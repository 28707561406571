import React, {useEffect, useState} from 'react';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {Button, Col, Input, Table, Pagination, Row, Form, Select} from "antd";
import SearchArea from "../../../components/layout/SearchArea";
import PageBottom from "../../../components/layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common"
import {Brand, Product, Term, wmsPublic} from "../config";
import condition from "../../../utils/condition";
import {useHistory} from "react-router-dom";

function SkuIndex(props) {
    // action 默认 part(配件) | boutique(精品) | commodity(商品库存 实际上就是boutique精品库存 只不过不显示成本价)
    let {
        action = Product.KIND_PART
        , isMultiple = false
        , onOk,
        quantityFilter //库存数量查全部
    } = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10
    })
    // 列表数据
    let [list, setList] = useState([])
    // let [subtotal, setSubtotal] = useState({});
    let [brand, setBrand] = useState([])// 品牌

    // 搜索条件
    let initialSearch = {
        name: "",
        number: "",
        warehouseId: "",
        codeBar: "",
        termIdsOne: [],
        termIdsTwo: [],
        productCategoryIds: [],
        quantity: quantityFilter || "gt",
        ownerId: common.getUser().company.id,
        kind: action === Product.KIND_PART ? Product.KIND_PART : Product.KIND_ARTICLE,
        // type: Receipt.RECEIPT_TYPE_BUY,
        brandIds: brand.map(item => item.id),
        // 显示商品可见性
        // needBrand: true,

        // 接口中没有的字段
        exampleWarehouseList: [],
    }
    let [search, setSearch] = useState(initialSearch) //搜索输入的内容
    let [query, setQuery] = useState(initialSearch)   //提交ajax搜索值
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [term1, setTerm1] = useState([]) // 集团管理分类
    let [term2, setTerm2] = useState([]) // 集团统计分类
    let [productCategoryTerm, setProductCategoryTerm] = useState([]) // 配件管理分类
    let [rows, setRows] = useState([]);// 打印的数据

    let rowSelection = {
        type: isMultiple ? 'checked' : 'radio',
        selectedRowKeys: rows.map(item => item.id),
        preserveSelectedRowKeys: true,
        onSelect: (record, selected) => {
            if (isMultiple) {
                if (selected) {
                    setRows([
                        ...rows,
                        record
                    ])
                } else {
                    setRows(rows.filter(item => item.id !== record.id))
                }
            } else {
                setRows(selected ? [record] : [])
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setRows([
                    ...rows,
                    ...changeRows
                ])
            }
            if (!selected) {
                let arr = []
                rows.forEach((item1) => {
                    let number = 0;
                    changeRows.forEach((item2) => {
                        if (item1.id === item2.id) {
                            number++;
                        }
                    })
                    if (number === 0) {
                        arr.push(item1)
                    }
                })

                setRows(arr)
            }
        }
    }

    const columnsPart = [
        {
            title: '配件编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '库存数量',
            width: 100,
            align: 'right',
            dataIndex: 'quantity',
            ellipsis: true,
            // sorter: true,
            // sortDirections: ['ascend', 'descend', 'ascend'],
            // defaultSortOrder: 'descend',
            render: (text) => <b>{common.numberFormat(text)}</b>,
        },
        {
            title: '零售价',
            dataIndex: 'priceOne',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.id === '-1' || record.id === '-2' || text === '0') {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '仓位',
            width: 100,
            dataIndex: 'positionName',
            ellipsis: true
        },
        {
            title: '适用车型',
            width: 150,
            align: 'center',
            dataIndex: 'applicableModels',
            ellipsis: true
        },
        {
            title: '配件条码',
            width: 150,
            dataIndex: 'barCode',
            ellipsis: true
        },
        {
            title: '是否施工',
            dataIndex: 'work',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '是否保养材料',
            dataIndex: 'material',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '配件管理分类',
            dataIndex: 'productCategoryName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true
        },
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
    ]
    const columnsBoutique = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '商品编号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '商品名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true
        },
        {
            title: '库存数量',
            width: 100,
            align: 'right',
            dataIndex: 'quantity',
            ellipsis: true,
            sorter: true,
            sortDirections: ['ascend', 'descend', 'ascend'],
            defaultSortOrder: 'descend',
            render: (text) => <b>{common.numberFormat(text)}</b>,
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品价' : '零售价',
            dataIndex: 'priceFour',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined || text === null) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '仓位',
            width: 100,
            dataIndex: 'positionName',
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '集团管理分类' : '管理分类',
            width: 150,
            align: 'center',
            dataIndex: 'termOneName',
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '集团统计分类' : '统计分类',
            width: 150,
            align: 'center',
            dataIndex: 'termTwoName',
            ellipsis: true
        },
        {
            title: '商品档案备注',
            width: action === Product.KIND_ARTICLE ? 0 : 120,
            dataIndex: 'spec',
            ellipsis: true,
        }
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })

            setQuery({
                ...query,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    // ajax 获取列表数据
    let getData = () => {
        common.loadingStart()
        common.ajax("get", "/wms/product/list/sku", {
            ...pagination,
            ...query,
            brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
        }).then((data) => {
            setTotal(data.pagination.total)
            setList(data.products)
        }).finally(() => {
            common.loadingStop()
        })
    }

    let onChange = (page, limit) => {
        setPagination({...pagination, page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query])

    // 获取仓库
    // let getWarehouse = () => {
    //     common.loadingStart();
    //     common.ajax("get", "/wms/warehouse/list", {
    //         status: Warehouse.STATUS_ENABLE,
    //         ownerId: common.getUser().company.id,
    //         isAll: Basic.IS_ANSWER_YES,
    //         type: Warehouse.TYPE_GOODS,
    //     }).then((data) => {
    //         setWarehouse(data.warehouses)
    //     }).finally(() => {
    //         common.loadingStop()
    //     })
    // }
    //
    // useEffect(getWarehouse, [])

    // 获取集团管理分类
    let getTerm = (taxonomy) => {
        common.loadingStart();
        common.ajax("get", "/wms/term/list", {
            taxonomy: taxonomy,
            status: Term.TERM_STATUS_ENABLE
        }).then((data) => {
            if (Term.TAXONOMY_ONE === taxonomy) {
                setTerm1(data.terms)
            } else if (Term.TAXONOMY_TWO === taxonomy) {
                setTerm2(data.terms)
            } else if (Term.PRODUCT_CATEGORY === taxonomy) {
                setProductCategoryTerm(data.terms)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // getTerm(Term.TAXONOMY_ONE)
        // getTerm(Term.TAXONOMY_TWO)
        getTerm(Term.PRODUCT_CATEGORY)
    }, [])

    let handleTableChange = (page, filters, sorter) => {
        setPagination({...pagination, orderBy: condition.getOrderBy(sorter)})
    };

    let print = () => {
        wmsPublic.printBarCode(wmsPublic.src, rows[0].name, rows[0].barCode)
    }

    let history = useHistory()

    return (
        <>
            {/*<PageTop*/}
            {/*    title={action === Product.KIND_ARTICLE ? "精品编号库存" : (action === Product.KIND_PART ? "配件编号库存" : "商品编号库存")}>*/}
            {/*</PageTop>*/}
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_ARTICLE ? "精品编号" : (action === Product.KIND_PART ? "配件编号" : "商品编号")}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }} onPressEnter={() => {setPagination({...pagination, page: 1}); setQuery(search)}} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={action === Product.KIND_ARTICLE ? "精品名称" : (action === Product.KIND_PART ? "配件名称" : "商品名称")}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }} onPressEnter={() => {setPagination({...pagination, page: 1}); setQuery(search)}} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={action === 'commodity' ? "商品编码" : "条码编号"}>
                                <Input value={search.codeBar} onChange={(e) => {
                                    setSearch({...search, codeBar: e.target.value})
                                }} onPressEnter={() => {setPagination({...pagination, page: 1}); setQuery(search)}} />
                            </Form.Item>
                        </Col>
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label={action === 'commodity' ? "管理分类" : "集团管理分类"}>*/}
                        {/*        <Select*/}
                        {/*            allowClear={true}*/}
                        {/*            value={search.termIdsOne[0]}*/}
                        {/*            onChange={(val) => {*/}
                        {/*                setSearch({...search, termIdsOne: [val]})*/}
                        {/*            }}>*/}
                        {/*            {*/}
                        {/*                term1.map((item) => {*/}
                        {/*                    return <Select.Option value={item.id}*/}
                        {/*                                          key={item.id}>{item.name}</Select.Option>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label="仓库名称">*/}
                        {/*        <Select*/}
                        {/*            mode={"multiple"}*/}
                        {/*            allowClear*/}
                        {/*            value={search.exampleWarehouseList}*/}
                        {/*            onChange={(val) => {*/}
                        {/*                setSearch({...search, exampleWarehouseList: val, warehouseId: val.toString()})*/}
                        {/*            }}>*/}
                        {/*            {*/}
                        {/*                warehouse.map((item) => {*/}
                        {/*                    return <Select.Option value={item.id}*/}
                        {/*                                          key={item.id}>{item.name}</Select.Option>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={6}>
                            <Form.Item label="库存数量">
                                <Select
                                    value={search.quantity}
                                    onChange={(val) => {
                                        setSearch({...search, quantity: val})
                                    }}>
                                    <Select.Option key={'gt'} value={'gt'}>不为0</Select.Option>
                                    <Select.Option key={'all'} value={'all'}>包括0</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {/*<Col span={12}>*/}
                        {/*    <Form.Item label="经营品牌">*/}
                        {/*        {brand.length > 0 &&*/}
                        {/*            <Select*/}
                        {/*                mode={"multiple"}*/}
                        {/*                allowClear*/}
                        {/*                value={search.brandIds}*/}
                        {/*                onChange={(val) => {*/}
                        {/*                    setSearch({...search, brandIds: val})*/}
                        {/*                }}>*/}
                        {/*                {*/}
                        {/*                    brand.map((item) => {*/}
                        {/*                        return (*/}
                        {/*                            <Select.Option key={item.id}*/}
                        {/*                                           value={item.id}>{item.name}</Select.Option>*/}
                        {/*                        )*/}
                        {/*                    })*/}
                        {/*                }*/}
                        {/*            </Select>}*/}
                        {/*        {brand.length === 0 &&*/}
                        {/*            <Select value={''}>*/}
                        {/*                <Select.Option key={''} value={''}>全部</Select.Option>*/}
                        {/*            </Select>}*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        {/*<Col span={6}>*/}
                        {/*    <Form.Item label={"集团统计分类"}>*/}
                        {/*        <Select*/}
                        {/*            allowClear={true}*/}
                        {/*            value={search.termIdsTwo[0]}*/}
                        {/*            onChange={(val) => {*/}
                        {/*                setSearch({...search, termIdsTwo: [val]})*/}
                        {/*            }}>*/}
                        {/*            {*/}
                        {/*                term2.map((item) => {*/}
                        {/*                    return <Select.Option value={item.id}*/}
                        {/*                                          key={item.id}>{item.name}</Select.Option>*/}
                        {/*                })*/}
                        {/*            }*/}
                        {/*        </Select>*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={6}>
                            <Form.Item label={"配件管理分类"}>
                                <Select
                                    allowClear={true}
                                    value={search.productCategoryIds[0]}
                                    onChange={(val) => {
                                        setSearch({...search, productCategoryIds: [val]})
                                    }}>
                                    {
                                        productCategoryTerm.map((item) => {
                                            return <Select.Option value={item.id}
                                                key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label={"适用车型"}>
                                <Input value={search.applicableModels} onChange={(e) => {
                                    setSearch({...search, applicableModels: e.target.value})
                                }} onPressEnter={() => {setPagination({...pagination, page: 1}); setQuery(search)}} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                }}>清空</Button>

                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                pagination={false}
                rowKey="id"
                columns={action === Product.KIND_PART ? columnsPart : columnsBoutique}
                dataSource={list}
                onChange={handleTableChange}
                scroll={{x: "100%", y: '460px'}}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onSelect(record, rows.filter(item => item.id === record.id).length === 0)
                        }, // 点击行
                    };
                }}
            />
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['10', '20', '30', '40']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>

            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast("请选择配件(精品)")
                        return false
                    }
                    isMultiple ? onOk(rows) : onOk(rows[0])
                }}>确定</Button>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuIndex)