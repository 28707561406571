import React from 'react';
import { useEffect, useState, useRef } from "react";
import { Table, Button, Switch } from "antd";
import common from "../../utils/common";

const AudioTable = () => {
    const [audioList, setAudioList] = useState([]); // 存储音频列表
    const [currentAudio, setCurrentAudio] = useState(null); // 当前播放音频
    const [isAutoPlay, setIsAutoPlay] = useState(true); // 是否开启自动播放
    const audioRef = useRef(null); // 音频播放器引用

    // WebSocket 连接并处理音频推送
    useEffect(() => {
        const socket = new WebSocket("ws://127.0.0.1:8080/audio");

        socket.onmessage = (event) => {
            common.consoleLog("Received audio URL:", event.data);

            // 将新音频添加到列表
            setAudioList((prevList) => [
                ...prevList,
                { key: prevList.length + 1, url: event.data, name: `音频 ${prevList.length + 1}` }
            ]);

            // 自动播放最新的音频，如果自动播放已开启
            if (isAutoPlay) {
                setCurrentAudio(event.data);
            }
        };

        socket.onopen = () => common.consoleLog("WebSocket connected");
        socket.onclose = () => common.consoleLog("WebSocket disconnected");

        return () => socket.close();
    }, [isAutoPlay]);

    // 播放指定音频
    const handlePlay = (url) => {
        setCurrentAudio(url);
        if (audioRef.current) {
            audioRef.current.load(); // 重新加载音频
            audioRef.current.play(); // 播放音频
        }
    };

    // 切换自动播放状态
    const handleAutoPlayChange = (checked) => {
        setIsAutoPlay(checked);
    };

    // 表格列配置
    const columns = [
        {
            title: "ID",
            dataIndex: "key",
            key: "id",
        },
        {
            title: "音频名称",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "播放",
            key: "play",
            render: (text, record) => (
                <Button type="primary" onClick={() => handlePlay(record.url)}>
                    播放
                </Button>
            ),
        },
    ];

    return (
        <div>
            <h2>音频列表</h2>

            {/* 自动播放开关 */}
            <div style={{ marginBottom: "16px" }}>
                <span>自动播放: </span>
                <Switch
                    checked={isAutoPlay}
                    onChange={handleAutoPlayChange}
                    checkedChildren="开启"
                    unCheckedChildren="关闭"
                />
            </div>

            {/* 音频列表表格 */}
            <Table columns={columns} dataSource={audioList} pagination={{ pageSize: 5 }} />

            {/* 音频播放器 */}
            {currentAudio && (
                <audio ref={audioRef} controls autoPlay={isAutoPlay}>
                    <source src={currentAudio} type="audio/mp3" />
                    您的浏览器不支持音频播放
                </audio>
            )}
        </div>
    );
};

export default AudioTable;