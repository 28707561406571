import {
    BuildOutlined,
    UnlockOutlined,
    GroupOutlined,
    PrinterOutlined,
    HddOutlined,
    MenuFoldOutlined,
    BookOutlined,
    FileDoneOutlined,
    BarChartOutlined,
    SettingOutlined,
    MenuUnfoldOutlined,
    WalletOutlined,
    AreaChartOutlined,
    PieChartOutlined,
    MoneyCollectOutlined,
    ControlOutlined,
    DashboardOutlined,
    CrownOutlined
} from '@ant-design/icons';

import {Layout, Menu, Modal} from 'antd';
import React, {useEffect, useState} from 'react'
import {Link, Route, Switch, withRouter} from "react-router-dom"

import './index.less'
import {connect} from "react-redux";
import common from "../../../utils/common";
import ChangePassword from "../../passport/ChangePassword";

let siderTheme = 'dark'
let headerTheme = 'dark'

function AdminLayout(props) {

    // 高度自适应
    useEffect(() => {
        let content = document.getElementsByClassName('content')[0]
        content.style.minHeight = window.innerHeight - 94 + "px";
    }, [common.getUser().brand.id, common.getUser().company.id])

    let [collapsed, setCollapsed] = useState(false)
    let [openKeys, setOpenKeys] = useState([])

    let [passVisible, setPassVisible] = useState(false)

    function toggle() {
        setCollapsed(!collapsed)
    }

    function onOpenChange(openKeys) {
        setOpenKeys([...openKeys.splice(-1)]);
    }

    function handleMenuClick(item, key, keyPath, domEvent) {
        props.history.push(item.item.props.url)
    }

    useEffect(() => {
        // 获取当前用户在指定公司下的权限
        common.loadingStart()
        common.ajax('get', '/passport/task/findTaskIdsByAuthUser', {companyId: common.getUser().company.id}).then((tasks) => {
            common.consoleLog(tasks)
            props.changeTasks(tasks)
        }).finally(() => {
            common.loadingStop()
        })

    }, [common.getUser().company.id])

    const {Header, Sider, Content} = Layout

    const {SubMenu} = Menu;

    // 没有task属性的菜单，不做权限验证
    const menuData = [
        {
            name: '组织架构',
            icon: <BuildOutlined />,
            children: [
                {name: '品牌管理', url: '/admin/wms/brand', task: 'admin.wms.brand'},
                {name: '区域信息', url: '/admin/passport/area', task: 'admin.passport.area'},
                {name: '管理归属', url: '/admin/passport/management', task: 'admin.passport.management'},
                {name: '部门设置', url: '/admin/passport/department', task: 'admin.passport.department'},
                {name: '岗位设置', url: '/admin/passport/position', task: 'admin.passport.position'},
                {name: '维修工段', url: '/admin/passport/section', task: 'admin.passport.section'},
                {name: '班组设置', url: '/admin/passport/group', task: 'admin.passport.group'},
                {name: '经营主体', url: '/admin/passport/company', task: 'admin.passport.company'},
                {name: '员工档案', url: '/admin/passport/employee', task: 'admin.passport.employee'},
            ]
        },
        {
            name: '权限设置',
            icon: <UnlockOutlined />,
            children: [
                {name: '角色权限设置', url: '/admin/passport/authority/role', task: 'admin.passport.authority.role'},
                {name: '用户权限设置', url: '/admin/passport/authority/user', task: 'admin.passport.authority.user'},
                {
                    name: '部门权限设置',
                    url: '/admin/passport/authority/department',
                    task: 'admin.passport.authority.department'
                },
                {
                    name: '礼券权限设置',
                    url: '/admin/passport/authority/coupon',
                    task: 'admin.passport.authority.coupon'
                },
                {
                    name: '仓库权限设置',
                    url: '/admin/passport/authority/warehouse',
                    task: 'admin.passport.authority.warehouse'
                },
                {
                    name: '付款申请权限设置',
                    url: '/admin/passport/authority/financeBusinessType',
                    task: 'admin.passport.authority.financeBusinessType'
                },
                {
                    name: '普通权限设置',
                    url: '/admin/passport/authority/normal',
                    task: 'admin.passport.authority.normal'
                },
            ]
        },
        {
            name: '主数据管理',
            icon: <GroupOutlined />,
            children: [
                {name: '外部单位管理', url: '/admin/passport/partner', task: 'admin.passport.partner'},
                {name: '配件档案管理', url: '/admin/wms/goods', task: 'admin.wms.product.part'},
                {name: '车系档案管理', url: '/admin/wms/series', task: 'admin.wms.series'},
                {name: '销售车型管理', url: '/admin/wms/car/product', task: 'admin.wms.product.car'},
            ]
        },
        {
            name: '资料管理',
            icon: <HddOutlined />,
            children: [
                {name: '短信模板管理', url: '/admin/crm/template', task: 'admin.sms.template'},
                {name: '调查问卷管理', url: '/admin/qa/questionnaire', task: 'admin.qa.questionnaire'},
                {name: '常用工时项目', url: '/admin/support/project', task: 'admin.support.project'},
            ]
        },
        {
            name: '系统设置',
            icon: <SettingOutlined />,
            children: [
                {name: '发票类型管理', url: '/admin/finance/invoiceType', task: 'admin.finance.invoiceType'},
                {name: '单位类型管理', url: '/admin/passport/partnerType', task: 'admin.passport.partnerType'},
                {name: '客户类型管理', url: '/admin/passport/customer/type', task: 'admin.passport.customerType'},
                {name: '销售渠道管理', url: '/admin/sale/channel', task: 'admin.sale.channel'},
                {name: '订单类型设置', url: '/admin/sale/type/channel', task: 'admin.sale.typeChannel'},
                {name: '折扣类型管理', url: '/admin/sale/rebate', task: 'admin.sale.rebate'},
                {name: '销售方式管理', url: '/admin/sale/pay', task: 'admin.sale.pay'},
                {name: '客流模块管理', url: '/admin/crm/term', task: 'admin.crm.term'},
                {name: '战败原因管理', url: '/admin/crm/reason', task: 'admin.crm.reason'},
                // {name: '收支项目管理', url: '/admin/finance/category', task: 'admin.finance.category'},
                {name: '车主标签管理', url: '/admin/support/vehicleTag', task: 'admin.support.vehicleTag'},
                {name: '维修工单类型', url: '/admin/support/repairCategory', task: 'admin.support.repairCategory'},
                {
                    name: '折扣项目管理',
                    url: '/admin/support/repairDiscountCategory',
                    task: 'admin.support.repairDiscountCategory'
                },
                // {name: '单号设置', url: '/admin/passport/code', task: 'admin.passport.code'},
                {name: '集团管理分类', url: '/admin/wms/category/manage', task: 'admin.wms.category.manage'},
                {name: '集团统计分类', url: '/admin/wms/category/statistics', task: 'admin.wms.category.statistics'},
                {name: '入库税率管理', url: '/admin/wms/tax/receipt', task: 'admin.wms.tax.receipt'},
                {name: '结算税率管理', url: '/admin/wms/tax/settlement', task: 'admin.wms.tax.settlement'},
                {name: '车型内饰颜色', url: '/admin/wms/product/colorInside', task: 'admin.wms.product.colorInside'},
                {name: '车型外饰颜色', url: '/admin/wms/product/colorOutside', task: 'admin.wms.product.colorOutside'},
                {name: '购买方式', url: '/admin/wms/buyWay', task: 'admin.wms.buyWay'},
                {name: '仓库管理', url: '/admin/wms/warehouse', task: 'admin.wms.warehouse'},
                {name: '车辆类型', url: '/admin/wms/goodsKind', task: 'admin.wms.car.goodsKind'},
                {name: '合格证状态', url: '/admin/wms/qualifiedStatus', task: 'admin.wms.car.qualifiedStatus'},
                {name: '维修业务类别', url: '/admin/support/businessKind', task: 'admin.support.businessKind'},
                {name: '挂帐类别管理', url: '/admin/support/accountCategory', task: 'admin.support.accountCategory'},
                {name: '预收业务类别', url: '/admin/finance/businessCategory', task: 'admin.finance.businessCategory'},
                {name: '贷款项目管理', url: '/admin/avocation/mortgageProject', task: 'admin.avocation.mortgageProject'},
                {name: '按揭产品管理', url: '/admin/avocation/mortgageProduct', task: 'admin.avocation.mortgageProduct'},
                {name: '增值业务类别', url: '/admin/avocation/businessType', task: 'admin.avocation.businessType'},
                {name: '业务类别属性', url: '/admin/avocation/businessAttr', task: 'admin.avocation.businessAttr'},
                {name: '卡券核销原因', url: '/admin/coupon/ticket/appliedType', task: 'admin.coupon.ticket.appliedType'},
                {name: '加装客户性质', url: '/admin/accessory/order/customerType', task: 'admin.accessory.customerType'},

                // {name: '维修方案套餐', url: '/admin/support/plan'},
            ]
        },
        {
            name: '库存查询',
            icon: <HddOutlined />,
            children: [
                {name: '整车库存查询', url: '/admin/report/wms/car/sku', task: 'admin.report.wms.car.sku'},
                {name: '精品库存查询', url: '/admin/report/wms/article/sku', task: 'admin.report.wms.boutique.sku'},
                {name: '配件库存查询', url: '/admin/report/wms/part/sku', task: 'admin.report.wms.part.sku'},
                // 新增 {name: '调拨业务统计', url: '', task: ''},
            ]
        },
        /*{
            name: '集采管理',
            icon: <ShopOutlined />,
            children: [
                // 新增 {name: '精品订货统计', url: '', task: ''},
                // 新增 {name: '精品价格统计', url: '', task: ''},
                // 新增 {name: '集采单据处理', url: '', task: ''},
            ]
        },*/
        /*{
            name: '增值业务管理',
            icon: <WhatsAppOutlined />,
            children: [
                // 此模块还没有做
            ]
        },*/
        /*{
            name: '客户管理',
            icon: <UsergroupAddOutlined />,
            children: [
                // 此模块子公司还没有做，子公司完成了再汇总
                // 新增 {name: '客户数量统计', url: '', task: ''},
                // 新增 {name: '售后维修统计', url: '', task: ''},
                // 新增 {name: '销售回访统计', url: '', task: ''},
                // 新增 {name: '售后回访统计', url: '', task: ''},
                // 新增 {name: '会员客户统计', url: '', task: ''},
                // 新增 {name: '业务预警器', url: '', task: ''},
                // 新增 {name: '客户筛选器', url: '', task: ''},
                // 新增 {name: '潜客信息管理', url: '', task: ''},
            ]
        },*/
        {
            name: '客户管理',
            icon: <WalletOutlined />,
            children: [
                {name: '会员档案', url: '/admin/passport/customer/vip', task: 'admin.passport.customer.vip'},
                {name: '车辆档案', url: '/admin/support/vehicle', task: 'admin.support.vehicle'},
                {name: '客户档案', url: '/admin/passport/customer', task: 'admin.passport.customer'},
            ]
        },
        {
            name: '卡券中心',
            icon: <WalletOutlined />,
            children: [
                {name: '礼券模板管理', url: '/admin/coupon', task: 'admin.coupon.coupon'},
                {name: '礼券方案管理', url: '/admin/coupon/plan', task: 'admin.coupon.plan'},
            ]
        },
        {
            name: '报表中心',
            icon: <BarChartOutlined />,
            children: [
                {name: '销售报表', url: '/admin/report/sale', task: 'admin.report.sale'},
                {name: '维修报表', url: '/admin/report/support', task: 'admin.report.support'},
                {name: '财务报表', url: '/admin/report/finance', task: 'admin.report.finance'},
            ]
        },
        {
            name: '经营汇总',
            icon: <AreaChartOutlined />,
            children: [
                {name: '经营汇总表', url: '/admin/report/summary', task: 'admin.report.summary'},
            ]
        },
        {
            name: '经营分析',
            icon: <PieChartOutlined />,
            children: [
                {name: 'BI 报表', url: '/admin/report/bi', task: 'admin.report.bi'},
            ]
        },
        {
            name: '政策设置',
            icon: <MoneyCollectOutlined />,
            children: [
                {name: '整车价格政策', url: '/admin/sale/carprice', task: 'admin.sale.car.price'},
                {name: '配件价格政策', url: '/admin/wms/partPrice', task: 'admin.wms.part.price'},
                // 新增 {name: '维修优惠设置', url: '', task: ''},
            ]
        },
        {
            name: '审批设置',
            icon: <FileDoneOutlined />,
            children: [
                {name: '审批模板', url: '/admin/ext/approve/sale', task: 'admin.ext.approve.sale'},
            ]
        },
        {
            name: '集采管理',
            icon: <FileDoneOutlined />,
            children: [
                {name: '报价设置', url: '/admin/wms/group/buy', task: 'admin.group.buy.setting'},
            ]
        },
        {
            name: '会员设置',
            icon: <CrownOutlined />,
            children: [
                {name: '会员类别', url: '/admin/passport/vip/level', task: 'admin.passport.vip.level'},
            ]
        },
        // 只有超级管理员才能操作，加一个后台不存在的 task
        {
            name: '参数设置',
            icon: <ControlOutlined />,
            children: [
                {name: '新车销售模块', url: '/admin/sale/setting', task: 'tenant'},
                {name: '维修管理模块', url: '/admin/support/setting', task: 'tenant'},
                {name: '增值业务模块', url: '/admin/avocation/setting', task: 'tenant'},
                {name: '财务管理模块', url: '/admin/finance/setting', task: 'tenant'},
                {name: '进销存模块', url: '/admin/wms/setting', task: 'tenant'},
                {name: '卡券模块', url: '/admin/coupon/setting', task: 'tenant'},
                // 新增 {name: '公共控制模块', url: '', task: 'tenant'},
                // 新增 {name: '特殊权限设置', url: '', task: 'tenant'},
                {name: '短信设置', url: '/admin/ext/sms', task: 'tenant'},
                {name: '文字识别', url: '/admin/ext/ocr', task: 'tenant'},
                {name: 'POS 设置', url: '/admin/finance/pos', task: 'tenant'},
            ]
        },
        // 只有超级管理员才能操作，加一个后台不存在的 task
        {
            name: '账套初始化',
            icon: <BookOutlined />,
            children: [
                {name: '品牌数据导入', url: '/admin/wms/brand/import', task: 'tenant'},
                {name: '车系数据导入', url: '/admin/wms/series/import', task: 'tenant'},
                {name: '车型数据导入', url: '/admin/wms/vehicle/import', task: 'tenant'},
                {name: '配件数据导入', url: '/admin/wms/product/goods/import', task: 'tenant'},
                {name: '员工档案导入', url: '/admin/passport/employee/import', task: 'tenant'},
                {name: '客户档案导入', url: '/admin/passport/customer/import', task: 'tenant'},
                {name: '车辆档案导入', url: '/admin/support/vehicle/import', task: 'tenant'},
                {name: '维修工时导入', url: '/admin/support/repairItemProject/import', task: 'tenant'},
                {name: '维修材料导入', url: '/admin/support/repairItemProduct/import', task: 'tenant'},
                {name: '维修记录导入', url: '/admin/support/repairItemOrder/import', task: 'tenant'},
                {name: '外部单位导入', url: '/admin/passport/partner/import', task: 'tenant'},
                // 增加：应收、预收、应付余额导入
            ]
        },
        // 只有超级管理员才能操作，加一个后台不存在的 task
        {
            name: '打印设置',
            icon: <PrinterOutlined />,
            children: [
                {name: '打印模板', url: '/admin/ext/print', task: 'tenant'},
                // 缺一个打印礼券
            ]
        },
        // 只有超级管理员才能操作，加一个后台不存在的 task
        {
            name: '控制面板',
            icon: <DashboardOutlined />,
            children: [
                // 新增 {name: '经营主体的新增', url: '', task: 'tenant'},
                // 新增 {name: '权限模块显示和隐藏', url: '', task: 'tenant'},
                {name: '单号设置', url: '/admin/passport/code', task: 'tenant'},
                {name: '收支项目管理', url: '/admin/finance/category', task: 'tenant'},
                // 新增 {name: '折扣类型设置', url: '', task: 'tenant'},
                // 新增 {name: '增值业务类别', url: '', task: 'tenant'},
                // 新增 {name: '日志查询（数据修改类和登录记录）', url: '', task: 'tenant'},
                // 新增 {name: '权限分配查询（某权限被放在哪些角色里和某权限某些人有）', url: '', task: 'tenant'},
            ]
        },

    ]

    // 带权限的菜单数据, show=true的表示显示
    let getMenuDataWithPermission = (menuData) => {

        for (let i = 0;i < menuData.length;i++) {

            let count = 0

            for (let j = 0;j < menuData[i].children.length;j++) {

                //没有task属性的菜单，不做权限验证
                if (menuData[i].children[j].task == null) {
                    count++
                    menuData[i].children[j].show = true
                    continue
                }

                if (props.permission.tasks.includes(menuData[i].children[j].task)) {
                    count++
                    menuData[i].children[j].show = true
                } else {
                    menuData[i].children[j].show = false
                }
            }

            // 一个子菜单都没有，则父级菜单也不显示
            if (count === 0) {
                menuData[i].show = false
            } else {
                menuData[i].show = true
            }
        }

        return menuData
    }

    return (
        <Layout className={'AdminLayout'}>
            <Sider theme={siderTheme} trigger={null} collapsible collapsed={collapsed}>
                <Link to={'/'}>
                    <div className="logo">{collapsed ? 'Erp' : 'Erp Auto'}</div>
                </Link>
                <Menu theme={siderTheme} mode="inline"
                    onClick={handleMenuClick}
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    style={{marginTop: '20px'}}
                >


                    {getMenuDataWithPermission(menuData).map((sub, subIndex) => {

                        if (props.user.type != 'tenant' && !sub.show) {
                            return null
                        }

                        return (
                            <SubMenu
                                className={'menu-one'}
                                key={`sub${subIndex}`}
                                title={
                                    <span>{sub.icon}<span>{sub.name}</span></span>
                                }
                            >
                                {sub.children.map((item, itemIndex) => {

                                    if (props.user.type != 'tenant' && !item.show) {
                                        return null
                                    }

                                    return (
                                        <Menu.Item
                                            className={'menu-two'}
                                            key={`sub${subIndex}-${itemIndex}`}
                                            url={item.url}>{item.name}</Menu.Item>
                                    )
                                })}
                            </SubMenu>
                        )
                    })}

                </Menu>
            </Sider>
            <Layout>
                <Header
                    style={{padding: 0}}>

                    {
                        collapsed ?
                            <MenuUnfoldOutlined
                                className="trigger"
                                onClick={toggle}
                            /> :
                            <MenuFoldOutlined
                                className="trigger"
                                onClick={toggle}
                            />
                    }

                    <Menu
                        className={'menu-top'}
                        theme={headerTheme}
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{lineHeight: '64px', float: 'left'}}
                    >
                        <Menu.Item key="1">{props.user.tenant.name}</Menu.Item>
                    </Menu>

                    <Menu
                        theme={headerTheme}
                        mode="horizontal"
                        defaultSelectedKeys={['2']}
                        style={{lineHeight: '64px', float: 'right'}}
                    >
                        <Menu.Item key="20" onClick={() => props.history.push('/admin/ext/activity')}>我的审批</Menu.Item>
                        <SubMenu
                            title={
                                <span className="submenu-title-wrapper">
                                    {props.user.nickname}
                                </span>
                            }
                        >
                            <Menu.Item key="changePassword" onClick={() => {
                                setPassVisible(true)
                            }}>修改密码</Menu.Item>
                            <Menu.Item key="logout" onClick={() => {
                                common.setToken(null)
                                common.redirectToLogin()
                            }}>退出登录</Menu.Item>
                        </SubMenu>
                    </Menu>

                </Header>
                <Content className={'content'}>
                    {props.children}
                </Content>
            </Layout>

            <div>
                <Modal
                    destroyOnClose={true}
                    maskClosable={false}
                    footer={null}
                    width={1000}
                    visible={passVisible}
                    onCancel={() => {
                        setPassVisible(false)
                    }}
                >
                    <ChangePassword onOk={() => {
                        setPassVisible(false)
                        common.alert('密码修改成功，请重新登录', () => {
                            common.setToken(null)
                            common.redirectToLogin()
                        })
                    }}></ChangePassword>
                </Modal>
            </div>
        </Layout>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        permission: state.permission,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        },
        changeTasks: (tasks) => {
            dispatch({type: 'CHANGE_TASKS', tasks: tasks})
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminLayout))
