import React, {useEffect, useRef, useState} from 'react';
import {CloseOutlined, RollbackOutlined, SaveOutlined, SelectOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Select,
    Row,
    Table,
    Modal,
    InputNumber,
    Form,
    DatePicker,
    Divider,
    Popconfirm
} from "antd";
import {connect} from "react-redux";
import ProductGoods from "../../../../components/wms/LookupProductGoods"
import Partner from "../../../../components/passport/LookupPartner"
import common from "../../../../utils/common";
import PageTop from "../../../../components/layout/PageTop";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Warehouse, Basic, Receipt, Product, Term, wmsPublic} from "../../../../components/wms/config";
import Search from "../../../../utils/search";
import {CustomizationConstant} from "../../../../components/Customization/config";
import LookupEmployee from '../../../../components/passport/LookupEmployee';

const {Option} = Select
const {TextArea} = Input

const DATA_SOURCE_ALL = 3 // 外部单位数据类型：1.集团  2：子公司 3.所有

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    let {onOk, action} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [employeeVisible, setEmployeeVisible] = useState(false) // 模态框可见性
    let [modalTitle, setModalTitle] = useState("") // 模态框名称
    let [modalNum, setModalNum] = useState(0) // 选择组件
    let [partner2, setPartner2] = useState({}) // 供应商
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [constPrice, setConstPrice] = useState(0) // 总金额(总计)
    let [allPriceGo, setAllPriceGo] = useState(0) // 去税金额(总计)
    let [allPriceTax, setAllPriceTax] = useState(0) // 税金(总计)
    let [allQuantity, setAllQuantity] = useState(0) // 数量(总计)

    let [list, setList] = useState([]) // 表格数据
    let [pageTopTitle, setPageTopTitle] = useState("") // 页面标题
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [tax, setTax] = useState([]) // 税率
    let [setting, setSetting] = useState({})
    let [settingSelectReceiptDate, setSettingSelectReceiptDate] = useState({})

    // 获取配置数据
    let getSettingData = (taxonomy) => {
        let params = {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/wms/setting', params)
            .then(res => {
                if (res !== null) {
                    if (res.taxonomy === Term.PART_RECEIPT_DEFAULT_WAREHOUSE_ID || res.taxonomy === Term.BOUTIQUE_RECEIPT_DEFAULT_WAREHOUSE_ID) {
                        setSetting(res)
                    } else if (res.taxonomy === Term.GOODS_RECEIPT_BUY_SELECT_DATE) {
                        setSettingSelectReceiptDate(res)
                    }
                }
            })
            .finally(common.loadingStop)
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({receiptDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    useEffect(() => {
        getSettingData(Term.GOODS_RECEIPT_BUY_SELECT_DATE)
        if (action === Product.KIND_PART) {
            getSettingData(Term.PART_RECEIPT_DEFAULT_WAREHOUSE_ID)
        } else {
            getSettingData(Term.BOUTIQUE_RECEIPT_DEFAULT_WAREHOUSE_ID)
        }
    }, [])

    useEffect(() => {
        if (warehouse.length > 0 && setting.flag !== undefined && setting.flag !== "") {
            // 验证默认的仓库ID 是不是在列表中存在 存在则默认
            let warehouseIds = warehouse.map(item => item.id).filter(warehouseId => warehouseId === setting.flag)
            if (warehouseIds.length > 0) {
                form.setFieldsValue({
                    warehouseId: setting.flag,
                })
            }
        }
    }, [setting, warehouse])

    let getTaxData = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_RECEIPT_TAX,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTax(data.terms)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getTaxData, [])

    // 获取列表数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/product/list", {
            isHaltProduction: Basic.IS_ANSWER_NO,
            kind: Product.KIND_GOODS,
            ownerId: common.getUser().company.id,
            brandId: common.getUser().brand.id,
            number: params,
            isInput: true,
        }).then((data) => {
            if (data.products.length === 1) {
                let arr = list.filter(item => item.id === data.products[0].id)
                // 添加一行
                if (arr.length > 0) {
                    productGoodsOnOk(list)
                } else {
                    productGoodsOnOk([...list, ...data.products])
                }

                setTimeout(() => {
                    emptyInput.current.focus()
                }, 100)
            } else {
                selectModal(3)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 表单提交
    let onFinish = (values) => {
        if (values.deliveryNumber === null || values.deliveryNumber === undefined) {
            values.deliveryNumber = ""
        }
        list.forEach((item) => {
            item.tax = values.tax
            item.spec = item.receiptItemSpec
            item.positionId = ''
            item.receiptQuantity = item.receiptQuantity + ''
        })

        values.receiptDate = values.receiptDate.format('YYYY-MM-DD')
        values.manufacturerId = ""
        values.supplierId = partner2.id
        values.receiptItem = list
        values.ownerId = common.getUser().company.id
        if (action === Product.KIND_ARTICLE) {
            values.kind = Product.KIND_ARTICLE
        } else {
            values.kind = Product.KIND_PART
        }
        onOk(values)
    };

    const columns = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={action === Product.KIND_ARTICLE ? "输入精品编号 回车" : "输入配件编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    selectModal(3)
                                }} />
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter

                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? "精品名称" : '配件名称',
            dataIndex: 'name',
            width: 200,
            ellipsis: true,
        },
        {
            title: '库存数',
            dataIndex: 'skuQuantity',
            width: 100,
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === null || text === undefined) {
                    return ''
                }

                if (parseFloat(text) === 0) {
                    return ''
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '入库数量',
            dataIndex: 'receiptQuantity',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            list[index].receiptQuantity = val
                            let arr = []
                            let price = 0
                            let priceGoAll = 0
                            let priceTaxAll = 0
                            let quantityAll = 0

                            list.forEach((item) => {
                                if (item.id === record.id) {
                                    let priceConst = '0.00'
                                    let priceGo = '0.00'
                                    let priceTax = '0.00'

                                    // 数量
                                    if (val !== '' && val !== null) {
                                        quantityAll = new BigNumber(val).plus(new BigNumber(quantityAll)).toString();
                                    }

                                    // 金额
                                    if (item.price !== null && val !== '' && val !== null) {
                                        priceConst = new BigNumber(val).multipliedBy(new BigNumber(item.price)).toString()
                                        price = new BigNumber(price).plus(new BigNumber(priceConst)).toString()
                                    }

                                    // 去税金额 税金
                                    if (item.price !== null && val !== '' && val !== null && form.getFieldValue("tax") !== null) {
                                        priceGo = new BigNumber(val).multipliedBy(new BigNumber(item.price)).dividedBy(new BigNumber(form.getFieldValue("tax")).plus(1)).toFixed(2)
                                        priceTax = new BigNumber(priceConst).minus(new BigNumber(priceGo)).toString()

                                        // 去税金额总计
                                        priceGoAll = new BigNumber(priceGo).plus(priceGoAll).toString();

                                        // 税金总计
                                        priceTaxAll = new BigNumber(priceTax).plus(priceTaxAll).toString();
                                    }
                                    arr.push({
                                        ...item,
                                        receiptQuantity: val,
                                        priceConst: priceConst,
                                        priceGo: priceGo,
                                        priceTax: priceTax,
                                    })
                                } else {
                                    // 数量
                                    if (item.receiptQuantity !== '' && item.receiptQuantity !== null && item.receiptQuantity !== undefined) {
                                        quantityAll = new BigNumber(item.receiptQuantity).plus(new BigNumber(quantityAll)).toString();
                                    }
                                    price = new BigNumber(price).plus(item.priceConst).toString()
                                    priceTaxAll = new BigNumber(item.priceTax).plus(priceTaxAll).toString();
                                    priceGoAll = new BigNumber(item.priceGo).plus(priceGoAll).toString();
                                    arr.push(item)
                                }
                            })
                            setAllQuantity(quantityAll)
                            setAllPriceGo(priceGoAll)
                            setAllPriceTax(priceTaxAll)
                            setConstPrice(price)
                            setList(arr)
                        }}
                    />)
            }
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true,
        },
        {
            title: '上次采购价',
            dataIndex: ['receiptItemDto', 'price'],
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === undefined) {
                    return ''
                }
                return text
            }
        },
        {
            title: '入库单价(含税)',
            dataIndex: 'price',
            width: 150,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        defaultValue={text}
                        onChange={(val) => {
                            let arr = []
                            let price = 0
                            let priceGoAll = 0
                            let priceTaxAll = 0

                            list.forEach((item) => {
                                if (item.id === record.id) {
                                    let priceConst = '0.00'
                                    let priceGo = '0.00'
                                    let priceTax = '0.00'

                                    // 金额
                                    if (item.receiptQuantity !== null && val !== '' && val !== null) {
                                        priceConst = new BigNumber(val).multipliedBy(new BigNumber(item.receiptQuantity)).toString()
                                        price = new BigNumber(price).plus(priceConst).toString()
                                    }

                                    // 去税价格
                                    if (item.receiptQuantity !== null && val !== '' && val !== null && form.getFieldValue("tax") !== null) {
                                        priceGo = new BigNumber(val).multipliedBy(new BigNumber(item.receiptQuantity)).dividedBy(new BigNumber(form.getFieldValue("tax")).plus(1)).toFixed(2)
                                        priceTax = new BigNumber(priceConst).minus(priceGo).toString()

                                        // 去税金额总计
                                        priceGoAll = new BigNumber(priceGo).plus(priceGoAll).toString();

                                        // 税金总计
                                        priceTaxAll = new BigNumber(priceTax).plus(priceTaxAll).toString();
                                    }

                                    arr.push({
                                        ...item,
                                        price: val,
                                        priceConst: priceConst,
                                        priceGo: priceGo,
                                        priceTax: priceTax,
                                    })
                                } else {
                                    price = new BigNumber(price).plus(item.priceConst).toString()
                                    priceTaxAll = new BigNumber(item.priceTax).plus(priceTaxAll).toString();
                                    priceGoAll = new BigNumber(item.priceGo).plus(priceGoAll).toString();
                                    arr.push(item)
                                }
                            })
                            setAllPriceGo(priceGoAll)
                            setAllPriceTax(priceTaxAll)
                            setConstPrice(price)
                            list[index].price = val
                            setList(arr)
                        }}
                    />)
            }
        },
        {
            title: '金额',
            dataIndex: 'priceConst',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === undefined || text === '') {
                    return '0.00'
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return <Input
                    value={text}
                    onChange={e => {
                        setList(list.map((item => {
                            if (item.id === record.id) {
                                item.positionName = e.target.value || ""
                            }
                            return item
                        })))
                    }} />
            }
        },
        {
            title: '适用车型',
            dataIndex: 'applicableModels',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return <Input
                    value={text}
                    onChange={e => {
                        setList(list.map((item => {
                            if (item.id === record.id) {
                                item.applicableModels = e.target.value || ""
                            }
                            return item
                        })))
                    }} />
            }
        },
        {
            title: '备注',
            dataIndex: 'receiptItemSpec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <Input
                        onPressEnter={wmsPublic.onPressEnter}
                        onChange={(e) => {
                            list[index].receiptItemSpec = e.target.value
                        }} />)
            }
        },
        {
            title: '去税金额',
            dataIndex: 'priceGo',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === undefined || text === '') {
                    return '0.00'
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '税金',
            dataIndex: 'priceTax',
            width: 150,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (text === undefined || text === '') {
                    return '0.00'
                }
                return common.numberFormat(text)
            }
        },
        {
            title: '操作',
            align: "center",
            fixed: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                let price = 0
                                let priceTax = 0
                                let priceGo = 0
                                let quantity = 0

                                let arr = list.filter((item) => {
                                    if (item.id !== record.id) {
                                        // 总金额
                                        if (item.priceConst !== '') {
                                            price = new BigNumber(price).plus(item.priceConst).toString()
                                        }

                                        // 总 去税金额
                                        if (item.priceGo !== '') {
                                            priceGo = new BigNumber(priceGo).plus(item.priceGo).toString()
                                        }

                                        // 总 税金
                                        if (item.priceTax !== '') {
                                            priceTax = new BigNumber(priceTax).plus(item.priceTax).toString()
                                        }

                                        // 总 数量
                                        if (item.receiptQuantity !== '' && item.receiptQuantity !== null) {
                                            quantity = new BigNumber(quantity).plus(item.receiptQuantity).toString()
                                        }
                                    }
                                    return item.id !== record.id
                                })
                                setAllQuantity(quantity)
                                setAllPriceGo(priceGo)
                                setAllPriceTax(priceTax)
                                setConstPrice(price)
                                setList(arr)
                            }}
                            okText="确定"
                            cancelText="取消">
                            <Button type={'link'}>删除</Button>
                        </Popconfirm>
                    </div>)
            }
        }
    ]

    let setSubtotalByTax = (params) => {
        if (list.length > 0) {
            let arr = []
            let priceGoAll = 0
            let priceTaxAll = 0

            list.forEach((item) => {
                if (item.price !== null && item.receiptQuantity !== null) {
                    // 去税金额
                    let priceGo = new BigNumber(item.receiptQuantity).multipliedBy(new BigNumber(item.price)).dividedBy(new BigNumber(params).plus(1)).toFixed(2)
                    let priceTax = new BigNumber(item.priceConst).minus(priceGo).toString()
                    arr.push({
                        ...item,
                        priceGo: priceGo,
                        priceTax: priceTax
                    })

                    // 去税金额总计
                    priceGoAll = new BigNumber(priceGo).plus(new BigNumber(priceGoAll)).toString()
                    // 税金总计
                    priceTaxAll = new BigNumber(priceTax).plus(new BigNumber(priceTaxAll)).toString()
                } else {
                    // 去税金额总计
                    priceGoAll = new BigNumber(item.priceGo).plus(new BigNumber(priceGoAll)).toString()
                    // 税金总计
                    priceTaxAll = new BigNumber(item.priceTax).plus(new BigNumber(priceTaxAll)).toString()
                    arr.push(item)
                }
            })
            setAllPriceGo(priceGoAll)
            setAllPriceTax(priceTaxAll)
            setList(arr)
        }
    }

    // 配件(精品选择)
    let productGoodsOnOk = (val) => {
        let arr = []
        let quantity = 0
        let total = 0
        let priceGo = 0
        let priceTax = 0

        val.forEach((item1) => {
            let number = 0
            list.forEach((item2) => {
                if (item2.id === item1.id) {
                    number++;
                    arr.push(item2)

                    quantity = new BigNumber(item2.receiptQuantity).plus(quantity).toString()
                    total = new BigNumber(item2.priceConst).plus(total).toString()
                    priceGo = new BigNumber(item2.priceGo).plus(priceGo).toString()
                    priceTax = new BigNumber(item2.priceTax).plus(priceTax).toString()
                }
            })
            if (number === 0) {
                let obj = {
                    price: null,
                    priceConst: '0.00',
                    priceGo: '0.00',
                    priceTax: '0.00',
                    receiptItemSpec: '',
                    receiptQuantity: 1,
                }
                if (item1.receiptItemDto !== null) {
                    let priceConst = new BigNumber(1).multipliedBy(item1.receiptItemDto.price).toString()
                    let priceGo = form.getFieldValue('tax') === null ? '0.00' : new BigNumber(priceConst).dividedBy(new BigNumber(form.getFieldValue('tax')).plus(1)).toString()
                    let priceTax = form.getFieldValue('tax') === null ? '0.00' : new BigNumber(priceConst).minus(priceGo).toString()

                    obj = {
                        price: item1.receiptItemDto.price,
                        priceConst: priceConst,
                        priceGo: priceGo,
                        priceTax: priceTax,
                        receiptItemSpec: '',
                        receiptQuantity: 1,
                    }
                }
                arr.push({
                    ...item1,
                    ...obj
                })

                quantity = new BigNumber(obj.receiptQuantity).plus(quantity).toString()
                total = new BigNumber(obj.priceConst).plus(total).toString()
                priceGo = new BigNumber(obj.priceGo).plus(priceGo).toString()
                priceTax = new BigNumber(obj.priceTax).plus(priceTax).toString()
            }
        })

        setAllQuantity(quantity)
        setConstPrice(total)
        setAllPriceGo(priceGo)
        setAllPriceTax(priceTax)
        setList(arr)
        setNewValue("")
        setVisible(false)
    }

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    // 选择模态框
    let selectModal = (num) => {
        setModalNum(num)
        if (num === 2) {
            setModalTitle("选择供应商")
        }
        if (num === 3 && action === Product.KIND_PART) {
            setModalTitle("选择配件")
        }
        if (num === 3 && action === Product.KIND_ARTICLE) {
            setModalTitle("选择精品")
        }
        if (num === 4) {
            setModalTitle("选择采购人")
        }
        setVisible(true)
    }

    useEffect(() => {
        if (action === Product.KIND_PART) {
            setPageTopTitle("新增配件入库")
        }
        if (action === Product.KIND_ARTICLE) {
            setPageTopTitle("新增精品入库")
        }
    }, [action])

    const [form] = Form.useForm()

    return <>
        <PageTop title={pageTopTitle}>
            <Button
                icon={<SaveOutlined />}
                type={"primary"}
                onClick={() => {
                    if (partner2.id === undefined) {
                        common.alert("请选择供应商")
                        return false
                    }
                    if (list[0].id === -1) {
                        if (action === Product.KIND_PART) {
                            common.alert("请选择配件")
                        }
                        if (action === Product.KIND_ARTICLE) {
                            common.alert("请选择精品")
                        }
                        return false
                    }
                    for (let item of list) {
                        if (item.receiptQuantity === null) {
                            common.alert("请填写数量")
                            return false
                        }
                        if (item.price === null) {
                            common.alert("请填写价格")
                            return false
                        }
                    }
                    form.submit()
                }}>保存</Button>
            <Button
                icon={<RollbackOutlined />}
                onClick={() => {
                    Search.back()
                }}>返回</Button>
        </PageTop>
        <Form
            initialValues={{
                tax: null,
                type: Receipt.RECEIPT_TYPE_BUY,
                code: '入库单号自动生成',
                buyerName: common.getUser().nickname,
                buyer: common.getUser().id,
                // receiptDate: moment().locale('zh-cn').format('YYYY/MM/DD'),
                // receiptDate: date
            }}
            form={form}
            onFinish={onFinish}
            name={'validate_other'}
            className={"ant-advanced-inline-form"}>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'deliveryNumber'}
                        label="送货单号">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'tax'}
                        rules={[{required: true, message: '请选择税率'}]}
                        label="税率">
                        <Select
                            onChange={(val) => {
                                setSubtotalByTax(val)
                            }}
                        >
                            {
                                tax.map((item) => {
                                    return <Option value={item.name}
                                        key={item.id}>{item.tax + '%'}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        rules={[{required: true, message: '请选择采购人'}]}
                        name={'buyerName'}
                        label="采购人">
                        <Input
                            readOnly={true}
                            autoComplete={"off"}
                            onKeyPress={() => {
                                selectModal(4)
                            }}
                            addonAfter={<SelectOutlined
                                onClick={() => {
                                    selectModal(4)
                                }} />}
                        />
                    </Form.Item>
                    <Form.Item name={'buyer'} label="采购人" hidden>
                        <Input readOnly={true} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-3'}
                        rules={[{required: true, message: '请选择库别'}]}
                        name={'warehouseId'}
                        label="库别">
                        <Select>
                            {
                                warehouse.map((item) => {
                                    return <Option value={item.id} key={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        rules={[{required: true, message: '请选择供应商'}]}
                        name={'supplierName'}
                        label="供应商">
                        <Input
                            readOnly={true}
                            autoComplete={"off"}
                            suffix={partner2.name !== undefined ?
                                <CloseOutlined onClick={() => {
                                    setPartner2({})
                                    form.setFieldsValue({supplierName: ""})
                                }} /> : <span />}
                            onKeyPress={() => {
                                selectModal(2)
                            }}
                            addonAfter={<SelectOutlined
                                onClick={() => {
                                    selectModal(2)
                                }} />}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        rules={[{required: true, message: '入库类型不允许为空'}]}
                        className={'label-character-4'}
                        name={'type'}
                        label="入库类型">
                        <Select>
                            <Option value={Receipt.RECEIPT_TYPE_BUY}
                                key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                            <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                            {Product.KIND_ARTICLE === action &&
                                <Option value={Receipt.RECEIPT_TYPE_EMPTY}
                                    key={Receipt.RECEIPT_TYPE_EMPTY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_EMPTY]}</Option>}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        className={'label-character-4'}
                        rules={[{required: true, message: '入库时间不允许为空'}]}
                        name={'receiptDate'}
                        label="入库时间">
                        <DatePicker
                            disabled={!(Object.keys(settingSelectReceiptDate).length > 0 && settingSelectReceiptDate.flag === Term.SETTING_YES)} />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name={'code'}
                        className={'label-character-3'}
                        label="入库单号">
                        <Input disabled={true} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        className={'label-character-4'}
                        name={'spec'}
                        label="入库备注">
                        <TextArea autoSize={{minRows: 1, maxRows: 2}} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <Table
            pagination={false}
            rowKey='id'
            columns={columns}
            dataSource={[...list, {
                isEmptyRecord: true,
                id: -1
            }]}
            scroll={{x: '100%', y: "400px"}} />
        <div style={{float: 'right'}}>
            总计:
            <Divider type="vertical" />
            配件名称: {list.length}
            <Divider type="vertical" />
            数量: {common.numberFormat(allQuantity)}
            <Divider type="vertical" />
            金额: {common.numberFormat(constPrice)}
            <Divider type="vertical" />
            去税金额: {common.numberFormat(allPriceGo)}
            <Divider type="vertical" />
            税金: {common.numberFormat(allPriceTax)}
        </div>
        <Modal
            maskClosable={false}
            visible={visible}
            title={modalTitle}
            width={1000}
            onCancel={() => {
                setVisible(false)
            }}
            destroyOnClose={true}
            footer={null}
        >
            {modalNum === 2 &&
                <Partner
                    canChangeType={false}
                    type={"供货商"}
                    dataSource={DATA_SOURCE_ALL}
                    isMultiple={false}
                    onOk={(val) => {
                        // 如果是等于2 是否添加应付款 先选择是 但是用户也可以选择否
                        // 如果是等于1 是否添加应付款 一定是否 不允许用户选择
                        if (val.isFactorySupplier === 2) {
                            form.setFieldsValue({isPayable: Receipt.RECEIPT_IS_PAYABLE_YES})
                        }
                        if (val.isFactorySupplier === 1) {
                            form.setFieldsValue({isPayable: Receipt.RECEIPT_IS_PAYABLE_NO})
                        }

                        setPartner2(val)
                        let arr = tax.filter((item) => item.name === val.receiptTax)
                        if (arr.length > 0) {
                            setSubtotalByTax(val.receiptTax)
                            form.setFieldsValue({supplierName: val.name, tax: val.receiptTax})
                        } else {
                            form.setFieldsValue({supplierName: val.name})
                        }
                        setVisible(false)
                    }} />}
            {modalNum === 3 &&
                <ProductGoods
                    brandId={common.getUser().brand.id}
                    defaultList={list}
                    showButton={["add", "update"]}
                    action={action === Product.KIND_ARTICLE ? '' : 'repair'}
                    isMultiple={true}
                    skuKind={action}
                    defaultValue={Product.KIND_GOODS}
                    onOk={productGoodsOnOk} />}
            {modalNum === 4 &&
                <LookupEmployee
                    onOk={(value) => {
                        common.consoleLog('employee', value)
                        form.setFieldsValue({'buyerName': value.name, 'buyer': value.id})
                        setVisible(false)
                    }}
                    isMultiple={false}
                />}
        </Modal>
    </>;
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)