import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    Input,
    Row,
    Form, List, Modal,
} from "antd";
import common from "../../../utils/common"
import {
    PlusOutlined,
    SaveOutlined,
} from "@ant-design/icons";
import PageTop from "../../../components/layout/PageTop";
import RemindersCreateForm from "./remindersCreateForm";
import {RepairReminders} from "../config";

function RemindersModal(props) {
    let {vin, vehicleId, canReminder = false, onOk, haveNotDataNotShow = false} = props

    let [remindersList, setRemindersList] = useState([])// 维修工单 维修提醒数据
    let [remindersVisible, setRemindersVisible] = useState(false) //  维修工单维修提醒
    let [remindersFormVisible, setRemindersFormVisible] = useState(false) //  维修工单维修提醒
    let [remindersItem, setRemindersItem] = useState({}) //  维修工单维修提醒

    let [otRemindersList, setOtRemindersList] = useState([])// 维修工单 维修提醒数据

    // 滚动条 滚动到底部加载更多
    // 避免多次触发
    const [scrollHMemory, setScrollHMemory] = useState(0)
    const scrollLoad = (e) => {
        const {scrollTop, clientHeight, scrollHeight} = e.currentTarget;
        if ((scrollTop + clientHeight) >= (scrollHeight - 30)) {
            if (scrollHMemory != scrollHeight) {
                setScrollHMemory(scrollHeight)
                getRemindersList(true)
            }
        }
    }
    const [otScrollHMemory, setOtScrollHMemory] = useState(0)
    const otScrollLoad = (e) => {
        const {scrollTop, clientHeight, scrollHeight} = e.currentTarget;
        if ((scrollTop + clientHeight) >= (scrollHeight - 30)) {
            if (otScrollHMemory != scrollHeight) {
                setOtScrollHMemory(scrollHeight)
                getOtRemindersList(true)
            }
        }
    }

    useEffect(() => {
        if (remindersFormVisible == false) {
            setRemindersItem({})
        }
    }, [remindersFormVisible])
    // 新增 维修提醒信息
    let addReminders = (params) => {
        setRemindersFormVisible(false)
        let todo = {
            title: params.title,
            type: RepairReminders.TYPE_ADD,
        }
        if (params.id) {
            todo.id = params.id
        }
        common.loadingStart();
        common.ajax('post', params.id ? '/support/repair/reminders/updateById' : '/support/repair/reminders/create', {
            ownerId: common.getUser().company.id,
            ...todo,
            content: "",
            vehicleId: vehicleId,
            photoList: [],
        })
            .then(() => {
                getRemindersList(false)
                common.toast("操作成功")
            })
            .finally(common.loadingStop)
    }

    // 维修提醒信息 分页列表
    let getRemindersList = (nextPage) => {
        if (!canReminder) {
            return;
        }
        common.loadingStart();
        common.ajax('get', '/support/repair/reminders/list', {
            ownerId: common.getUser().company.id,
            vin: vin,
            type: RepairReminders.TYPE_ADD,
            page: nextPage ? (Math.ceil(remindersList.length / 10) + 1) : 1,
            limit: 10
        })
            .then(res => {
                if (nextPage) {
                    setRemindersList([...remindersList, ...res.list])
                } else {
                    setRemindersList(res.list)
                }
                // 没有数据就 不展示模态框
                if (haveNotDataNotShow) {
                    if (res.list.length > 0) {
                        setRemindersVisible(true)
                    }
                } else {
                    setRemindersVisible(true)
                }
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getRemindersList()
    }, [])

    let getOtRemindersList = (nextPage) => {
        common.loadingStart();
        common.ajax('get', '/support/repair/reminders/list', {
            ownerId: common.getUser().company.id,
            vin: vin,
            type: RepairReminders.TYPE_AVOCATION_ORDER,
            page: nextPage ? (Math.ceil(remindersList.length / 10) + 1) : 1,
            limit: 10
        })
            .then(res => {
                if (nextPage) {
                    setOtRemindersList([...otRemindersList, ...res.list])
                } else {
                    setOtRemindersList(res.list)
                }
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        getOtRemindersList()
    }, [])

    return (
        <>
            <Modal
                // title={'维修提醒'}
                visible={remindersVisible}
                footer={null}
                width={1000}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={() => {
                    setRemindersVisible(false)
                    onOk()
                }}>
                <br />
                <div style={{height: '500px', display: 'flex'}}>
                    <div style={{flex: 1, marginRight: '16px'}}>
                        <PageTop title={"维修提醒"}>
                            <Button
                                icon={<PlusOutlined />}
                                type={"primary"}
                                onClick={() => {
                                    setRemindersFormVisible(true)
                                }}>新增</Button>
                        </PageTop>
                        <div
                            // ref={scrollableDivRef}
                            // id="scrollableDiv"
                            style={{
                                height: 450,
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                            }}
                            onScroll={scrollLoad}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={remindersList}
                                renderItem={(item) => {
                                    let description = `记录时间：${item.createdAt} \n\t 跟进人：${item.creatorName}`
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                // avatar={}
                                                title={<div style={{display: 'flex'}}>
                                                    <div style={{width: 0, flex: 1}}>{item.title}</div>
                                                    <a onClick={() => {
                                                        setRemindersItem(item)
                                                        setRemindersFormVisible(true)
                                                    }}>修改</a>
                                                </div>}
                                                description={description}
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </div>
                    <div style={{flex: 1}}>
                        <PageTop title={"其他提醒"}></PageTop>
                        <div
                            // ref={scrollableDivRef}
                            // id="scrollableDiv"
                            style={{
                                height: 450,
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                            }}
                            onScroll={otScrollLoad}
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={otRemindersList}
                                renderItem={(item) => {
                                    let description = `记录时间：${item.createdAt} \n\t 跟进人：${item.creatorName}`
                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                // avatar={}
                                                title={<div style={{display: 'flex'}}>
                                                    <div style={{width: 0, flex: 1}}>{item.title}</div>
                                                </div>}
                                                description={description}
                                            />
                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                // title={'维修提醒'}
                visible={remindersFormVisible}
                footer={null}
                width={500}
                maskClosable={false}
                destroyOnClose={true}
                onCancel={() => {
                    setRemindersFormVisible(false)
                }}>
                {remindersFormVisible &&
                    <RemindersCreateForm values={remindersItem} onOk={(val) => {
                        addReminders(val)
                    }} />}
            </Modal>
        </>
    );
}

export default RemindersModal