import React from 'react'
import {Route, Switch} from "react-router-dom";
import AudioPlayer from './audioPlayer'
import AudioPlayer2 from './audioPlayer2'
import common from '../../utils/common';

function SocketIndex() {
    return (
        <Switch>
            <Route path={'/example/socket/audio'} component={AudioPlayer} />
            {
                common.can('support.repair.quick') && <Route path={'/example/socket/quick'} component={AudioPlayer2} />
            }

        </Switch>
    )
}

export default SocketIndex
