import React, {useEffect, useState} from "react";
import {RestFilled, PlusOutlined} from "@ant-design/icons";
import {Button, Image, Empty, Tabs, Table, Modal} from "antd";
import "./AudioTable.css";
import common from "../../utils/common";
import PageTop from "../../components/layout/PageTop";
import './style.css'
import mqtt from "mqtt";
import noneImg from './none-img.svg'
import LookupVehicle from "../../components/support/LookupVehicle";
import moment from "moment";

const AudioTable = (props) => {
    const [clientConfig, setClientConfig] = useState({
        "password": "",
        "clientId": "",
        "broker": "",
        "username": "",
        "topic": ""
    }); // 请求配置
    const [audioList, setAudioList] = useState([]); // 存储音频列表
    const [audioEdList, setAudioEdList] = useState([]); // 存储音频列表
    const [isDataLoaded, setIsDataLoaded] = useState(0); // 标记数据是否已加载
    const [isConnected, setIsConnected] = useState(false);
    const [lookupVehicleVisible, setLookupVehicleVisible] = useState(false);
    const [tabActive, setTabActive] = useState('unorder');
    const [whitelist, setWhiteList] = useState([]);

    // 获取初始列表数据
    useEffect(() => {


        fetchData();
        fetchDataEd();
        fetchWhites()
    }, []);
    const fetchData = async () => {
        common.loadingStart();
        try {
            const data = await common.ajax("get", "/support/vehicle/recognition/page", {
                ownerId: common.getUser().company.id,
                action: "new",
                createdAtStart: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                createdAtEnd: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            });

            if (data?.list && Array.isArray(data.list)) {
                common.consoleLog("Initial data received:", data.list);
                setAudioList([...data.list]); // 确保触发 React 渲染
                setIsDataLoaded(1);
            } else {
                console.warn("Invalid data format received:", data);
            }
        } catch (error) {
            console.error("Error fetching initial data:", error);
        } finally {
            common.loadingStop();
        }
    };
    const fetchDataEd = async () => {
        common.loadingStart();
        const data = await common.ajax("get", "/support/vehicle/recognition/page", {
            ownerId: common.getUser().company.id,
            action: "have_order",
            createdAtStart: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            createdAtEnd: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        });

        if (data?.list && Array.isArray(data.list)) {
            setAudioEdList([...data.list]); // 确保触发 React 渲染
        }
        common.loadingStop();
    };
    const fetchWhites = async () => {
        common.ajax("get", "/support/term/list", {
            ownerId: common.getUser().company.id,
            taxonomy: "vehicleWhitelistRecognition",
        }).then(res => {
            if (res && res.terms) {
                setWhiteList(res.terms)
            } else {
                setWhiteList([])
            }
        })
    }

    // 获取链接配置
    useEffect(() => {
        if (isDataLoaded !== 1) return;
        common.loadingStart()
        common.ajax('get', '/support/mqtt/assign-clientId', {
            ownerId: common.getUser().company.id,
            type: "thirdPartyPush"
        }).then((res) => {
            setClientConfig(res)
            setIsDataLoaded(2);
        }).finally(common.loadingStop)
    }, [isDataLoaded]);

    // 确保数据加载后再建立 mqtt 连接
    useEffect(() => {
        if (isDataLoaded !== 2) return;

        // 连接 MQTT 服务器
        const mqttClient = mqtt.connect(clientConfig.broker, {
            username: clientConfig.username,
            password: clientConfig.password,
            reconnectPeriod: 1000, // 自动重连
            clean: false, // 保持会话
            clientId: clientConfig.clientId,
        });

        mqttClient.on("connect", () => {
            common.consoleLog("连接成功");
            setIsConnected(true);
            // 订阅主题
            mqttClient.subscribe(clientConfig.topic, {qos: 1}, (err) => {
                if (!err) {
                    common.consoleLog(`已订阅主题: ${clientConfig.topic}`);
                }
            });
        });

        // 接收到消息时处理
        mqttClient.on("message", (topic, message) => {
            common.consoleLog(`收到消息: ${message.toString()}`);
            const data = JSON.parse(message.toString());
            const {plate, imageUrl, id} = data;
            const newAudio = {id: id, imageUrl: imageUrl, plate: plate};
            setAudioList((prevAudioList) => [...[newAudio], ...prevAudioList]);
        });

        mqttClient.on("error", (err) => {
            common.consoleLog("连接错误:", err);
        });

        mqttClient.on("close", () => {
            common.consoleLog("连接关闭");
            setIsConnected(false);
        });

        // 清理连接
        return () => {
            mqttClient.end();
        };
    }, [isDataLoaded]);

    // 点击事件
    const handleClick = (item) => {
        common.consoleLog("点击", item);
        if (item) {
            sessionStorage.setItem('QUICK_VEHICLE', JSON.stringify({
                ...item
            }))
            props.history.push('/support/repair/quick')
        }
    };

    return (
        <div>
            <PageTop title={'快捷保修'}>
                {
                    tabActive !== 'ordered' && <Button
                        icon={<PlusOutlined/>}
                        type="primary"
                        onClick={() => {
                            if (tabActive == 'unorder') {
                                props.history.push('/support/repair/quick')
                            } else {
                                setLookupVehicleVisible(true)
                            }
                        }}>新增</Button>
                }
            </PageTop>
            <Tabs defaultActiveKey="unorder" onChange={e => {
                setTabActive(e)
            }}>
                <Tabs.TabPane tab="入厂车列表" key="unorder">
                    <Image.PreviewGroup
                        items={audioList}
                    >
                        <div style={{display: 'flex', flexWrap: 'wrap'}} className="imgList">
                            {
                                audioList.map((item, index) => {
                                    return (
                                        <div key={index}
                                             style={{
                                                 padding: '5px',
                                                 margin: '5px',
                                                 position: 'relative',
                                                 boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)',
                                                 cursor: 'pointer'
                                             }}>
                                            {item.imageUrl ? <Image
                                                width={160}
                                                height={160}
                                                src={item.imageUrl}
                                            /> : <Image
                                                width={160}
                                                height={160}
                                                style={{padding: '20px'}}
                                                src={noneImg}
                                            />}
                                            <div style={{lineHeight: '30px', textAlign: 'center', fontWeight: 800}}><a
                                                onClick={() => handleClick(item)}
                                            >{item.plate}</a></div>
                                        </div>
                                    )
                                })
                            }
                            {
                                audioList.length == 0 && <Empty description='暂无入厂车列表'/>
                            }
                        </div>
                    </Image.PreviewGroup>
                </Tabs.TabPane>
                <Tabs.TabPane tab="已开单列表" key="ordered">
                    <Image.PreviewGroup
                        items={audioEdList}
                    >
                        <div style={{display: 'flex', flexWrap: 'wrap'}} className="imgList">
                            {
                                audioEdList.map((item, index) => {
                                    return (
                                        <div key={index}
                                             style={{
                                                 padding: '5px',
                                                 margin: '5px',
                                                 position: 'relative',
                                                 boxShadow: '0 0 4px 0 rgba(0,0,0,0.2)',
                                                 cursor: 'pointer'
                                             }}>
                                            {item.imageUrl ? <Image
                                                width={160}
                                                height={160}
                                                src={item.imageUrl}
                                            /> : <Image
                                                width={160}
                                                height={160}
                                                style={{padding: '20px'}}
                                                src={noneImg}
                                            />}
                                            <div style={{lineHeight: '30px', textAlign: 'center', fontWeight: 800}}>
                                                <p
                                                    // onClick={() => handleClick(item)}
                                                >{item.plate}</p></div>
                                        </div>
                                    )
                                })
                            }
                            {
                                audioEdList.length == 0 && <Empty description='暂无已开单车列表'/>
                            }
                        </div>
                    </Image.PreviewGroup>
                </Tabs.TabPane>
                <Tabs.TabPane tab="白名单列表" key="whites">
                    <Table scroll={{x: '100%'}}
                           rowKey={record => record.id}
                           pagination={false}
                           columns={[
                               {
                                   title: '车牌号',
                                   dataIndex: 'name',
                                   ellipsis: true,
                               },
                               {
                                   title: '操作',
                                   key: 'operation',
                                   align: 'center',
                                   width: 100,
                                   render:
                                       (text, record) => {
                                           return (
                                               <div className={"action-btns"}>
                                                   <Button type="link" onClick={() => {
                                                       // selectId = record.id
                                                       // setSelectId(selectId)
                                                       // setModalVisible(true)
                                                       common.ajax("post", "/support/vehicle/recognition/whitelist/delete", {
                                                           id: record.id,
                                                       }).then(res => {
                                                           setLookupVehicleVisible(false)
                                                           common.toast('删除白名单成功')
                                                           fetchWhites()
                                                       })
                                                   }}>删除</Button>
                                               </div>
                                           )
                                       }
                               },]}
                           dataSource={whitelist}
                    />
                </Tabs.TabPane>
            </Tabs>
            <Modal
                maskClosable={false}
                title="选择车型"
                visible={lookupVehicleVisible}
                width={1000}
                footer={null}
                onCancel={() => {
                    setLookupVehicleVisible(false)
                }}>
                <LookupVehicle
                    onOk={(value) => {
                        common.consoleLog(value)
                        if (value && value.plate) {
                            common.ajax("post", "/support/vehicle/recognition/whitelist/create", {
                                ownerId: common.getUser().company.id,
                                plate: value.plate,
                            }).then(res => {
                                setLookupVehicleVisible(false)
                                fetchWhites()
                                common.toast('添加白名单成功')
                            })
                        }
                    }}
                    isMultiple={false}  //如果想单选，传false
                />
            </Modal>
        </div>
    );
};

export default AudioTable;