import React, {useEffect, useState} from 'react';
import {CheckSquareOutlined, PlusOutlined} from '@ant-design/icons';
import {Button, Divider, Input, InputNumber, Modal, Select, Table} from 'antd';
import "../style.less"
import common from "../../../../utils/common";
import BigNumber from "bignumber.js";
import PageBottom from "../../../../components/layout/PageBottom";
import LookupBoutique from "../../../../components/sale/LookupBoutique";
import {SaleOrderItem} from "../../../../components/sale/config";

const IS_ANSWER_YES = 'Y'
const IS_ANSWER_NO = 'N'

const IsAnswerAlias = {
    1: "是",
    2: "否",
    [IS_ANSWER_YES]: '是',
    [IS_ANSWER_NO]: '否'
}

function Articles(props) {
    let {order, setOrder, isSettlement} = props

    const columns = [
        {
            title: "套餐名称",
            dataIndex: "planName",
            width: 100,
            align: "center",
            ellipsis: true,
            render: (text) => {
                return text ? text : '-'
            }
        },
        {
            title: "精品名称",
            dataIndex: "productName",
            width: 100,
            ellipsis: true
        },
        {
            title: "精品编号",
            dataIndex: "productCode",
            width: 80,
            ellipsis: true
        },
        {
            title: "计划售价",
            dataIndex: "price",
            width: 100,
            align: 'right',
            ellipsis: true,
            render: (text, record) => {
                if (record.type !== 'plan') {
                    return <InputNumber
                        defaultValue={record.price}
                        min={0.01}
                        precision={2}
                        onChange={val => {
                            if (!isNaN(val)) {
                                setDataSource(dataSource.map(item => {
                                    if (item.type !== 'plan' && item.id === record.id) {
                                        return {
                                            ...item,
                                            price: val,
                                            total: new BigNumber(record.quantity ? record.quantity : '0.00')
                                                .multipliedBy(new BigNumber(record.price ? record.price : '0.00'))
                                                .toFixed(2)
                                        }
                                    }

                                    return item
                                }))
                            }
                        }}/>
                }

                return text
            }
        },
        {
            title: "数量",
            dataIndex: "quantity",
            align: 'center',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.type !== 'plan') {
                    return <InputNumber
                        defaultValue={record.quantity}
                        min={0.01}
                        precision={2}
                        onChange={val => {
                            if (!isNaN(val)) {
                                setDataSource(dataSource.map(item => {
                                    if (item.type !== 'plan' && item.id === record.id) {
                                        return {
                                            ...item,
                                            quantity: val,
                                            total: new BigNumber(record.quantity ? record.quantity : '0.00')
                                                .multipliedBy(new BigNumber(record.price ? record.price : '0.00'))
                                                .toFixed(2)
                                        }
                                    }

                                    return item
                                }))
                            }
                        }}/>
                }

                return text
            }
        },
        {
            title: "仓库",
            dataIndex: 'warehouseName',
            width: 100,
            ellipsis: true,
        },
        {
            title: "是否需要施工",
            dataIndex: "needProcess",
            width: 80,
            ellipsis: true,
            render: (text) => text in IsAnswerAlias ? IsAnswerAlias[text] : text
        },
        {
            title: "出库方式",
            dataIndex: "remarkBuy",
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                return <div style={{display: "flex", justifyContent: "flex-start"}}>
                    <Select
                        value={text}
                        style={{width: '60%'}}
                        onChange={(val) => {
                            setDataSource(dataSource.map((item) => {
                                if (item.id === record.id) {
                                    return {
                                        ...item,
                                        remarkBuy: val,
                                    }
                                }
                                return item
                            }))
                        }}>
                        <Select.Option value={SaleOrderItem.REMARK_BUY_PURCHASE}
                                       key={SaleOrderItem.REMARK_BUY_PURCHASE}>{SaleOrderItem.remarkBuyNames[SaleOrderItem.REMARK_BUY_PURCHASE]}</Select.Option>
                        <Select.Option value={SaleOrderItem.REMARK_BUY_GIVE}
                                       key={SaleOrderItem.REMARK_BUY_GIVE}>{SaleOrderItem.remarkBuyNames[SaleOrderItem.REMARK_BUY_GIVE]}</Select.Option>
                    </Select>
                    <Button icon={<CheckSquareOutlined/>}
                            hidden={!(index === 0 && record.remarkBuy !== null && record.remarkBuy !== undefined && record.remarkBuy !== "")}
                            onClick={() => {
                                setDataSource(dataSource.map((item) => {
                                    return {
                                        ...item,
                                        remarkBuy: record.remarkBuy
                                    }
                                }))
                            }}>批量
                    </Button>
                </div>
            }
        },
        {
            title: "销售单价",
            dataIndex: "remarkPrice",
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return <InputNumber
                    defaultValue={record.remarkPrice}
                    min={0}
                    precision={2}
                    onChange={val => {
                        if (!isNaN(val)) {
                            setDataSource(dataSource.map(item => {
                                if (item.id === record.id) {
                                    return {
                                        ...item,
                                        remarkPrice: val ? new BigNumber(val).toFixed(2) : '0.00',
                                        remarkTotal: new BigNumber(record.quantity ? record.quantity : '0.00')
                                            .multipliedBy(new BigNumber(val ? new BigNumber(val).toFixed(2) : '0.00'))
                                            .toFixed(2)
                                    }
                                }

                                return item
                            }))
                        }
                    }}/>
            }
        },
        {
            title: "销售金额",
            dataIndex: "remarkTotal",
            width: 100,
            align: 'right',
            ellipsis: true,
        },
        {
            title: "备注",
            dataIndex: "remark",
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Input.TextArea
                        value={text}
                        autoSize={{minRows: 1, maxRows: 2}}
                        onChange={(e) => {
                            setDataSource(dataSource.map(item => {
                                if (item.id === record.id) {
                                    return {
                                        ...item,
                                        remark: e.target.value,
                                    }
                                }

                                return item
                            }))
                        }}/>)
            }
        },
        {
            title: '操作',
            dataIndex: 'operation',
            width: 80,
            align: 'center',
            fixed: 'right',
            render: (text, record) => {
                return (
                    <div className={'action-btns'}>
                        {
                            <Button type="link" onClick={() => {
                                common.confirm("确定要删除吗?", () => {
                                    let list = [...dataSource]
                                    if (record.type === 'plan') {
                                        list = list.filter(val => (!val.planId || val.planId !== record.planId))
                                    } else {
                                        list = list.filter(val => (val.skuId !== record.skuId || val.type === 'plan'))
                                    }
                                    setDataSource(list)
                                    setDefaultCheckedList(list)
                                    // setIsRest(true)
                                })
                            }}>删除</Button>
                        }
                    </div>
                )
            }
        },
    ];

    const settlementColumns = [
        {
            title: "精品名称",
            dataIndex: "productName",
            width: 80,
            ellipsis: true
        },
        {
            title: "精品编号",
            dataIndex: "productCode",
            width: 80,
            ellipsis: true
        },
        {
            title: "单价",
            dataIndex: "price",
            width: 60,
            ellipsis: true
        },
        {
            title: "库存",
            dataIndex: "salableQuantity",
            width: 60,
            ellipsis: true
        },
        {
            title: "数量",
            dataIndex: "quantity",
            width: 60,
            ellipsis: true,
        },
        {
            title: "是否需要施工",
            dataIndex: "needProcess",
            width: 100,
            ellipsis: true,
            render: (text) => text in IsAnswerAlias ? IsAnswerAlias[text] : text
        },
    ];

    let [numbers, setNumbers] = useState(0)
    let [money, setMoney] = useState("0")
    let [buyMoney, setBuyMoney] = useState("0.00")//购买总金额
    let [sendMoney, setSendMoney] = useState("0.00")//赠送总金额
    // let [isRest, setIsRest] = useState(false)
    let [dataSource, setDataSource] = useState([])
    let [lookupSkuProductGoodsVisible, setLookupSkuProductGoodsVisible] = useState(false)
    let [defaultCheckedIds, setDefaultCheckedIds] = useState([])
    let [defaultCheckedList, setDefaultCheckedList] = useState([])

    useEffect(() => {
        if (order.isEdit) {
            let ids = []
            let list = []
            order.parts.map(val => {
                ids.push(val.skuId)
                list.push({
                    id: val.skuId,
                    productId: val.productId,
                    productName: val.productName
                })
            })

            setDefaultCheckedList(list)
            if (ids.length > 0) {
                let price = new BigNumber('0')
                let num = '0'
                let buyTotal = '0.00'
                let sendTotal = '0.00'

                order.parts.map((val) => {
                    val.key = val.id

                    // 总数量
                    num = common.numberCut(new BigNumber(num).plus(val.quantity ? val.quantity : '0'), 2)

                    // 计划售价
                    price = price.plus(new BigNumber(val.price).multipliedBy(val.quantity ? val.quantity : 0))

                    // 分类
                    if (val.planId) {
                        // 方案
                        val.type = 'plan'
                    } else {
                        // 精品
                        val.type = 'bouitqe'
                    }

                    if (val.remarkBuy !== null && val.remarkBuy !== '') {
                        if (val.remarkBuy === SaleOrderItem.REMARK_BUY_PURCHASE) {
                            // 购买金额(出库方式为购买)
                            buyTotal = new BigNumber(val.remarkPrice ? val.remarkPrice : 0).multipliedBy(val.quantity ? val.quantity : 0).plus(buyTotal).toFixed(2)
                        } else {
                            // 赠送金额(出库方式为赠送)
                            sendTotal = new BigNumber(val.remarkPrice ? val.remarkPrice : 0).multipliedBy(val.quantity ? val.quantity : 0).plus(sendTotal).toFixed(2)
                        }
                    }
                })

                setDefaultCheckedIds(Array.from(new Set(ids)))

                // 总数量
                setNumbers(num)

                // 计划售价
                setMoney(price.toString())

                // 购买
                setBuyMoney(buyTotal)

                // 赠送
                setSendMoney(sendTotal)

                setDataSource(order.parts)
                setOrder({...order, isEdit: false})

            } else {
                setOrder({...order, isEdit: false})
            }
        } else {
            let price = new BigNumber('0')
            let num = '0'
            let buyTotal = '0.00'
            let sendTotal = '0.00'

            dataSource.forEach(val => {
                // 总数量
                num = common.numberCut(new BigNumber(num).plus(val.quantity ? val.quantity : '0'), 2)

                // 计划售价
                price = price.plus(new BigNumber(val.price).multipliedBy(val.quantity ? val.quantity : 0))

                if (val.remarkBuy !== null && val.remarkBuy !== '') {
                    if (val.remarkBuy === SaleOrderItem.REMARK_BUY_PURCHASE) {
                        // 购买金额(出库方式为购买)
                        buyTotal = new BigNumber(val.remarkPrice ? val.remarkPrice : 0).multipliedBy(val.quantity ? val.quantity : 0).plus(buyTotal).toFixed(2)
                    } else {
                        // 赠送金额(出库方式为赠送)
                        sendTotal = new BigNumber(val.remarkPrice ? val.remarkPrice : 0).multipliedBy(val.quantity ? val.quantity : 0).plus(sendTotal).toFixed(2)
                    }
                }
            })

            // 总数量
            setNumbers(num)

            // 计划售价
            setMoney(price.toString())

            // 购买
            setBuyMoney(buyTotal)

            // 赠送
            setSendMoney(sendTotal)
            setOrder({...order, parts: dataSource, partsPrice: common.numberCut(price.toString(), 2)})
        }
    }, [dataSource])

    function handleDataSource(value) {
        let planArr = value.filter(item => item.type === 'plan')
        let boutiqueArr = value.filter(item => item.type === 'boutique')

        if (planArr.length > 0) {
            let array = []

            // 获取已选择的方案数据
            let ids = dataSource.filter(item => item.type === 'plan').map(item => item.planId)

            // 新数据
            let newPlanList = planArr.filter(item => ids.indexOf(item.id) === -1)

            newPlanList.forEach(item => {
                item.planItemDtoList.forEach(planItem => {
                    planItem.planName = item.name
                    planItem.type = 'plan'
                    array.push(planItem)
                })
            })

            let arr = array.map((val, index) => {
                return {
                    ...val,
                    key: 'plan' + val.planId + val.skuId + val.productCode + index.toString(),
                    quantity: val.quantity,
                    planId: val.planId,
                    needProcess: val.work,
                    productName: val.productName,
                    productCode: val.number,
                    skuId: val.skuId,
                    total: common.numberCut(new BigNumber(val.planPrice).multipliedBy(val.quantity).toString(), 2),
                    salableQuantity: '',
                    price: val.planPrice,
                    deliveryPrice: val.price,
                    remarkBuy: null,
                    remarkPrice: '0.00',
                    remark: '',
                }
            })
            setDataSource([...dataSource, ...arr])
        }

        if (boutiqueArr.length > 0) {
            let ids = dataSource.filter(item => item.type === 'boutique').map(item => item.skuId)
            // 新数据
            value = value.filter(item => ids.indexOf(item.id) === -1).map(item => item)
            value.forEach((val, index) => {
                val.key = 'bouitqe' + val.id + val.productCode + index.toString()
                val.planId = ''
                val.quantity = 1
                val.planPrice = '0'
                val.needProcess = val.productDto.work === "Y" ? 1 : 2
                val.productName = val.productDto.name
                val.productCode = val.productDto.number
                val.skuId = val.id
                val.total = val.price
                val.deliveryPrice = val.price
                val.warehouseName = val.warehouseDto.name
                val.remarkBuy = null
                val.remarkPrice = '0.00'
                val.remark = ''

                dataSource.push(val)
            })
            let list = []
            value.forEach(val => {
                list.push({
                    id: val.id,
                    productName: val.productDto.name,
                    productId: val.productDto.id
                })
            })
            setDefaultCheckedList(list)
            setDataSource([...dataSource])
        }
        // setIsRest(true)
        setLookupSkuProductGoodsVisible(false)
    }

    return (
        <div className={"articles"}>
            {
                !isSettlement &&
                <Button style={{marginBottom: 16}} onClick={() => setLookupSkuProductGoodsVisible(true)}
                        icon={<PlusOutlined/>}>
                    精品选择
                </Button>
            }
            <Table
                rowKey={record => record.key}
                dataSource={dataSource}
                columns={isSettlement ? settlementColumns : columns}
                pagination={false}
                scroll={{x: true}}
            />
            <PageBottom>
                <div>
                    总数量: {numbers}
                    <Divider type="vertical"/>
                    计划售价: {common.numberFormat(money)}
                    <Divider type="vertical"/>
                    购买: {common.numberFormat(buyMoney)}
                    <Divider type="vertical"/>
                    赠送: {common.numberFormat(sendMoney)}
                </div>
            </PageBottom>
            <div>

                <Modal title=""
                       maskClosable={false}
                       destroyOnClose={true}
                       visible={lookupSkuProductGoodsVisible}
                       width={1000}
                       footer={null}
                       onCancel={() => {
                           setLookupSkuProductGoodsVisible(false)
                       }}>

                    {/*需要多选，则isMultiple=true*/}
                    {/*精品选择，则defaultValue={"boutique"}*/}
                    {/*配件选择，则defaultValue={"part"}*/}
                    {/*配件和精品选择，则defaultValue={"goods"}*/}
                    {/*<SkuProductGoods*/}
                    {/*    isMultiple={true}*/}
                    {/*    defaultValue={"boutique"}*/}
                    {/*    onOk={handleDataSource}/>*/}
                    <LookupBoutique
                        defaultList={defaultCheckedList}
                        isMultiple={true}
                        onSave={handleDataSource}
                        productId={order.vehicle.productId}/>
                </Modal>

            </div>

        </div>
    );
}

export default Articles
