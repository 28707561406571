import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Input, InputNumber, Modal, Popconfirm, Row, Table, Form, Tooltip, DatePicker, Divider} from "antd";
import LookupCompany from "../../../../components/passport/LookupCompany"
import common from "../../../../utils/common"
import {CloseOutlined, QuestionCircleOutlined, SaveOutlined, SelectOutlined, StopOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import {
    Allot,
    Basic,
    Product,
    ProductCategoryRate,
    ProductGeneralRate,
    Receipt,
    wmsPublic
} from "../../../../components/wms/config";
import GoodsReceiptItem from "../../../../components/wms/LookupGoodsReceiptItem";
import moment from "moment";
import BigNumber from "bignumber.js";

const {TextArea} = Input

function AllotForm(props) {
    // action 2(精品) 3(配件)
    let {onOk, action} = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [list, setList] = useState([]) // 表格数据
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [company, setCompany] = useState({});//接收方
    let [subtotal, setSubtotal] = useState({
        quantity: '0.00',
        total: '0.00',
        costTotal: '0.00',
        skuId: 0,
    });//小计

    let companyId = common.getUser().company.id

    // 获取列表数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/listForDelivery", {
            types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER, Receipt.RECEIPT_TYPE_ALLOT, Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_STOCK, Receipt.RECEIPT_TYPE_ADJUST_SKU],
            brandId: common.getUser().brand.id,
            kind: action,
            ownerId: common.getUser().company.id,
            number: params,
            isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
            isHaveGoodsInStock: Basic.IS_ANSWER_YES,//要有库存
            chargeType: action === Allot.TYPE_PART ? ProductGeneralRate.CHARGE_TYPE_SALE : ProductGeneralRate.CHARGE_TYPE_BOUTIQUE,
            orderType: ProductCategoryRate.ORDER_TYPE_ALLOT,
        }).then((data) => {
            if (data.receiptItems.length === 1) {
                let arr = list.filter(item => item.id === data.receiptItems[0].id)
                if (arr.length > 0) {
                    productGoodsOnOk(list)
                } else {
                    // 调拨出库：强制按出库类型定价的调拨出库类型计算，如果没有提示让设置
                    if (data.receiptItems[0].priceRate === null) {
                        common.toast((action === Allot.TYPE_PART ? "配件:" : "精品:") + data.receiptItems[0].productDto.name + "未设置加价率")
                        return false;
                    }

                    productGoodsOnOk([...list, ...data.receiptItems])
                }
                setTimeout(() => {
                    emptyInput.current.focus()
                }, 100)
            } else {
                setModalNumber(1)
                setModalTitle(action === Allot.TYPE_PART ? '配件选择' : '精品选择')
                setVisible(true)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        style={{whiteSpace: 'pre-wrap'}}
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={"输入编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setModalNumber(1)
                                    setModalTitle('配件选择')
                                    setVisible(true)
                                }}/>
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.productDto === undefined) {
                    return ''
                }

                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '可出库数量',
            dataIndex: 'salableQuantity',
            align: 'right',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        max={record.salableQuantity}
                        min={0.01}
                        value={text}
                        onChange={(val) => {
                            setList(list.map((item, index2) => {
                                if (index2 === index) {
                                    return {
                                        ...item,
                                        quantity: val
                                    }
                                } else {
                                    return item;
                                }
                            }))
                        }}
                    />)
            }
        },
        {
            title: '计划售价',
            width: 100,
            dataIndex: 'priceConst',
            align: 'right',
            ellipsis: true,
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '出库价',
            dataIndex: 'price',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (<InputNumber
                    style={{width: '100%'}}
                    onPressEnter={wmsPublic.onPressEnter}
                    min={0.01}
                    precision={2}
                    value={text}
                    onChange={(val) => {
                        setList(list.map((item, index2) => {
                            if (index2 === index) {
                                return {
                                    ...item,
                                    price: val ? new BigNumber(val).toFixed(2) : null
                                }
                            } else {
                                return item;
                            }
                        }))
                    }}
                />)
            }
        },
        {
            title: '含税进价',
            width: 100,
            dataIndex: 'priceCost',
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                return common.numberFormat(text)
            }
        },
        {
            title: '加价率',
            width: 100,
            dataIndex: 'priceRate',
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                if (record.price === null || record.price === '') {
                    return '0%'
                }

                return (new BigNumber(record.price)
                        .minus(record.priceCost))
                        .dividedBy(record.priceCost)
                        .multipliedBy(100)
                        .toFixed(0)
                    + "%"
            }
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '税率',
            width: 100,
            dataIndex: 'tax',
            ellipsis: true,
            align: 'right',
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }

                if (record.tax === null || record.tax === '') {
                    return '0%'
                }

                return new BigNumber(record.tax)
                        .multipliedBy(100)
                        .toFixed(0)
                    + "%"
            }
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '出库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    onChange={(e) => {
                        list[index].spec = e.target.value
                    }}/>)
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                        <Popconfirm
                            title="确定要删除吗?"
                            onConfirm={() => {
                                setList(list.filter((item) => {
                                    return item.id !== record.id
                                }))
                            }}
                            okText="确定"
                            cancelText="取消">
                            <Button type={'link'}>删除</Button>
                        </Popconfirm>}
                    </div>)
            }
        }
    ]

    // 精品选择
    let productGoodsOnOk = (val) => {
        // 调拨出库：强制按出库类型定价的调拨出库类型计算，如果没有提示让设置
        let errorArr = val.filter(item => item.priceRate === null);
        if (errorArr.length > 0) {
            common.toast((action === Allot.TYPE_PART ? "配件:" : "精品:") + errorArr[0].productDto.name + "未设置加价率")
            return false;
        }

        let arr = wmsPublic.productGoodsOnOk(val, list)

        setList(arr)
        setVisible(false)
        setNewValue('')
    }

    const [form] = Form.useForm()

    let onFinish = (values) => {
        // key:商品ID value:入库单明细数据
        let myObject = {}
        list.forEach(item => {
            if (item.productId in myObject) {
                myObject[item.productId].push(item)
            } else {
                myObject[item.productId] = [item]
            }
        })

        for (let key in myObject) {
            let deliveryItemList = myObject[key]

            // 同一个调拨单 相同的商品只能同一个税率 同一个出库价
            let priceArr = deliveryItemList.map(item => item.price)
            if (1 !== new Set(priceArr).size) {
                common.toast("同一个调拨单,相同的商品: " + deliveryItemList[0].productDto.name + " 出库价必须相同")
                return false
            }

            let taxArr = deliveryItemList.map(item => item.tax)
            if (1 !== new Set(taxArr).size) {
                common.toast("同一个调拨单,相同的商品: " + deliveryItemList[0].productDto.name + " 税率必须相同")
                return false
            }
        }

        onOk({
            ...values,
            ownerId: companyId,
            companyIdOut: companyId,
            type: action,
            companyIdIn: company.id,
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD') + " " + moment(new Date(), "HH:mm:ss").format('HH:mm:ss'),
            deliveryItem: list.map((item) => {
                return {
                    productId: item.productId,
                    receiptItemId: item.id,
                    quantity: item.quantity,
                    price: item.price,
                    spec: item.spec,
                }
            })
        })
    }

    useEffect(() => {
        // 小计
        let quantity = '0.00'
        let total = '0.00'
        let costTotal = '0.00'
        let skuIdArr = []
        list.forEach((item) => {
            if (item.quantity != null && item.quantity !== '') {
                quantity = new BigNumber(item.quantity).plus(new BigNumber(quantity)).toFixed(2)

                if (item.price != null && item.price !== '') {
                    total = new BigNumber(item.quantity).multipliedBy(new BigNumber(item.price)).plus(new BigNumber(total)).toFixed(2)
                    costTotal = new BigNumber(item.quantity).multipliedBy(new BigNumber(item.priceCost)).plus(new BigNumber(costTotal)).toFixed(2)
                }
            }

            skuIdArr.push(item.skuId)
        })

        setSubtotal({
            quantity: quantity,
            total: total,
            costTotal: costTotal,
            skuId: new Set(skuIdArr).size,
        })
    }, [list])

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({deliveryDate: moment(res)})
            })
            .finally(common.loadingStop)
    }

    useEffect(getDate, [])

    return (
        <>
            <br/>
            <PageTop title={action === Allot.TYPE_PART ? '新增配件调拨单' : '新增精品调拨单'}>
                <Button
                    icon={<SaveOutlined/>}
                    type={"primary"}
                    onClick={() => {
                        if (Object.keys(company).length === 0) {
                            common.alert("请选择接收方")
                            return false
                        }
                        if (list.length === 0) {
                            if (action === Allot.TYPE_PART) {
                                common.alert("请选择配件")
                            }
                            if (action === Allot.TYPE_ARTICLE) {
                                common.alert("请选择精品")
                            }
                            return false
                        }
                        for (let item of list) {
                            if (item.quantity === null || item.quantity === '') {
                                common.alert("请填写出库数量")
                                return false
                            }
                            if (item.price === null || item.price === '') {
                                common.alert("请填写出库价格")
                                return false
                            }
                        }
                        form.submit()
                    }}>保存</Button>
            </PageTop>
            <Form
                initialValues={{
                    deliverySpec: '',
                    spec: '',
                    // deliveryDate: moment(new Date().toLocaleDateString(), "YYYY/MM/DD"),
                    companyName: '',
                    employeeName: common.getUser().nickname
                }}
                form={form}
                onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'companyName'}
                            rules={[{required: true, message: '接收方不允许为空'}]}
                            label="接收方"
                            className={'label-character-3'}>
                            <Input
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    company.id !== undefined ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setCompany({})
                                                form.setFieldsValue({companyName: ""})
                                            }}/> : <span/>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(3)
                                            setModalTitle('选择接收方')
                                            setVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setModalNumber(3)
                                    setModalTitle('选择接收方')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="出库时间">
                            <DatePicker
                                disabled={true}
                                style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'employeeName'}
                            rules={[{required: true, message: '出库人不允许为空'}]}
                            label="出库人">
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-3'}
                            name={'spec'}
                            label="备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Table
                    pagination={false}
                    rowKey='id'
                    columns={columns}
                    dataSource={[...list, {
                        isEmptyRecord: true,
                        id: -1
                    }]}
                    scroll={{x: '100%', y: '400px'}}/>
                <Row gutter={24} style={{marginTop: 20}}>
                    <Col span={24}>
                        <Form.Item
                            name={'deliverySpec'}
                            label="出库备注">
                            <TextArea autoSize={{minRows: 1, maxRows: 2}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{marginTop: '10px'}}>
                    SKU: {common.numberFormat(subtotal.skuId)}
                    <Divider type="vertical"/>
                    调拨数量: {common.numberFormat(subtotal.quantity)}
                    <Divider type="vertical"/>
                    出库金额: {common.numberFormat(subtotal.total)}
                    <Divider type="vertical"/>
                    成本金额: {common.numberFormat(subtotal.costTotal)}
                </div>
            </Form>
            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 1 &&
                <GoodsReceiptItem
                    defaultValue={list}
                    productNumber={newValue}
                    action={action === Allot.TYPE_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART}
                    chargeType={action === Allot.TYPE_ARTICLE ? ProductGeneralRate.CHARGE_TYPE_BOUTIQUE : ProductGeneralRate.CHARGE_TYPE_SALE}
                    orderType={ProductCategoryRate.ORDER_TYPE_ALLOT}
                    onOk={productGoodsOnOk}/>}
                {modalNumber === 3 &&
                <LookupCompany
                    isMultiple={false}
                    onOk={(val) => {
                        if (val.id === companyId) {
                            common.toast('接收方不允许是本公司')
                            return false
                        }
                        form.setFieldsValue({companyName: val.name})
                        setCompany(val)
                        setVisible(false)
                    }}/>}
            </Modal>
        </>
    );
}

export default AllotForm