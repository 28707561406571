import common from "../../utils/common";

/**
 * 车辆VIN变更记录
 */
class VehicleChange {
    /**
     * 变更类型 1.车架号变更 2.备用车架号变更
     */
    static TYPE_VIN = 1;
    static TYPE_SPARE_FRAME_NUMBER = 2;

    // 车辆用途
    static PURPOSE_PRIVATE = "1"
    static PURPOSE_PUBLIC = "2"

    static PurposeAlias = {
        [this.PURPOSE_PRIVATE]: "私车",
        [this.PURPOSE_PUBLIC]: "公车",
    }

    // 车辆类型
    static KIND_AUTOMATIC_CATCH = "automatic_catch";
    static KIND_MANUAL_GEAR = "manual_gear";
    static KIND_TIPTRONIC = "tiptronic";

    static KindsAlias = {
        [this.KIND_AUTOMATIC_CATCH]: "自动挡",
        [this.KIND_MANUAL_GEAR]: "手动挡",
        [this.KIND_TIPTRONIC]: "手自一体",
    }

    // 状态：1.启用 2.禁用
    static STATUS_ENABLE = 1
    static STATUS_DISABLE = 2

    static StatusAlias = {
        [this.STATUS_DISABLE]: '禁用',
        [this.STATUS_ENABLE]: '启用',
    }

    // 车辆来源 1.新车客户 2.维修客户 3.租赁客户 4.增值业务客户 5.二手车客户
    static CUSTOMER_TYPE_SALE = 1;
    static CUSTOMER_TYPE_SUPPORT = 2;
    static CUSTOMER_TYPE_RENT = 3;
    static CUSTOMER_TYPE_VAS = 4;
    static CUSTOMER_TYPE_ASSISTANT = 5;

    static CustomerTypeAlias = {
        [this.CUSTOMER_TYPE_SALE]: '新车客户',
        [this.CUSTOMER_TYPE_SUPPORT]: '维修客户',
        [this.CUSTOMER_TYPE_RENT]: '租赁客户',
        [this.CUSTOMER_TYPE_VAS]: '增值业务客户',
        [this.CUSTOMER_TYPE_ASSISTANT]: '二手车客户',
    }
}

class Vehicle {
    // 是否包修 1.包修 2.不保修
    static IS_WARRANTY_YES = "1";
    static IS_WARRANTY_NO = "2";

    static isWarrantyAlias = {
        [this.IS_WARRANTY_YES]: '是',
        [this.IS_WARRANTY_NO]: '否',
    }
}

class Project {
    static TYPE_PROJECT = "project";
    static TYPE_OUTSOURCE = "outsource";
}

class Repair {
    //  1.工单编辑   2.等待派工  3.维修中（配件出库，取消出库）4.已竣工（取消竣工）
    //  5.订单预结（取消预结） 6.订单结算（取消结算）7.订单收款 8.已退单
    static REPAIR_STATUS_CREATING = 1;
    static REPAIR_STATUS_WAITING = 2;
    static REPAIR_STATUS_SUPPORTING = 3;
    static REPAIR_STATUS_SUPPORTED = 4;
    static REPAIR_STATUS_PREPAID = 5;
    static REPAIR_STATUS_SETTLED = 6;
    static REPAIR_STATUS_RECEIVED = 7;
    static REPAIR_STATUS_BACK = 8;
}

class SupportSetting {
    // 维修工单自动弹出维修提醒 默认否
    static TAXONOMY_REPAIR_AUTOMATIC_REMINDER = "repairAutomaticReminder";
    // 同一台车(车牌号)未结算允许再开工单
    static TAXONOMY_CREATE_REPAIR_AT_SETTLEMENT = "createRepairAtSettlement";
    // 维修工单预结算发票抬头,打印时的可选下拉框(多选外部单位)
    static PREPAY_PRINT_INVOICE_TITLE = "prepayPrintInvoiceTitle";
    // 维修工单 默认工单类型
    static TAXONOMY_REPAIR_CATEGORY_ID = "repairCategoryId";
    // 维修工单 预计交车时间
    static TAXONOMY_REPAIR_MAKE_AT = "repairMakeAt";
    // 加装订单，客户性质 有多个
    static ACCESSORY_ORDER_CUSTOMER_TYPE = "accessoryOrderCustomerType";
    // 车辆档案，车辆来源 有多个
    static VEHICLE_SOURCE_TYPE = "vehicleSourceType";
    // 工单[自费维修]类材料毛利小于设定值
    static PRODUCT_PREPAY_SELF_THRESHOLD = "repairProductSelfLessThanTheSetValue";
    // 工单[保险理赔]类材料毛利小于设定值
    static PRODUCT_PREPAY_INSURANCE_THRESHOLD = "repairProductInsuranceClaimsLessThanTheSetValue";

    static PRODUCT_PREPAY_MARGIN_AUDIT_PROCESSES = 'marginAuditProcesses';

    // 0.否  1.是
    static NO = "0";
    static YES = "1";

    // 状态 1有效 2无效
    static STATUS_YES = 1;
    static STATUS_NO = 2;

    static statusAlias = {
        [this.STATUS_YES]: '启用',
        [this.STATUS_NO]: '禁用',
    }
}

// 维修提醒
class RepairReminders {
    // 手动新增(add) 增值业务备注(avocation_order_spec)
    static TYPE_ADD = "add";
    static TYPE_AVOCATION_ORDER = "avocation_order_spec";

}

// 图片
class SupportImages {
    // 订单
    static TYPE_ORDER = "order";
    static TYPE_LOOP_CHECK = "loop_check";// 环检
    static TYPE_SECOND_INSPECTION = "second_inspection";// 二检
    static TYPE_DAMAGE_PHOTOGRAPHY = "damage_photography";// 损伤拍照

    // 其他
    static TYPE_PRINT_LOGO = "print_logo";// 打印的时候的logo

    // app 质保 轮播图(app_accessory_slideshow) 背景图片(app_accessory_background_image) 日历背景图片(app_calendar_background_image)
    static TYPE_APP_ACCESSORY_SLIDESHOW = "app_accessory_slideshow";
    static TYPE_APP_ACCESSORY_BACKGROUND_IMAGE = "app_accessory_background_image";
    static TYPE_APP_CALENDAR_BACKGROUND_IMAGE = "app_calendar_background_image";
}

class Plan {
    static IS_APP_VISIBLE_YES = 1;
    static IS_APP_VISIBLE_NO = 2;

    static isAppVisibleAlias = {
        [this.IS_APP_VISIBLE_YES]: '启用',
        [this.IS_APP_VISIBLE_NO]: '禁用',
    }
}

export {
    Plan,
    Repair,
    SupportImages,
    SupportSetting,
    Project,
    Vehicle,
    RepairReminders,
    VehicleChange
}