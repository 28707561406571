import React from 'react';
import {Tabs} from 'antd';
import FakeForm1 from '../components/fakeForm1';
import FakeForm2 from '../components/fakeForm2';
import FakeForm3 from '../components/fakeForm3';

function FakeRepairEdit(props) {
    //  工单类型

    return (
        <React.Fragment>
            {/* 编辑的时候 */}
            {
                props.repairData && props.repairData.data && <>
                    <br />
                    {
                        (props.repairData.data.fakeType == undefined || props.repairData.data.fakeType == 'fakeType1') && <FakeForm1 {...props} />
                    }
                    {
                        props.repairData.data.fakeType == 'fakeType2' && <FakeForm2 {...props} />
                    }
                    {
                        props.repairData.data.fakeType == 'fakeType3' && <FakeForm3 {...props} />
                    }
                </>
            }
            {/* 新增的时候 */}
            {
                props.repairId && <>
                    <Tabs defaultActiveKey="1" onChange={e => {
                        console.log(e)
                    }} >
                        <Tabs.TabPane tab="估价单1" key="1">
                            <FakeForm1 {...props} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="估价单2" key="2">
                            <FakeForm2 {...props} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="估价单3" key="3">
                            <FakeForm3 {...props} />
                        </Tabs.TabPane>
                    </Tabs>
                </>
            }
        </React.Fragment>
    )
}

export default FakeRepairEdit