import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Col,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Table,
    Form,
    Tooltip,
    Select,
    DatePicker,
    Divider
} from "antd";
import GoodsReceiptItem from "../../../../../components/wms/LookupGoodsReceiptItem"
import common from "../../../../../utils/common"
import {
    CloseOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    SelectOutlined,
    EyeOutlined, CheckSquareOutlined
} from "@ant-design/icons";
import LookupEmployee from "../../../../../components/passport/LookupEmployee";
import EmptyForm from "../../../components/empty/form";
import PageTop from "../../../../../components/layout/PageTop";
import {
    Basic,
    Delivery,
    Product,
    ProductCategoryRate,
    ProductGeneralRate,
    Receipt, Term, wmsPublic
} from "../../../../../components/wms/config";
import BigNumber from "bignumber.js";
import moment from "moment";
import UnshippedTable from "./unshippedTable";
import RepairDetail from "../../../../../components/support/RepairDetail";
import {SupportImages} from "../../../../../components/support/config";

const {TextArea} = Input

function OrderDelivery(props) {
    let {
        defaultValue, onOk = () => {
        }
    } = props

    let [visible, setVisible] = useState(false) // 模态框可见性
    let [visible2, setVisible2] = useState(false) // 模态框可见性
    let [list, setList] = useState([]) // 表格数据
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [employee, setEmployee] = useState({});//领料人
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [itCannotEdit, setItCannotEdit] = useState(false);// 是否已经点击了新增按钮
    let [createReturnData, setCreateReturnData] = useState({});//新增成功返回的数据
    let [modalValue, setModalValue] = useState([]);
    let [groups, setGroups] = useState([]) // 班组
    let [quantity, setQuantity] = useState(0);
    let [total, setTotal] = useState(0);
    let [canChangeTax, setCanChangeTax] = useState(false);
    let [setting, setSetting] = useState({})
    let [isRepairPredictProduct, setIsRepairPredictProduct] = useState(false)
    let [settingForUpdateDeliveryPrice, setSettingForUpdateDeliveryPrice] = useState({})
    let [imageUrl, setImageUrl] = useState(null);

    // 获取班组
    let getGroups = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findUserTermsByTaxonomy', {
            taxonomy: "group",
            userIds: [common.getUser().company.id]
        }).then((data) => {
            data = data.filter(item => item.status !== 2)
            setGroups(data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getGroups, [])

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    let create = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/productGoods/delivery/create", params).then((data) => {
            if (data.id === "stockOut") {
                if (data.stockOutProduct.length > 0) {
                    common.confirm("库存不够 需要虚入库吗?", () => {
                        // 组装订单ID
                        data.stockOutProduct[0].orderId = params.orderId
                        setModalValue(data.stockOutProduct)
                        setModalNumber(5)
                        setVisible2(true)
                    })
                } else {
                    common.alert("此配件/精品还有其它批次进货的库存余额，请选择另外一批出库或修改出库数量")
                }
            } else {
                setCreateReturnData(data)
                setItCannotEdit(true)
                common.toast("配件出库成功")
                onOk()
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表数据
    let getData = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/listForDelivery", {
            kind: Product.KIND_PART,
            ownerId: common.getUser().company.id,
            number: params,
            isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
            isHaveGoodsInStock: Basic.IS_ANSWER_YES,//要有库存
            chargeType: ProductGeneralRate.CHARGE_TYPE_SALE,
            orderType: ProductCategoryRate.ORDER_TYPE_REPAIR,
            types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER, Receipt.RECEIPT_TYPE_ALLOT, Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_STOCK, Receipt.RECEIPT_TYPE_ADJUST_SKU],
            brandId: common.getUser().brand.id,
        }).then((data) => {
            if (data.receiptItems.length === 1) {
                let arr = list.filter(item => item.id === data.receiptItems[0].id)
                if (arr.length > 0) {
                    productGoodsOnOk(list)
                } else {
                    productGoodsOnOk([...list, ...data.receiptItems])
                }
                setTimeout(() => {
                    emptyInput.current.focus()
                }, 100)

                // 有多条数据 就把多条数据显示在模态框里
            } else {
                setModalNumber(1)
                setModalTitle('配件选择')
                setVisible(true)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        // 左下角 出库数量 出库金额
        let number = '0';
        let price = '0';
        list.forEach((item) => {
            if (item.quantity !== null && item.quantity !== '') {
                number = new BigNumber(item.quantity).plus(new BigNumber(number)).toFixed(2)
            }

            if (item.quantity !== null && item.quantity !== '' && item.price !== null && item.price !== '') {
                price = new BigNumber(item.quantity).multipliedBy(new BigNumber(item.price)).plus(new BigNumber(price)).toFixed(2)
            }
        })
        setQuantity(number)
        setTotal(price)
    }, [list])

    const columns = [
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        style={{whiteSpace: 'pre-wrap'}}
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={"输入编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setModalNumber(1)
                                    setModalTitle('配件选择')
                                    setVisible(true)
                                }} />
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined || text === null) {
                    return ''
                }

                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined /></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '可出库数量',
            dataIndex: 'salableQuantity',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        disabled={itCannotEdit}
                        defaultValue={text}
                        onChange={(val) => {
                            setList(list.map(item => {
                                if (item.id === record.id) {
                                    return {
                                        ...item,
                                        quantity: val ? new BigNumber(val).toFixed(2) : ''
                                    }
                                }

                                return item
                            }))
                        }}
                    />)
            }
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true,
            align: 'center',
        },
        {
            title: '进价',
            width: 120,
            dataIndex: 'priceCost',
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '计划售价',
            width: 120,
            dataIndex: 'priceConst',
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                return <div style={{color: "blue"}}>{common.numberFormat(text)}</div>
            }
        },
        {
            title: <Tooltip title={"默认为配件档案管理中的计划售价"}>出库价 <QuestionCircleOutlined /></Tooltip>,
            dataIndex: 'price',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord || text === undefined || text === null) {
                    return ''
                }
                return (<InputNumber
                    onPressEnter={wmsPublic.onPressEnter}
                    disabled={(Object.keys(settingForUpdateDeliveryPrice).length > 0 && settingForUpdateDeliveryPrice.flag === Term.SETTING_NO) || itCannotEdit}
                    min={0.01}
                    precision={2}
                    value={text}
                    onChange={(val) => {
                        setList(list.map((item, index2) => {
                            if (index === index2) {
                                // 计算出库价
                                let taxPercent = "0"
                                if (val !== null && val !== '' && parseFloat(item.priceCost) !== 0) {
                                    taxPercent = new BigNumber(val)
                                        .dividedBy(item.priceCost)
                                        .multipliedBy(100)
                                        .toFixed(0)
                                }

                                return {
                                    ...item,
                                    taxPercent: taxPercent,
                                    price: val ? new BigNumber(val).toFixed(2) : '',
                                }
                            }
                            return item
                        }))
                    }}
                />)
            }
        },
        {
            title: '出库加价率',
            width: 200,
            align: 'center',
            dataIndex: 'taxPercent',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord || text === undefined || text === null) {
                    return ''
                }
                if (!canChangeTax) {
                    return text.indexOf("%") < 0 ? (text + "%") : text
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <InputNumber
                            formatter={value => `${value}%`}
                            precision={2}
                            min={0.01}
                            parser={value => value.replace('%', '')}
                            style={{width: '60%'}}
                            value={text}
                            onChange={(value) => {
                                // 如果加价率相等 出库价也没有改变 出库价就不重新计算
                                if (value === text && record.price === record.priceConst) {
                                    return false;
                                }

                                setList(list.map((item, index2) => {
                                    if (index === index2) {
                                        // 计算出库价
                                        let price = '0.00'
                                        if (value !== null && value !== '') {
                                            price = new BigNumber(item.priceCost)
                                                .multipliedBy(new BigNumber(value))
                                                .dividedBy(new BigNumber(100))
                                                .toFixed(2)
                                        }

                                        return {
                                            ...item,
                                            taxPercent: value,
                                            price: price,
                                        }
                                    }
                                    return item
                                }))
                            }} />
                        <Button icon={<CheckSquareOutlined />}
                            hidden={!(index === 0 && record.taxPercent !== null && record.taxPercent !== "")}
                            onClick={() => {
                                setList(list.map((item) => {
                                    // 计算 出库价
                                    let price = '0.00'
                                    if (text !== null && text !== '') {
                                        price = new BigNumber(item.priceCost)
                                            .multipliedBy(new BigNumber(text))
                                            .dividedBy(new BigNumber(100))
                                            .toFixed(2)
                                    }

                                    return {
                                        ...item,
                                        taxPercent: text,
                                        price: price,
                                    }
                                }))
                            }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '出库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    disabled={itCannotEdit}
                    onChange={(e) => {
                        list[index].spec = e.target.value
                    }} />)
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => {
                                    let arr = list.filter((item) => {
                                        return item.id !== record.id
                                    })
                                    setList(arr)
                                }}
                                okText="确定"
                                cancelText="取消">
                                <Button type={'link'} disabled={itCannotEdit}>删除</Button>
                            </Popconfirm>}
                    </div>)
            }
        }
    ]
    const columnsUnshipped = [
        {
            title: '编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
        },
        {
            title: '名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '预估数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '出库数量',
            dataIndex: 'deliveryPrice',
            width: 100,
            ellipsis: true,
            align: "right",
        },
        {
            title: '差异',
            dataIndex: 'spec',
            width: 100,
            ellipsis: true,
            align: "right",
        },
    ]
    const columnsRepair = [
        {
            title: '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 200,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return text
                }

                return (
                    <Input
                        style={{whiteSpace: 'pre-wrap'}}
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={"输入编号 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setModalNumber(1)
                                    setModalTitle('配件选择')
                                    setVisible(true)
                                }} />
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined || text === null) {
                    return ''
                }

                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined /></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '可出库数量',
            dataIndex: 'salableQuantity',
            width: 100,
            ellipsis: true,
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '计划出库数量',
            dataIndex: 'repairQuantityPlan',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ""
                }
                if (text == undefined) {
                    return ''
                }
                return common.numberFormat(text)
            },
        },
        {
            title: '已出库数量',
            dataIndex: 'repairQuantityDelivery',
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                if (text === undefined) {
                    return ""
                }
                if (record.isEmptyRecord) {
                    return ""
                }
                return common.numberFormat(text)
            },
        },
        {
            title: '出库数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        onPressEnter={wmsPublic.onPressEnter}
                        precision={2}
                        min={0.01}
                        disabled={itCannotEdit}
                        defaultValue={text}
                        onChange={(val) => {
                            setList(list.map(item => {
                                if (item.id === record.id) {
                                    return {
                                        ...item,
                                        quantity: val ? new BigNumber(val).toFixed(2) : ''
                                    }
                                }

                                return item
                            }))
                        }}
                    />)
            }
        },
        {
            title: '单位',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true,
            align: 'center',
        },
        {
            title: '进价',
            width: 120,
            dataIndex: 'priceCost',
            ellipsis: true,
            align: 'right',
            render: (text, record) => record.isEmptyRecord ? '' : common.numberFormat(text),
        },
        {
            title: '计划售价',
            width: 120,
            dataIndex: 'priceConst',
            ellipsis: true,
            align: 'right',
            render: (text) => {
                if (text === undefined) {
                    return ''
                }
                return <div style={{color: "blue"}}>{common.numberFormat(text)}</div>
            }
        },
        {
            title: <Tooltip title={"默认为配件档案管理中的计划售价"}>出库价 <QuestionCircleOutlined /></Tooltip>,
            dataIndex: 'price',
            width: 150,
            align: 'center',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (<InputNumber
                    onPressEnter={wmsPublic.onPressEnter}
                    disabled={(Object.keys(settingForUpdateDeliveryPrice).length > 0 && settingForUpdateDeliveryPrice.flag === Term.SETTING_NO) || itCannotEdit}
                    min={0.01}
                    precision={2}
                    value={text}
                    onChange={(val) => {
                        setList(list.map((item, index2) => {
                            if (index === index2) {
                                // 计算出库价
                                let taxPercent = "0"
                                if (val !== null && val !== '') {
                                    taxPercent = new BigNumber(val)
                                        .dividedBy(item.priceCost)
                                        .multipliedBy(100)
                                        .toFixed(0)
                                }

                                return {
                                    ...item,
                                    taxPercent: taxPercent,
                                    price: val ? new BigNumber(val).toFixed(2) : '',
                                }
                            }
                            return item
                        }))
                    }}
                />)
            }
        },
        {
            title: '出库加价率',
            width: 200,
            align: 'center',
            dataIndex: 'taxPercent',
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord || text === null) {
                    return ''
                }
                if (!canChangeTax) {
                    return text.indexOf("%") < 0 ? (text + "%") : text
                }
                return (
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <InputNumber
                            formatter={value => `${value}%`}
                            precision={2}
                            min={0.01}
                            parser={value => value.replace('%', '')}
                            style={{width: '60%'}}
                            value={text}
                            onChange={(value) => {
                                // 如果加价率相等 出库价也没有改变 出库价就不重新计算
                                if (value === text && record.price === record.priceConst) {
                                    return false;
                                }

                                setList(list.map((item, index2) => {
                                    if (index === index2) {
                                        // 计算出库价
                                        let price = '0.00'
                                        if (value !== null && value !== '') {
                                            price = new BigNumber(item.priceCost)
                                                .multipliedBy(new BigNumber(value))
                                                .dividedBy(new BigNumber(100))
                                                .toFixed(2)
                                        }

                                        return {
                                            ...item,
                                            taxPercent: value,
                                            price: price,
                                        }
                                    }
                                    return item
                                }))
                            }} />
                        <Button icon={<CheckSquareOutlined />}
                            hidden={!(index === 0 && record.taxPercent !== null && record.taxPercent !== "")}
                            onClick={() => {
                                setList(list.map((item) => {
                                    // 计算 出库价
                                    let price = '0.00'
                                    if (text !== null && text !== '') {
                                        price = new BigNumber(item.priceCost)
                                            .multipliedBy(new BigNumber(text))
                                            .dividedBy(new BigNumber(100))
                                            .toFixed(2)
                                    }

                                    return {
                                        ...item,
                                        taxPercent: text,
                                        price: price,
                                    }
                                }))
                            }}>批量
                        </Button>
                    </div>
                )
            }
        },
        {
            title: '供货商',
            width: 200,
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 150,
            ellipsis: true
        },
        {
            title: '库别名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '出库备注',
            dataIndex: 'spec',
            width: 200,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (<Input.TextArea
                    autoSize={{minRows: 1, maxRows: 2}}
                    disabled={itCannotEdit}
                    onChange={(e) => {
                        list[index].spec = e.target.value
                    }} />)
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => {
                                    let arr = list.filter((item) => {
                                        return item.id !== record.id
                                    })
                                    setList(arr)
                                }}
                                okText="确定"
                                cancelText="取消">
                                <Button type={'link'} disabled={itCannotEdit}>删除</Button>
                            </Popconfirm>}
                    </div>)
            }
        }
    ]

    // 打印
    let print = () => {
        common.consoleLog(createReturnData.deliveryItems)
        let total = 0;
        // 入库单价*出库数量
        let receiptTotal = '0.00';
        // 组装数据
        createReturnData.deliveryItems.forEach((item2) => {
            item2.total = new BigNumber(item2.quantity)
                .multipliedBy(new BigNumber(item2.price))
                .toFixed(2)

            // 打印需要
            item2.receiptItemDto = {
                price: item2.costPrice
            }

            // 成本金额
            item2.receiptTotal = common.numberCut(new BigNumber(item2.quantity).multipliedBy(item2.receiptItemDto.price).toFixed(2), 2)

            total = new BigNumber(item2.total).plus(new BigNumber(total)).toFixed(2)

            // 入库总价
            receiptTotal = new BigNumber(item2.receiptTotal).plus(receiptTotal).toFixed(2)

            list.forEach((item1) => {
                if (item1.skuId === item2.skuId) {
                    item2.productDto = {
                        name: item1.productDto.name,
                        number: item1.productDto.number,
                        unit: item1.productDto.unit,
                    }
                    item2.warehouseDto = {name: item1.warehouseName}
                }
            })
        })

        // 组装仓位数据
        createReturnData.deliveryItems.forEach(item => {
            list.forEach(product => {
                if (item.productId === product.productId) {
                    item.productDto.positionName = product.positionName
                    item.productDto.applicableModels = product.applicableModels || (product.productDto && product.productDto.applicableModels) || ''
                }
            })
        })

        // 组装数据
        let data = {
            "code": "wms_delivery_position",
            "owner_id": common.getUser().company.id,
            "data": {
                "logo": imageUrl || null,
                "title": "维修出库单",
                "companyName": common.getUser().company.name,
                "order": {
                    "customerName": defaultValue.customerName,
                    "code": defaultValue.code,
                    "plate": defaultValue.plate,
                    "orderType": defaultValue.repairCategoryName //打印增加单据类型
                },
                "code": createReturnData.code,
                "total": common.numberCut(total, 2),
                "receiptTotal": common.numberCut(receiptTotal, 2),
                "createdAt": createReturnData.deliveryItems[0].deliveryDate.substring(0, 10),
                "spec": createReturnData.spec,
                "provider": createReturnData.employeeName,
                "receiver": employee.name,
                "salesman": common.getUser().nickname,
                "deliveryItems": createReturnData.deliveryItems,
                "totalUpper": common.moneyToChinese(total),// 大写,
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 虚入库
    let createReceiptEmpty = (params) => {
        common.loadingStart();
        common.ajax("post", "/wms/productGoods/receipt/empty/create", params).then((data) => {
            setVisible2(false)
            common.toast("虚入库创建成功")
            data.forEach((item1) => {
                list.forEach((item2) => {
                    // 计算加价率
                    if (item1.skuId === item2.skuId) {
                        // 加价率 = 计划售价/原价
                        let tax = new BigNumber(item2.priceConst).dividedBy(new BigNumber(item2.priceCost)).toFixed(2)

                        // 计划售价
                        item1.priceConst = new BigNumber(item1.price).multipliedBy(new BigNumber(tax)).toFixed(2)
                    }

                    // 进价
                    item1.priceCost = item1.price

                    item1.spec = ''
                    item1.quantity = item1.salableQuantity
                })
            })

            let arr = [
                ...list,
                ...data
            ]
            setList(arr)

            setModalValue([])
        }).finally(() => {
            common.loadingStop()
        })
    }

    const [form] = Form.useForm()

    // 精品/配件 选择
    let productGoodsOnOk = (val) => {
        let arr = wmsPublic.productGoodsOnOk(val, list)

        arr.forEach((item) => {
            // 除数不允许为0
            if (parseFloat(item.priceCost) !== 0) {
                item.taxPercent = new BigNumber(item.price)
                    .dividedBy(item.priceCost)
                    .multipliedBy(100)
                    .toFixed(0)
            }
        })
        setList(arr)
        setVisible(false)
        setNewValue('')
    }

    let onFinish = (values) => {
        create({
            ...values,
            deliveryItem: list.map((item) => {
                return {
                    productId: item.productId,
                    receiptItemId: item.id,
                    quantity: item.quantity,
                    price: item.price,
                    spec: item.spec,
                }
            }),
            grantItIs: Delivery.DELIVERY_ITEM_STATUS_SHIPPED,
            ownerId: defaultValue.ownerId,
            customerId: defaultValue.customerId,
            type: Delivery.DELIVERY_TYPE_REPAIR,
            orderId: defaultValue.id,
            employeeId: employee.id,
            kind: Product.KIND_PART,
            deliveryDate: moment(values.deliveryDate).format('YYYY-MM-DD'),
        })
    }

    // 获取当前时间
    let getDate = () => {
        common.loadingStart()
        common.ajax('get', '/wms/receipt/now')
            .then(res => {
                form.setFieldsValue({deliveryDate: moment(moment(res).format("YYYY-MM-DD"), "YYYY/MM/DD")})
            })
            .finally(common.loadingStop)
    }

    // 获取工单预估材料
    let getPredictProductList = () => {
        common.loadingStart()
        new Promise(resolve => {
            common.ajax('get', "/wms/deliveryItem/getPredictProductList", {
                ownerId: common.getUser().company.id,
                repairId: defaultValue.id,
            }).then(res => {
                // common.consoleLog('getPredictProductList', res)
                resolve(res)
            }).catch(common.loadingStop)
        }).then(predictProductList => {
            if (predictProductList.length === 0) {
                return
            }
            let params = {
                kind: Product.KIND_PART,
                chargeType: ProductGeneralRate.CHARGE_TYPE_SALE,
                orderType: ProductCategoryRate.ORDER_TYPE_REPAIR,
                ownerId: common.getUser().company.id,
                types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER, Receipt.RECEIPT_TYPE_ALLOT, Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_STOCK, Receipt.RECEIPT_TYPE_ADJUST_SKU],
                isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
                isHaveGoodsInStock: Basic.IS_ANSWER_YES,
                brandId: common.getUser().brand.id,
                productId: predictProductList.map(item => item.productId).join(","),
                page: 1,
                limit: 500
            }
            common.ajax('get', '/wms/receiptItem/listForDelivery', params).then(data => {
                if (data.receiptItems.length > 0) {
                    let arr = []
                    setIsRepairPredictProduct(true)
                    for (let predictProduct of predictProductList) {// predictProductList需要放外层 不然会导致重复
                        for (let receiptItem of data.receiptItems) {
                            if (receiptItem.productId === predictProduct.productId) {
                                // 维修工单 预估材料
                                receiptItem.isRepairPredictProduct = true
                                // 计划出库数量
                                receiptItem.repairQuantityPlan = predictProduct.quantity
                                // 已出库数量
                                receiptItem.repairQuantityDelivery = predictProduct.quantityGrant
                                // 计划出库价
                                receiptItem.price = predictProduct.price
                                // 可以出库数量
                                receiptItem.quantity = new BigNumber(predictProduct.quantity).minus(predictProduct.quantityGrant).toString()
                                if (parseFloat(receiptItem.quantity) <= 0) {
                                    // 数量为0 就不带出了
                                    receiptItem.quantity = "0.00"
                                } else {
                                    arr.push(receiptItem)
                                }
                                break;
                            }
                        }
                    }
                    if (arr.length > 0) {
                        productGoodsOnOk(arr)
                    }
                }
            }).finally(common.loadingStop)
        }).finally(common.loadingStop)
    }

    // 获取配置数据
    let getSettingData = (taxonomy) => {
        let params = {
            taxonomy: taxonomy,
            ownerId: common.getUser().company.id
        }
        common.loadingStart()
        common.ajax('get', '/wms/setting', params)
            .then(res => {
                if (res !== null) {
                    if (taxonomy === Term.GOODS_DELIVERY_REPAIR_SELECT_DATE) {
                        setSetting(res)
                    }
                    // 工单配件出库时，是否允许修改出库价
                    if (taxonomy === Term.GOODS_SUPPORT_DELIVERY_UPDATE_DELIVERY_PRICE) {
                        setSettingForUpdateDeliveryPrice(res)
                    }

                    // 工单配件出库时是否允许录入加价率
                    if (taxonomy === Term.REPAIR_DELIVERY_CHANGE_TAX) {
                        if (res.flag === Term.SETTING_YES) {
                            setCanChangeTax(true)
                        } else {
                            setCanChangeTax(false)
                        }
                    }
                }
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        getDate()

        // 工单出库时，允许选择出库日期
        getSettingData(Term.GOODS_DELIVERY_REPAIR_SELECT_DATE)

        // 工单配件出库时，是否允许修改出库价
        getSettingData(Term.GOODS_SUPPORT_DELIVERY_UPDATE_DELIVERY_PRICE)

        // 工单配件出库时是否允许录入加价率
        getSettingData(Term.REPAIR_DELIVERY_CHANGE_TAX)
    }, [])

    return (
        <>
            <br />
            <PageTop title={"配件出库"}>
                <Button
                    icon={<SaveOutlined />}
                    disabled={itCannotEdit}
                    type={"primary"}
                    onClick={() => {
                        if (Object.keys(employee).length === 0) {
                            common.alert("请选择领料人")
                            return false
                        }
                        if (list.length === 0) {
                            common.alert("请选择配件")
                            return false
                        }
                        for (let item of list) {
                            if (item.quantity === null) {
                                common.alert("请填写出库数量")
                                return false
                            }
                            if (item.price === null) {
                                common.alert("请填写出库价格")
                                return false
                            }
                        }
                        form.submit()
                    }}>保存</Button>
                <Button icon={<PrinterOutlined />} disabled={!itCannotEdit} onClick={print}>打印</Button>
                <Button
                    icon={<EyeOutlined />}
                    onClick={() => {
                        setModalTitle("维修工单详情")
                        setModalNumber(3)
                        setVisible(true)
                    }}>工单详情</Button>
                <Button
                    icon={<EyeOutlined />}
                    disabled={itCannotEdit}
                    onClick={() => {
                        getPredictProductList()
                    }}>工单预估材料</Button>
                <Button
                    icon={<EyeOutlined />}
                    // disabled={itCannotEdit}
                    onClick={() => {
                        setModalNumber(4)
                    }}>待出库材料</Button>
            </PageTop>
            <Form
                name={'form-data-children'}
                onFinish={onFinish}
                initialValues={{
                    spec: "",
                    groupId: "",
                    ...defaultValue,
                    employeeName: "",
                }}
                form={form}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'code'}
                            label="订单编号"
                            className={'label-character-4'}>
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name={'customerName'}
                            className={'label-character-4'}
                            label="客户名称">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'plate'}
                            label="车牌号">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {!itCannotEdit &&
                        <Col span={8}>
                            <Form.Item
                                className={'label-character-4'}
                                name={'employeeName'}
                                rules={[{required: true, message: '领料人不允许为空'}]}
                                label="领料人">
                                <Input
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        employee.id === undefined ? <span /> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setEmployee({})
                                                    form.setFieldsValue({
                                                        employeeName: "",
                                                        groupId: ""
                                                    })
                                                }} />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(2)
                                                setModalTitle('选择领料人')
                                                setVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(2)
                                        setModalTitle('选择领料人')
                                        setVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>}
                    {itCannotEdit &&
                        <Col span={8}>
                            <Form.Item
                                className={'label-character-4'}
                                name={'employeeName'}
                                rules={[{required: true, message: '领料人不允许为空'}]}
                                label="领料人">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                    }
                    <Col span={8}>
                        <Form.Item
                            label={'班组'}
                            className={'label-character-4'}
                            name={'groupId'}
                            rules={[{required: true, message: '请选择班组'}]}
                        >
                            <Select disabled={itCannotEdit}>
                                {groups.map(group => (
                                    <Select.Option key={group.id} value={group.id}>{group.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'repairCategoryName'}
                            label="工单类型">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            rules={[{required: true, message: '出库时间不允许为空'}]}
                            name={'deliveryDate'}
                            label="出库时间">
                            <DatePicker
                                style={{width: '100%'}}
                                disabled={!(Object.keys(setting).length > 0 && setting.flag === Term.SETTING_YES)} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'vin'}
                            label="VIN号">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'engineNum'}
                            label="发动机号">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name={'spec'}
                            className={'label-character-4'}
                            label="出库备注">
                            <TextArea
                                disabled={itCannotEdit}
                                autoSize={{minRows: 1, maxRows: 2}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'seriesName'}
                            label="车系">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'productName'}
                            label="车型">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                pagination={false}
                rowKey='id'
                columns={isRepairPredictProduct ? columnsRepair : columns}
                dataSource={itCannotEdit ? list : [...list, {
                    isEmptyRecord: true,
                    id: -1
                }]}
                scroll={{x: '100%', y: '400px'}} />
            <div style={{marginTop: '10px'}}>
                配件名称: {list.length}
                <Divider type="vertical" />
                出库数量: {common.numberFormat(quantity)}
                <Divider type="vertical" />
                出库金额: {common.numberFormat(total)}
            </div>

            {/*待出库 预估材料*/}
            {modalNumber === 4 &&
                <UnshippedTable
                    onOk={() => {
                        setModalNumber(0)
                    }}
                    repairId={defaultValue.id}
                />
            }

            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 3 &&
                    <RepairDetail code={defaultValue.code} />}
                {modalNumber === 1 &&
                    <GoodsReceiptItem
                        defaultValue={list}
                        productNumber={newValue}
                        action={Product.KIND_PART}
                        chargeType={ProductGeneralRate.CHARGE_TYPE_SALE}
                        orderType={ProductCategoryRate.ORDER_TYPE_REPAIR}
                        onOk={productGoodsOnOk} />}
                {modalNumber === 2 &&
                    <LookupEmployee
                        canChangeCompany={true}
                        companyId={common.getUser().company.id}
                        isMultiple={false}
                        onOk={(val) => {
                            setEmployee(val)
                            if (val.groupId !== "") {
                                let number = 0
                                groups.forEach((item) => {
                                    if (item.id === val.groupId) {
                                        number++
                                    }
                                })
                                if (number !== 0) {
                                    form.setFieldsValue({
                                        groupId: val.groupId,
                                        employeeName: val.name
                                    })
                                } else {
                                    form.setFieldsValue({
                                        employeeName: val.name
                                    })
                                }
                            } else {
                                form.setFieldsValue({
                                    employeeName: val.name
                                })
                            }
                            setVisible(false)
                        }} />}
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                onCancel={() => {
                    setVisible2(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                <EmptyForm
                    defaultValue={modalValue}
                    action={Product.KIND_PART}
                    onOk={createReceiptEmpty} />
            </Modal>
        </>
    );
}

export default OrderDelivery