import React from 'react';
import {Route, Switch, Link} from "react-router-dom";
import Book from "./book";
import Lookup from "./exampleLookup";
import Demo from "./Demo";
import Print from "./print";
import Excel from "./excel";
import Calc from "./calc";
import ChartDemo from "./chart";
import SocketIndex from "./socket/index";

function Index() {
    return (
        <div>

            <Switch>
                <Route path={'/example/book'} component={Book}/>
                <Route path={'/example/chartDemo'} component={ChartDemo}/>
                <Route path={'/example/lookup'} component={Lookup}/>
                <Route path={'/example/print'} component={Print}/>
                <Route path={'/example/demo'} component={Demo}/>
                <Route path={'/example/excel'} component={Excel}/>
                <Route path={'/example/calc'} component={Calc}/>
                <Route path={'/example/socket'} component={SocketIndex}/>

                <Route path="*">
                    <ul>
                        <li>
                            <Link to='/example/demo'>Demo</Link>
                        </li>
                        <li>
                            <Link to='/example/book'>图书管理案例</Link>
                        </li>
                        <li>
                            <Link to='/example/lookup'>弹窗组件</Link>
                        </li>
                        <li>
                            <Link to='/example/print'>打印</Link>
                        </li>
                        <li>
                            <Link to='/example/excel'>Excel 导入</Link>
                        </li>
                        <li>
                            <Link to='/example/calc'>高精度计算</Link>
                        </li>
                        <li>
                            <Link to='/example/chartDemo'>图表示例</Link>
                        </li>
                    </ul>

                    {/*<HookDemo product={{id: 1, name: "商品a"}}></HookDemo>*/}

                </Route>

            </Switch>
        </div>
    );
}

export default Index;