import React, {useEffect, useState} from "react";
import {Coupon, Ticket} from "../../../coupon/config";
import BigNumber from "bignumber.js";
import moment from "moment";
import {Button, Pagination, Table, Radio} from "antd";
import common from "../../../../utils/common";
import PageBottom from "../../../layout/PageBottom";

function TicketList(props) {

    let {customerId} = props

    let [dataSource, setDataSource] = useState([])
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let [total, setTotal] = useState(0)
    let [status, setStatus] = useState(Ticket.STATUS_UNLOCKED)

    const columns = [
        {
            title: '编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true,
        },
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 200,
            ellipsis: true,
        },
        {
            title: '卡券名称',
            dataIndex: ['couponDto', 'name'],
            width: 200,
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'customerName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'customerPhone',
            width: 150,
            ellipsis: true,
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: text => text in Ticket.StatusAlias ? Ticket.StatusAlias[text] : text
        },
        {
            title: '卡券类型',
            dataIndex: ["couponDto", 'type'],
            width: 100,
            ellipsis: true,
            render: text => Coupon.TypeAlias[text]
        },
        {
            title: '减免金额',
            dataIndex: ["couponDto", 'reduceCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '抵扣条件',
            dataIndex: ["couponDto", 'leastCost'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_CASH ? <span>&yen;{text}</span> : '-'
        },
        {
            title: '折扣比例',
            dataIndex: ["couponDto", 'discount'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_DISCOUNT ? text + '%' : '-'
        },
        {
            title: '抵扣项目',
            dataIndex: ["couponDto", 'projectId'],
            width: 100,
            ellipsis: true,
            render: (text, record) => record.couponDto.type === Coupon.TYPE_PROJECT ? text : '-'
        },
        {
            title: '最大抵扣金额',
            dataIndex: ["couponDto", 'reduceMax'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '预收金额',
            dataIndex: ["couponDto", 'price'],
            width: 100,
            ellipsis: true,
            render: text => <span>&yen;{text}</span>
        },
        {
            title: '财务类型',
            dataIndex: ["couponDto", 'category1'],
            width: 150,
            ellipsis: true,
            render: text => Coupon.Category1s[text]
        },
        {
            title: '业务类型',
            dataIndex: ["couponDto", 'category2'],
            width: 100,
            ellipsis: true,
            render: text => Coupon.Category2s[text]
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            render: text => text.substring(0, 16)
        },
    ]

    useEffect(() => {
        if (customerId) {
            let params = {
                ownerId: common.getUser().company.id,
                memberId: customerId,
                page: pagination.page,
                limit: pagination.limit,
                statuses: status || ''
            }

            common.loadingStart()
            common.ajax('get', '/coupon/ticket/list', params).then(res => {
                setDataSource(res.tickets)
                setTotal(res.pagination.total)
                // common.consoleLog(res)
            }).finally(common.loadingStop)
        }
    }, [customerId, pagination.page, pagination.limit, status])

    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }

    return (
        <>
            <Radio.Group style={{marginBottom: '10px'}} size="small" options={[
                {
                    label: Ticket.StatusAlias[Ticket.STATUS_UNLOCKED],
                    value: Ticket.STATUS_UNLOCKED,
                },
                {
                    label: Ticket.StatusAlias[Ticket.STATUS_USED],
                    value: Ticket.STATUS_USED,
                },
                {
                    label: Ticket.StatusAlias[Ticket.STATUS_APPLIED],
                    value: Ticket.STATUS_APPLIED,
                },
                {
                    label: '全部',
                    value: ''
                }
            ]} onChange={(e) => {
                setStatus(status => {
                    if (e.target.value !== status) {
                        setPagination({
                            ...pagination,
                            page: 1
                        })
                    }
                    return e.target.value
                })
            }} value={status} optionType="button" buttonStyle="solid" />
            <Table rowKey={record => record.id}
                scroll={{x: true}}  // 指定x方向滚动
                dataSource={dataSource}
                columns={columns}
                pagination={false}
            />
            <div>
                <PageBottom pagination={
                    <Pagination
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        defaultPageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={['10', '20', '40', '80']}
                        showQuickJumper={true}
                        showSizeChanger />
                } />
            </div>
        </>
    )
}

export default TicketList