import React, {useEffect, useRef, useState} from 'react';
import {CloseOutlined, SaveFilled, SelectOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, Modal, Row, Image, Table, Tabs, Tag, Tooltip, DatePicker, Upload} from 'antd';
import PageTop from '../../../components/layout/PageTop';
import moment from 'moment';
import Partner from '../../passport/LookupPartner';
import BigNumber from 'bignumber.js';
import common from '../../../utils/common';
import './style.css'
// .ant-upload-list-picture-card-container


function AssessmentForm(props) {
    let {scene} = props
    let [lookupPartnerVisible, setLookupPartnerVisible] = useState('')   // 选择保险公司弹框
    const [formValues, setFormValues] = useState({
        ownerId: common.getUser().company.id,
        repairId: props.repair && props.repair.id,
        insuranceCompanyName: '',//保险公司ID
        insuranceCompanyId: '',//保险公司ID
        adjusterName: '',//定损员名称
        projectCost: '',
        materialCost: '',
        otherCost: '',
        spec: '',
        imageUrlList: [],
        pdfUrlList: [],
    })

    const [imgList, setImgList] = useState([])
    const [fileList, setFileList] = useState([])
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const handlePreview = async (file) => {
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    useEffect(() => {
        if (props.repair) {
            setFormValues({
                ...formValues,
                insuranceCompanyName: props.repair.partnerName,
                insuranceCompanyId: props.repair.partnerId,
                code: props.repair.code,
                plate: props.repair.plate,
                seriesName: props.repair.seriesName,
                productName: props.repair.productName,
                employeeName: props.repair.employeeName,
            })
        }
    }, [props.repair])

    useEffect(() => {
        if (props.id) {
            common.loadingStart()
            common.ajax("get", `/support/insurance/claims/findById?id=${props.id}`)
                .then(res => {
                    setFormValues(formValues => {
                        return {
                            ...formValues,
                            ...res
                        }
                    })
                    setImgList(res.imageUrlList)
                    setFileList(res.pdfUrlList)
                })
                .finally(common.loadingStop)
        }
    }, [props.id])

    return (
        <React.Fragment>
            <PageTop title={'定损' + (scene == 'look' ? '详情' : '录入')}>
                {
                    scene != 'look' && <Button icon={<SaveFilled />} type="primary" onClick={() => {
                        let params = {
                            ...formValues,
                            imageUrlList: imgList,
                            pdfUrlList: fileList
                        }
                        if (props.id) {
                            params.id = props.id
                        }
                        common.loadingStart()
                        common.ajax("post", props.id ? '/support/insurance/claims/updateById' : "/support/insurance/claims/create", params)
                            .then(() => {
                                common.toast("保存成功")
                                if (props.onOk) {
                                    props.onOk(props.id)
                                }
                            })
                            .finally(common.loadingStop)
                    }}>保存</Button>
                }

            </PageTop>
            <Form className={'ant-advanced-inline-form'}   >
                {
                    formValues.code && <Row gutter={24}>
                        <Col span={6} >
                            <Form.Item className={"label-character-4"} label={"维修单号"} >
                                <Input autoComplete='off' value={formValues.code} readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"车牌号"} >
                                <Input autoComplete='off' value={formValues.plate} readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item className={"label-character-3"} label={"车系车型"} >
                                <Input autoComplete='off' value={formValues.seriesName + '/' + formValues.productName} readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={"服务顾问"} >
                                <Input autoComplete='off' readOnly value={formValues.employeeName} />
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <Row gutter={24}>
                    <Col span={6} >
                        <Form.Item className={"label-character-4"} label={"工时费"} >
                            <InputNumber readOnly={scene == 'look'} autoComplete='off' value={formValues.projectCost} onChange={e => {
                                setFormValues({...formValues, projectCost: e})
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"材料费"} >
                            <InputNumber readOnly={scene == 'look'} autoComplete='off' value={formValues.materialCost} onChange={e => {
                                setFormValues({...formValues, materialCost: e})
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item className={"label-character-3"} label={"其他费用"} >
                            <InputNumber readOnly={scene == 'look'} autoComplete='off' value={formValues.otherCost} onChange={e => {
                                setFormValues({...formValues, otherCost: e})
                            }} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label={"定损合计"} >
                            <Input autoComplete='off' readOnly value={new BigNumber(formValues.materialCost || '0').plus(formValues.projectCost || '0').plus(formValues.otherCost || '0').toFixed(2)} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item className={"label-character-4"} label={"上传照片"} >
                            <Upload
                                multiple={true}
                                accept="image/*"
                                listType="picture-card"
                                fileList={imgList.map((item, index) => item = {
                                    uid: index,
                                    name: `image-${index}.png`,
                                    status: 'done',
                                    url: item,
                                })}
                                disabled={scene == 'look'}
                                customRequest={async event => {
                                    const reader = new FileReader();
                                    reader.readAsDataURL(event.file);
                                    reader.onload = (e) => {
                                        common.ajax('post', '/wms/oss/uploadBase', {
                                            "image": e.target.result
                                        }).then(res => {
                                            setImgList(imgList => {
                                                return [...imgList, res]
                                            })
                                            event.onSuccess(res, event.file)
                                        })
                                    };
                                }}
                                onChange={event => {
                                    if (event.file.status === 'removed') {
                                        setImgList(event.fileList.map(item => item.url))
                                    }
                                }}
                                onPreview={handlePreview}
                            >
                                <Button style={{
                                    border: 0,
                                    background: 'none',
                                }} icon={<UploadOutlined />}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item className={"label-character-4"} label={"上传文件"} >
                            <Upload
                                accept='.pdf'
                                multiple
                                fileList={fileList.map((item, index) => item = {
                                    uid: index,
                                    name: `pdf-${index}.png`,
                                    status: 'done',
                                    url: item,
                                })}
                                disabled={scene == 'look'}
                                customRequest={async event => {
                                    const formData = new FormData();
                                    formData.append('files', event.file);
                                    common.ajax('post', '/wms/oss/upload/pdf', formData, {'contentType': 'multipart/form-data'}).then(res => {
                                        setFileList(fileList => {
                                            return [...fileList, ...res]
                                        })
                                        event.onSuccess(res, event.file)
                                    })
                                }}
                                onChange={event => {
                                    if (event.file.status === 'removed') {
                                        setFileList(event.fileList.map(item => item.url))
                                    }
                                }}
                            >
                                <Button icon={<UploadOutlined />}>点击上传</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item className={"label-character-4"} label={"保险公司1"} >
                            <Input autoComplete="off"
                                value={formValues.insuranceCompanyName}
                                readOnly
                                placeholder={'请选择保险公司'}
                                suffix={scene != 'look' && formValues.insuranceCompanyName ?
                                    <CloseOutlined onClick={() => {
                                        setFormValues({...formValues, insuranceCompanyName: "", insuranceCompanyId: "", adjusterName: ''})
                                    }} /> :
                                    <span />}
                                onPressEnter={() => {
                                    if (scene != 'look') {
                                        setLookupPartnerVisible('partner1')
                                    }
                                }}
                                addonAfter={<SelectOutlined onClick={() => {
                                    if (scene != 'look') {
                                        setLookupPartnerVisible('partner1')
                                    }
                                }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className={"label-character-3"} label={"定损员"} >
                            <Input readOnly={scene == 'look'} autoComplete='off' value={formValues.adjusterName} onChange={e => {
                                setFormValues({...formValues, adjusterName: e.target.value})
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item className={"label-character-4"} label={"保险公司2"} >
                            <Input autoComplete="off"
                                value={formValues.partnerName2}
                                readOnly
                                placeholder={'请选择保险公司'}
                                suffix={formValues.partnerName2 ?
                                    <CloseOutlined onClick={() => {
                                        setFormValues({...formValues, partnerName2: "", partnerId2: "", partnerUser2: ''})
                                    }} /> :
                                    <span />}
                                onPressEnter={() => {
                                    setLookupPartnerVisible('partner2')
                                }}
                                addonAfter={<SelectOutlined onClick={() => {
                                    setLookupPartnerVisible('partner2')
                                }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item className={"label-character-3"} label={"定损员"} >
                            <Input autoComplete='off' value={formValues.partnerUser2} onChange={e => {
                                setFormValues({...formValues, partnerUser2: e.target.value})
                            }} />
                        </Form.Item>
                    </Col>
                </Row> */}
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item className={"label-character-4"} label={"备注"} >
                            <Input readOnly={scene == 'look'} autoComplete='off' value={formValues.spec} onChange={e => {
                                setFormValues({...formValues, spec: e.target.value})
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {/*保险公司*/}
            <Modal title={'选择保险单位'}
                maskClosable={false}
                visible={lookupPartnerVisible !== ''}
                footer={null} width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setLookupPartnerVisible('')
                }}>
                <Partner type={"保险公司"} canChangeType={true} dataSource={3} onOk={value => {
                    setFormValues({...formValues, insuranceCompanyName: value.name, insuranceCompanyId: value.id})
                    setLookupPartnerVisible('')
                }} />
            </Modal>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </React.Fragment>
    )
}

export default AssessmentForm