import React, {useEffect, useRef, useState} from 'react';
import {useHistory} from "react-router-dom";
import {
    CheckOutlined,
    CheckSquareOutlined,
    CloseOutlined,
    CrownOutlined,
    EditOutlined, HistoryOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    RollbackOutlined,
    SelectOutlined,
    CopyOutlined
} from '@ant-design/icons';
import {Button, Col, Form, Input, Space, Modal, Row, Select, Table, Tabs, Tag, Tooltip, Empty} from 'antd';
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import PageTop from "../../layout/PageTop";
import AssessmentForm from '../AssessmentForm';
import "./index.less"

const FormItem = Form.Item
const {TabPane} = Tabs

//  工单类型
const REPAIR_TYPE_NORMAL = "1" // 普通工单
const REPAIR_TYPE_DELEGATE = "2" // 内协工单
const repairTypeAlias = {
    [REPAIR_TYPE_NORMAL]: '普通工单',
    [REPAIR_TYPE_DELEGATE]: '内协工单'
}

// 收费区分 1:客户自费、2:保险维修、3:厂家索赔、4:委托费用
const CHARGE_TYPE_CUSTOMER = 1
const CHARGE_TYPE_INSURE = 2
const CHARGE_TYPE_FACTORY = 3

let chargeTypeAlias = {
    [CHARGE_TYPE_CUSTOMER]: '客户自费',
    [CHARGE_TYPE_INSURE]: '保险维修',
    [CHARGE_TYPE_FACTORY]: '厂家索赔',
}


function RepairProfitCheck(props) {
    let {repairChecks} = props
    const [form] = Form.useForm();

    // 初始化维修工单
    let initRepair = {
        id: "",
        customerId: "",
        customerName: "",
        clearCreator: "",
        repairItems: [],
        consignorCompanyId: "",
        consignorCompanyName: "",
        partnerId: "",
        partnerName: "",
        factoryId: "",
        factoryName: "",
        vehicleSpec: "",
        vehicleSelfCode: "",
        vehicleValidUntil: "",
        vehicleIsWarranty: "",
        vehicleContractCompanyName: "",
    }


    // 初始化结算表单
    let initForm = {
        "code": "001AR2025030024",
        "customerName": "RMM",
        "plate": "川JPJLJ*",
        "vehicleName": "张三",
        "vehiclePhone": "18310172737",
        "makeAt": "2025-03-30 18:31:14",
        "name": "张三",
        "seriesName": "所有车系",
        "productName": "吉普",
        "mileage": 1,
        "vin": "***********123321",
        "clearingAt": null,
        "repairCategoryName": "一般维修",
        "repairItemNameList": "",
        "statusName": "已竣工",
        "predictProductTotal": "0.00",
        "projectTotal": "0.00",
        "deliveryTotal": "0.00",
        "total": "4878.61",
        "productReceiptTotal": "0.00",
        "productReceiptTotalNotTax": "0.00",
        "employeeName": "系统管理员",
        "employeeMobile": "18080852216",
        "partnerName": "平安车险公司",
        "vehiclePartnerName": "",
        "registerDate": null,
        "buyDate": null,
        "vehicleCreatedAt": null,
        "typeName": null,
        "vehicleModeName": null,
        "color": "",
        "vehicleCode": "SUP2025030086",
        "vehicleSpec": "测试6位",
        "vehicleSelfCode": "",
        "vehicleValidUntil": null,
        "vehicleIsWarranty": "",
        "vehicleContractCompanyName": "",
        "purposeName": null,
        "premiumPartnerName": "",
        "premiumEmployeeName": "",
        "premiumStartAt": null,
        "premiumEndAt": null,
        "premiumAuditAt": null,
        "avocation": "否",
        "avocationStartAt": null,
        "avocationEndAt": null,
        "avocationPartnerCompanyName": "",
        "spareFrameNumber": "",
        "id": "625959864548765696",
        "tenantId": "1005",
        "ownerId": "620408211648843776",
        "companyName": "成都比亚迪4S旗舰店",
        "creatorId": "620410443580608512",
        "type": "1",
        "consignorCompanyId": "",
        "consignorCompanyName": null,
        "delegateId": "",
        "vehicleId": "624106235235631104",
        "brandId": null,
        "brandName": "综合品牌",
        "seriesId": null,
        "productId": null,
        "status": 4,
        "productDeliveryTotal": "0.00",
        "dispatchAt": "2025-03-30 18:33:34",
        "finishAt": "2025-03-30 18:36:09",
        "repairCategoryId": "620405737818996736",
        "phone": "18310172737",
        "failure": "",
        "inspection": "",
        "spec": "",
        "clearingSpec": "",
        "customerId": "620736162613506048",
        "customerPhone": "18310172737",
        "customerAddress": "",
        "customerCode": "M2025030003",
        "partnerId": "620492461865775104",
        "factoryId": "",
        "factoryName": "",
        "employeeId": "620410443580608512",
        "dispatchCreator": "系统管理员",
        "clearCreator": "",
        "clearCreatorId": "",
        "estimateFinish": "2025-04-09 18:31:15",
        "schedule": 0,
        "repairItems": [
            {
                "tenantId": "1005",
                "id": "625960079527817216",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620453460538626048",
                "productName": "转向节",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "455.00",
                "salePrice": "455.00",
                "price": "455.00",
                "deliveryPrice": "455.00",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9022218",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "转向节",
                "isDefault": true,
                "originSalePrice": "455.00"
            },
            {
                "tenantId": "1005",
                "id": "625960079527817217",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620452810421506048",
                "productName": "加热器与空调控制",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "459.10",
                "salePrice": "459.10",
                "price": "459.10",
                "deliveryPrice": "459.10",
                "receiptPrice": "100.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "96539550",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "加热器与空调控制",
                "isDefault": true,
                "originSalePrice": "459.10"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851712",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620452810421506048",
                "productName": "加热器与空调控制",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "459.10",
                "salePrice": "459.10",
                "price": "459.10",
                "deliveryPrice": "459.10",
                "receiptPrice": "100.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "96539550",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "加热器与空调控制",
                "isDefault": true,
                "originSalePrice": "459.10"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851713",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620452811277144064",
                "productName": "自动变速器控制总成",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "845.01",
                "salePrice": "845.01",
                "price": "845.01",
                "deliveryPrice": "845.01",
                "receiptPrice": "100.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "96639108",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "自动变速器控制总成",
                "isDefault": true,
                "originSalePrice": "845.01"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851714",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620452809108688896",
                "productName": "前地板控制台总成   *灰色",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "152.76",
                "salePrice": "152.76",
                "price": "152.76",
                "deliveryPrice": "152.76",
                "receiptPrice": "100.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "96485052",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "前地板控制台总成   *灰色",
                "isDefault": true,
                "originSalePrice": "152.76"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851715",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620453460723175424",
                "productName": "空气滤清器接管",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "122.59",
                "salePrice": "122.59",
                "price": "122.59",
                "deliveryPrice": "122.59",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9022003",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "空气滤清器接管",
                "originSalePrice": "122.59"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851716",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620453460538626048",
                "productName": "转向节",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "455.00",
                "salePrice": "455.00",
                "price": "455.00",
                "deliveryPrice": "455.00",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9022218",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "转向节",
                "isDefault": true,
                "originSalePrice": "455.00"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851717",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620453460358270976",
                "productName": "右前前悬架动力系统横梁总成",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "910.00",
                "salePrice": "910.00",
                "price": "910.00",
                "deliveryPrice": "910.00",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9022224",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "右前前悬架动力系统横梁总成",
                "originSalePrice": "910.00"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851718",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620453460165332992",
                "productName": "后端板饰件总成",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "41.00",
                "salePrice": "41.00",
                "price": "41.00",
                "deliveryPrice": "41.00",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9022562",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "后端板饰件总成",
                "originSalePrice": "41.00"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851719",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620453459976589312",
                "productName": "行李厢地板地毯总成                      *墨黑色",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "141.00",
                "salePrice": "141.00",
                "price": "141.00",
                "deliveryPrice": "141.00",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9022654",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "行李厢地板地毯总成                      *墨黑色",
                "originSalePrice": "141.00"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851720",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191099986690048",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 1,
                "chargeTypeName": null,
                "productId": "620453459804622848",
                "productName": "行李厢侧饰件                       *墨黑色",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "70.00",
                "salePrice": "70.00",
                "price": "70.00",
                "deliveryPrice": "70.00",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9022680",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "行李厢侧饰件                       *墨黑色",
                "originSalePrice": "70.00"
            },
            {
                "tenantId": "1005",
                "id": "625960689060851721",
                "ownerId": "620408211648843776",
                "repairId": "625959864548765696",
                "companyId": "620408211648843776",
                "companyName": "成都比亚迪4S旗舰店",
                "type": "3",
                "fiduciaryCompanyId": "",
                "fiduciaryCompanyName": "",
                "businessKind": "620191100200599552",
                "dispatchTime": "0.00",
                "dispatchTotal": "0.00",
                "dispatchPrice": "0.00",
                "dispatchPercentage": "50",
                "businessKindName": null,
                "chargeType": 2,
                "chargeTypeName": null,
                "productId": "620453459594907648",
                "productName": "前轮轴承总成",
                "projectId": "",
                "projectName": "",
                "number": null,
                "total": "138.05",
                "salePrice": "138.05",
                "price": "138.05",
                "deliveryPrice": "138.05",
                "receiptPrice": "200.00",
                "actualCost": "0.00",
                "quantity": "1.00",
                "sectionId": "620408666261065728",
                "sectionName": "机电",
                "groupId": "620408738751221760",
                "groupName": "机电一组",
                "workerId": "620410577802530816",
                "workerName": "晏胜",
                "remark": "",
                "couponDiscount": 0,
                "cardDiscount": 0,
                "manualDiscount": 0,
                "priceRate": "0.00",
                "supplier": "",
                "spec": "",
                "isMaintenance": 1,
                "dispatchAt": null,
                "finishAt": null,
                "status": 10,
                "printTag": "",
                "plate": null,
                "seriesName": null,
                "code": null,
                "makeAt": null,
                "clearingAt": null,
                "employeeName": null,
                "productDto": {
                    "id": null,
                    "tenantId": null,
                    "creatorId": null,
                    "code": null,
                    "name": null,
                    "spec": null,
                    "unit": "件",
                    "number": "9024116",
                    "price": null,
                    "businessKind": null,
                    "quantity": "1.00",
                    "source": "原厂授权件",
                    "receiptItemDto": null
                },
                "projectDto": null,
                "warehouse": "品牌库",
                "manualRate": 0,
                "name": "前轮轴承总成",
                "originSalePrice": "138.05"
            }
        ],
        "workerMap": {},
        "plans": [],
        "fiduciaryCompanyId": "",
        "fiduciaryCompanyName": "",
        "delegateCode": "",
        "appointmentId": "",
        "departmentId": "620408360324337664",
        "departmentName": "研发部",
        "engineNum": "",
        "prepayAt": "2025-04-01",
        "premium": null,
        "chargeTypeNameStr": "客户自费,保险维修",
        "businessKindStr": "自费维修,保险维修",
        "imageList": [],
        "feedbackStatus": null,
        "inspectionId": null,
        "vehicleInspectionId": null,
        "extra": "{}",
        "havePrint": false,
        "clientRemark": null,
        "clientSignaturePhoto": null,
        "clientSignatureDate": null,
        "prepaySignaturePhoto": "https://erpauto.oss-cn-chengdu.aliyuncs.com/2025/03/31/d06edb9a01304973974a1c88e692163f.png",
        "prepaySignatureDate": "2025-03-31 21:10:51"
    }

    let [repair, setRepair] = useState(initForm) // 维修工单
    let [profitObj, setProfitObj] = useState({
        self: '0', // 自费
        insurance: '0', // 保险
        factory: '0' // 索赔
    })

    useEffect(() => {
        if (repairChecks) {
            setRepair(repairChecks)
        }
    }, [])

    useEffect(() => {
        if (repair.repairItems.length) {
            let obj = {}
            let repairItems = [...repair.repairItems]
            if (repairItems.filter(fitem => chargeTypeAlias[fitem.chargeType].indexOf('自费') != -1).length) {
                // 有自费类材料
                let self_product_items = [...repairItems.filter(fitem => chargeTypeAlias[fitem.chargeType].indexOf('自费') != -1)]
                // 销售总价
                let self_priceTotal = self_product_items.reduce((a, c) => {return new BigNumber(a).plus(c.price || 0)}, new BigNumber(0))
                // 入库总价
                let self_receiptPriceTotal = self_product_items.reduce((a, c) => {return new BigNumber(a).plus(c.receiptPrice || 0)}, new BigNumber(0))
                obj.self = self_priceTotal.minus(self_receiptPriceTotal).dividedBy(self_priceTotal).multipliedBy(100).toFixed(2)
            }
            if (repairItems.filter(fitem => chargeTypeAlias[fitem.chargeType].indexOf('保险') != -1).length) {
                // 有保险类材料
                let insurance_product_items = [...repairItems.filter(fitem => chargeTypeAlias[fitem.chargeType].indexOf('保险') != -1)]
                // 销售总价
                let insurance_priceTotal = insurance_product_items.reduce((a, c) => {return new BigNumber(a).plus(c.price || 0)}, new BigNumber(0))
                // 入库总价
                let insurance_receiptPriceTotal = insurance_product_items.reduce((a, c) => {return new BigNumber(a).plus(c.receiptPrice || 0)}, new BigNumber(0))
                obj.insurance = insurance_priceTotal.minus(insurance_receiptPriceTotal).dividedBy(insurance_priceTotal).multipliedBy(100).toFixed(2)
            }
            if (repairItems.filter(fitem => chargeTypeAlias[fitem.chargeType].indexOf('索赔') != -1).length) {
                // 有保险类材料
                let factory_product_items = [...repairItems.filter(fitem => chargeTypeAlias[fitem.chargeType].indexOf('索赔') != -1)]
                // 销售总价
                let factory_priceTotal = factory_product_items.reduce((a, c) => {return new BigNumber(a).plus(c.price || 0)}, new BigNumber(0))
                // 入库总价
                let factory_receiptPriceTotal = factory_product_items.reduce((a, c) => {return new BigNumber(a).plus(c.receiptPrice || 0)}, new BigNumber(0))
                obj.factory = factory_priceTotal.minus(factory_receiptPriceTotal).dividedBy(factory_priceTotal).multipliedBy(100).toFixed(2)
            }
            setProfitObj({
                ...profitObj,
                ...obj
            })
        }
    }, [repair.repairItems])

    let [assessmentList, setAssessmentList] = useState([])
    const [assessmentId, setAppointmentId] = useState('')
    const onGetAssessmentList = (id) => {
        common.loadingStart()
        let params = {
            repairId: id,
            ownerId: common.getUser().company.id,
            insuranceCompanyName: '',
            customerName: '',
            vehicleName: '',
            plate: '',
            vin: '',
            spec: '',
            repairCode: '',
        }
        common.ajax('get', '/support/insurance/claims/page', params).then(res => {
            // setTotal(res.pagination.total)
            if (res.list.length > 0) {
                setAssessmentList([...res.list])
            } else {
                setAssessmentList([])
            }
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        if (repair.id) {
            onGetAssessmentList(repair.id)
        }
    }, [repair.id])

    let columns = [
        {
            title: '序号',
            dataIndex: 'key',
            key: 'key',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => index + 1
        },
        {
            title: '配件编号',
            width: 120,
            ellipsis: true,
            dataIndex: ['productDto', 'number'],
        },
        {
            title: '配件名称',
            width: 120,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '付费类型',
            width: 80,
            dataIndex: 'chargeType',
            ellipsis: true,
            render: (text, record, index) => {
                return chargeTypeAlias[text]
            }
        },
        {
            title: '数量',
            width: 80,
            align: "right",
            dataIndex: 'quantity',
            ellipsis: true,
        },
        {
            title: '出库单价',
            width: 80,
            align: "right",
            dataIndex: 'deliveryPrice',
            ellipsis: true,
        },
        {
            title: '出库金额',
            width: 80,
            align: "right",
            dataIndex: 'deliveryPrice',
            ellipsis: true,
            render: (text, record, index) => {
                return new BigNumber(record.quantity).multipliedBy(record.deliveryPrice).toFixed(2)
            }
        },
        {
            title: '结算单价',
            width: 80,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
        },
        {
            title: '结算金额',
            width: 80,
            align: "right",
            dataIndex: 'price',
            ellipsis: true,
            render: (text, record, index) => {
                return new BigNumber(record.quantity).multipliedBy(record.price).toFixed(2)
            }
        },
        {
            title: '成本单价',
            width: 80,
            align: "right",
            dataIndex: 'receiptPrice',
            ellipsis: true,
        },
        {
            title: '成本金额',
            width: 80,
            align: "right",
            dataIndex: 'receiptPrice',
            ellipsis: true,
            render: (text, record, index) => {
                return new BigNumber(record.quantity).multipliedBy(record.receiptPrice).toFixed(2)
            }
        },
        {
            title: '材料毛利',
            width: 80,
            align: "right",
            dataIndex: 'receiptPrice',
            ellipsis: true,
            render: (text, record, index) => {
                return new BigNumber(record.quantity).multipliedBy(new BigNumber(record.price).minus(record.receiptPrice)).toFixed(2)
            }
        },
        {
            title: '毛利率',
            width: 80,
            align: "right",
            dataIndex: 'receiptPrice',
            ellipsis: true,
            render: (text, record, index) => {
                return new BigNumber(record.price).isEqualTo(0) ? '-100%' : (new BigNumber(record.price).minus(record.receiptPrice).dividedBy(record.price).multipliedBy(100).toFixed(2) + '%')
            }
        },
    ]

    let assessmentColums = [
        {
            title: '序号',
            key: 'index',
            dataIndex: 'index',
            width: 80,
            fixed: 'left',
            render: (text, record, index) => `${record.index != '小计' ? index + 1 : record.index}`,
        },
        {
            title: '维修单号',
            width: 120,
            dataIndex: ['repairDto', 'code'],
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: ['repairDto', 'plate'],
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '车架号',
            width: 120,
            dataIndex: ['repairDto', 'vin'],
            ellipsis: true,
        },
        {
            title: '品牌',
            width: 120,
            dataIndex: ['repairDto', 'brandName'],
            ellipsis: true,
        },
        {
            title: '车系',
            width: 120,
            dataIndex: ['repairDto', 'seriesName'],
            ellipsis: true,
        },
        {
            title: '车型',
            width: 120,
            dataIndex: ['repairDto', 'productName'],
            ellipsis: true,
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: ['repairDto', 'employeeName'],
            ellipsis: true,
        },
        // {
        //     title: '理赔照片',
        //     width: 120,
        //     dataIndex: 'imageUrlList',
        //     ellipsis: true,
        //     render: (text, record) => {
        //         return text.length
        //     }
        // },
        // {
        //     title: '理赔文件',
        //     width: 120,
        //     dataIndex: 'pdfUrlList',
        //     ellipsis: true,
        //     render: (text, record) => {
        //         return text.length
        //     }
        // },
        {
            title: '定损工时',
            width: 120,
            dataIndex: 'projectCost',
            ellipsis: true,
        },
        {
            title: '定损材料',
            width: 120,
            dataIndex: 'materialCost',
            ellipsis: true,
        },
        {
            title: '其他费用',
            width: 120,
            dataIndex: 'otherCost',
            ellipsis: true,
        },
        {
            title: '定损金额',
            width: 120,
            dataIndex: 'vin',
            ellipsis: true,
            render: (text, record) => {
                return new BigNumber(0).plus(record.projectCost).plus(record.materialCost).plus(record.otherCost).toFormat(2)
            }
        },
        {
            title: '维修净产值',
            width: 120,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '差额',
            width: 120,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '保险公司1',
            width: 120,
            dataIndex: 'insuranceCompanyName',
            ellipsis: true,
        },
        {
            title: '定损员',
            width: 120,
            dataIndex: 'insuranceCompanyId',
            ellipsis: true,
        },
        // {
        //     title: '保险公司2',
        //     width: 120,
        //     dataIndex: 'vin',
        //     ellipsis: true,
        // },
        // {
        //     title: '定损员',
        //     width: 120,
        //     dataIndex: 'vin',
        //     ellipsis: true,
        // },
        {
            title: '定损备注',
            width: 120,
            dataIndex: 'spec',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            ellipsis: true,
            fixed: 'right',
            render: (text, record) => {
                return (
                    <div>
                        <Space>
                            <a onClick={() => {
                                setAppointmentId(record.id)
                            }}>查看</a>
                        </Space>
                    </div>
                )
            }
        },
    ]

    let caclColumns = [
        {
            title: '序号',
            dataIndex: 'key',
            key: 'key',
            width: 80,
            ellipsis: true,
            render: (text, record, index) => index + 1
        },
        {
            title: '项目名称',
            width: 120,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '维修工段',
            width: 120,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '付费类型',
            width: 80,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '工时金额（优惠后）',
            width: 80,
            align: "right",
            dataIndex: 'customerProductProfit',
            ellipsis: true,
        },
        {
            title: '版面数或提成系数',
            width: 80,
            align: "right",
            dataIndex: 'insuranceProductProfit',
            ellipsis: true,
        },
        {
            title: '工时单价',
            width: 80,
            align: "right",
            dataIndex: 'factoryProductProfit',
            ellipsis: true,
        },
        {
            title: '工时成本',
            width: 80,
            align: "right",
            dataIndex: 'insuranceProductProfit',
            ellipsis: true,
        },
        {
            title: '工时毛利',
            width: 80,
            align: "right",
            dataIndex: 'factoryProductProfit',
            ellipsis: true,
        },
        {
            title: '毛利率',
            width: 80,
            align: "right",
            dataIndex: 'insuranceProductProfit',
            ellipsis: true,
        },
    ]

    let onFinish = () => {

    }

    return (
        <React.Fragment>
            <PageTop title={'维修毛利审核'}></PageTop>

            <Form form={form} onFinish={onFinish} id={"prepay-warp"} initialValues={initForm}>
                <Row gutter={24}>
                    <Col span={6}>
                        <FormItem label={"车牌号码"}>
                            <span className="ant-form-text">{repair.plate}</span>
                        </FormItem>
                        <FormItem label={"工单编号"}>
                            <span className="ant-form-text">{repair.code}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"进厂日期"}>
                            <span className="ant-form-text">{repair.makeAt}</span>
                        </FormItem>
                        <FormItem label={"预结日期"}>
                            <span className="ant-form-text">{repair.prepayAt}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"服务顾问"}>
                            <span className="ant-form-text">{repair.employeeName} </span>
                        </FormItem>
                        <FormItem label={"维修车型"}>
                            <span className="ant-form-text">{repair.productName}</span>
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <FormItem label={"保险公司"}>
                            <span className="ant-form-text">{repair.partnerName}</span>
                        </FormItem>
                        <FormItem label={"工单类型"}>
                            <span className="ant-form-text">
                                {
                                    (repair.type === REPAIR_TYPE_DELEGATE ? repairTypeAlias[REPAIR_TYPE_DELEGATE] : repairTypeAlias[REPAIR_TYPE_NORMAL]) + " " + (repair.repairCategoryName || "")
                                }
                            </span>
                        </FormItem>
                    </Col>
                    {/* <Col span={24}>
                        <FormItem label={"预结备注"}>
                            <span className="ant-form-text"> {repair.prepaySpec}</span>
                        </FormItem>
                    </Col> */}
                    <Col span={8}>
                        <FormItem label={"自费维修材料毛利率"}>
                            <span className="ant-form-text">
                                <strong>{profitObj.self}%</strong>
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"保险维修材料毛利率"}>
                            <span className="ant-form-text">
                                <strong>{profitObj.insurance}%</strong>
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={8}>
                        <FormItem label={"索赔维修材料毛利率"}>
                            <span className="ant-form-text">
                                <strong>{profitObj.factory}%【非考核项】</strong>
                            </span>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="毛利明细" key="1">
                                <Table
                                    rowKey={record => record.id}
                                    scroll={{x: '100%'}}
                                    pagination={['bottomRight']}
                                    columns={columns}
                                    dataSource={repair.repairItems}
                                // summary={() => {
                                //     return (
                                //         <tr>
                                //             <th colSpan={6}>小计</th>
                                //             <td>￥</td>
                                //             <td />
                                //             <td>￥</td>
                                //             <td />
                                //             <td>￥</td>
                                //             <td>￥</td>
                                //             <td>￥</td>
                                //         </tr>
                                //     )
                                // }}
                                />
                            </TabPane>
                            <TabPane tab="定损详情" key="2">
                                {
                                    assessmentList.length != 0 ? <AssessmentForm repair={repair} id={assessmentList[0].id} scene="look" /> :
                                        <Empty description='暂无定损记录' />
                                }
                                {/* <Table
                                    columns={assessmentColums}
                                    pagination={['bottomRight']}
                                    scroll={{x: '100%'}}
                                    rowKey={record => record.id}
                                    dataSource={assessmentList}
                                /> */}
                            </TabPane>
                        </Tabs>
                    </Col>
                    {/* <Col span={24}>
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="计算法1" key="1">
                                <Table
                                    rowKey={record => record.id}
                                    scroll={{x: '100%'}}
                                    pagination={false}
                                    columns={caclColumns}
                                    dataSource={[{}]}
                                    summary={() => {
                                        return (
                                            <tr>
                                                <th colSpan={4}>小计</th>
                                                <td>￥</td>
                                                <td />
                                                <td />
                                                <td>￥</td>
                                                <td>￥</td>
                                                <td>￥</td>
                                            </tr>
                                        )
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </Col>
                    <Col span={24}>
                        <FormItem label={"定损附件"}>
                            <span className="ant-form-text"></span>
                        </FormItem>
                        <FormItem label={"定损照片"}>
                            <span className="ant-form-text"> </span>
                        </FormItem>
                        <FormItem label={"申请人"}>
                            <span className="ant-form-text"> </span>
                        </FormItem>
                        <FormItem label={"申请时间"}>
                            <span className="ant-form-text"></span>
                        </FormItem>
                        <FormItem label={"申请理由"}>
                            <span className="ant-form-text"></span>
                        </FormItem>
                        <FormItem label={"审批说明"}>
                            <span className="ant-form-text"></span>
                        </FormItem>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={6} offset={6}>
                                <Space>
                                    <Button type='primary'>通过</Button>
                                    <Button>驳回</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Col> */}
                </Row>
            </Form>
            <Modal
                visible={assessmentId !== ''}
                destroyOnClose={true}
                maskClosable={false}
                width={1000}
                footer={null}
                onCancel={() => {
                    setAppointmentId('')
                }}>
                <br />
                <AssessmentForm repair={repair} id={assessmentId} scene="look" />
            </Modal>
        </React.Fragment>
    )
}

export default RepairProfitCheck