import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, Pagination, Row, Table, Tabs} from "antd";
import {connect} from "react-redux";
import {ReloadOutlined, SearchOutlined, UploadOutlined} from "@ant-design/icons";
import SearchArea from "../../layout/SearchArea";
import moment from "moment";
import common from "../../../utils/common";
import PageBottom from "../../layout/PageBottom";

const FormItem = Form.Item
const {TabPane} = Tabs
const {RangePicker} = DatePicker;

function OldRepairItemList(props) {
    let initialProjectSearch = {
        plate: props.plate || "",
        repairCode: "",
        phone: "",
        reportAtStart: "",
        reportAtEnd: "",
        finishAtStart: "",
        finishAtEnd: "",
        ownerId: common.getUser().company.id
    }
    let initialProductSearch = {
        plate: props.plate || "",
        deliveryAtStart: "",
        deliveryAtEnd: "",
        number: "",
        repairCode: "",
        ownerId: common.getUser().company.id
    }
    let initialOriOrderSearch = {
        plate: props.plate || "",
        repairCode: "",
        customerName: "",
        vin: "",
        type: "",
        employeeName: "",
        companyName: "",
        makeAtStart: "",
        makeAtEnd: "",
        clearingAtStart: "",
        clearingAtEnd: "",
        bothVin: "",
        ownerId: common.getUser().company.id
    }
    let [projects, setProjects] = useState([]) //维修工时
    let [products, setProducts] = useState([]) //维修材料
    let [oriOrders, setOriOrders] = useState([]) //原始工单
    let [projectSearch, setProjectSearch] = useState(initialProjectSearch) //搜索维修工时输入的内容
    let [productSearch, setProductSearch] = useState(initialProductSearch) //搜索维修材料输入的内容
    let [oriOrderSearch, setOriOrderSearch] = useState(initialOriOrderSearch) //搜索原始工单输入的内容
    let [projectQuery, setProjectQuery] = useState(initialProjectSearch)   //提交维修工时ajax搜索值
    let [productQuery, setProductQuery] = useState(initialProductSearch)   //提交维修材料ajax搜索值
    let [oriOrderQuery, setOriOrderQuery] = useState(initialOriOrderSearch)   //提交原始工单ajax搜索值
    let [projectTotal, setProjectTotal] = useState(0)     //维修工时总记录条数
    let [productTotal, setProductTotal] = useState(0)     //维修材料总记录条数
    let [oriOrderTotal, setOriOrderTotal] = useState(0)     //原始工单总记录条数
    let [projectPagination, setProjectPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })
    let [productPagination, setProductPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })
    let [oriOrderPagination, setOriOrderPagination] = useState({
        currentPage: 1,
        pageSize: 10,
    })
    const projectColumns = [
        {
            title: '工号',
            width: 120,
            dataIndex: 'repairCode',
            ellipsis: true,
        },
        {
            title: '报修时间',
            width: 150,
            dataIndex: 'reportAt',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '工段',
            width: 120,
            dataIndex: 'sectionName',
            ellipsis: true,
        },
        {
            title: '维修班组',
            width: 120,
            dataIndex: 'groupName',
            ellipsis: true,
        },
        {
            title: '主修人',
            width: 120,
            dataIndex: 'workerName',
            ellipsis: true,
        },
        {
            title: '客户工时费',
            width: 120,
            dataIndex: 'customerPrice',
            ellipsis: true,
        },
        {
            title: '维修内容',
            width: 150,
            dataIndex: 'content',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 120,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '保险工时费',
            width: 120,
            dataIndex: 'insurancePrice',
            ellipsis: true,
        },
        {
            title: '索赔工时费',
            width: 120,
            dataIndex: 'factoryPrice',
            ellipsis: true,
        },
        {
            title: '折扣前工时费',
            width: 120,
            dataIndex: 'originalPrice',
            ellipsis: true,
        },
        {
            title: '优惠工时',
            width: 120,
            dataIndex: 'discount',
            ellipsis: true,
        },
        {
            title: '合计工时费用',
            width: 120,
            dataIndex: 'totalPrice',
            ellipsis: true,
        },
        {
            title: '固定提成',
            width: 120,
            dataIndex: 'profit',
            ellipsis: true,
        },
        {
            title: '业务员',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '出厂时间',
            width: 150,
            dataIndex: 'finishAt',
            ellipsis: true,
        },
        {
            title: '交修原因',
            width: 120,
            dataIndex: 'failure',
            ellipsis: true,
        },
    ]
    const productColumns = [
        {
            title: '库别',
            width: 100,
            dataIndex: 'skuName',
            ellipsis: true,
        },
        {
            title: '配件号',
            width: 150,
            dataIndex: 'number',
            ellipsis: true,
        },
        {
            title: '配件名称',
            width: 200,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '工号',
            width: 120,
            dataIndex: 'repairCode',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '出库日期',
            width: 150,
            dataIndex: 'deliveryAt',
            ellipsis: true,
        },
        {
            title: '单位',
            width: 80,
            dataIndex: 'unit',
            ellipsis: true,
        },
        {
            title: '数量',
            width: 120,
            dataIndex: 'quantity',
            ellipsis: true,
        },
        {
            title: '出库单价',
            width: 120,
            dataIndex: 'deliveryPrice',
            ellipsis: true,
        },
        {
            title: '出库价税金额',
            width: 120,
            dataIndex: 'deliveryTax',
            ellipsis: true,
        },
        {
            title: '实际出库金额',
            width: 120,
            dataIndex: 'actualPayPrice',
            ellipsis: true,
        },
        {
            title: '出库单号',
            width: 120,
            dataIndex: 'deliveryCode',
            ellipsis: true,
        },
        {
            title: '出库类型',
            width: 120,
            dataIndex: 'deliveryName',
            ellipsis: true,
        },
        {
            title: '材料类型',
            width: 120,
            dataIndex: 'productType',
            ellipsis: true,
        },
        {
            title: '车型',
            width: 120,
            dataIndex: 'productName',
            ellipsis: true,
        },
        {
            title: '发料人',
            width: 120,
            dataIndex: 'granterName',
            ellipsis: true,
        },
        {
            title: '领料人',
            width: 120,
            dataIndex: 'collectorName',
            ellipsis: true,
        },
        {
            title: '维修班组',
            width: 120,
            dataIndex: 'groupName',
            ellipsis: true,
        },
        {
            title: '业务员',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '进价',
            width: 120,
            dataIndex: 'receiptPrice',
            ellipsis: true,
        },
        {
            title: '制单人',
            width: 120,
            dataIndex: 'makerName',
            ellipsis: true,
        },
        {
            title: '入库时间',
            width: 150,
            dataIndex: 'receiptDate',
            ellipsis: true,
        },
        {
            title: '供货商',
            width: 120,
            dataIndex: 'supplierName',
            ellipsis: true,
        },
        {
            title: 'VIN号',
            width: 120,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '单据备注',
            width: 150,
            dataIndex: 'spec',
            ellipsis: true,
        },
    ]
    let oriOrderColumns = [
        {
            title: '维修单号',
            width: 120,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '工单类型',
            width: 120,
            dataIndex: 'type',
            ellipsis: true,
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: 'plate',
            ellipsis: true,
        },
        {
            title: '进厂日期',
            width: 150,
            dataIndex: 'makeAt',
            ellipsis: true,
        },
        {
            title: '车系',
            width: 120,
            dataIndex: 'seriesName',
            ellipsis: true,
        },
        {
            title: '里程数',
            width: 120,
            dataIndex: 'mileage',
            ellipsis: true,
        },
        {
            title: '车架号',
            width: 120,
            dataIndex: 'vin',
            ellipsis: true,
        },
        {
            title: '联系电话',
            width: 120,
            dataIndex: 'spareFrameNumber',
            ellipsis: true,
        },
        {
            title: '客户名称',
            width: 120,
            dataIndex: 'customerName',
            ellipsis: true,
        },
        {
            title: '送修人',
            width: 150,
            dataIndex: 'name',
            ellipsis: true,
        },
        {
            title: '结算日期',
            width: 150,
            dataIndex: 'clearingAt',
            ellipsis: true,
        },
        {
            title: '结算金额',
            width: 120,
            dataIndex: 'total',
            ellipsis: true,
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: 'employeeName',
            ellipsis: true,
        },
        {
            title: '经营主体',
            width: 120,
            dataIndex: 'companyName',
            ellipsis: true,
        },
    ]

    //获取维修工时
    let getRepairItemProjectList = () => {
        let params = {
            ...projectQuery,
            page: projectPagination.currentPage,
            limit: projectPagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/support/oldRepairItemProject/list', params)
            .then((res) => {
                projects = res.oldRepairItemProjects || []
                setProjects([...projects])
                setProjectTotal(res.pagination.total)
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getRepairItemProjectList()
    }, [projectPagination, projectQuery])

    // 当前页码改变或每页数量改变
    let handleProjectPageChange = (currentPage, pageSize) => {
        setProjectPagination({currentPage, pageSize})
    }


    //获取维修材料
    let getRepairItemProductList = () => {
        let params = {
            ...productQuery,
            page: productPagination.currentPage,
            limit: productPagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/support/oldRepairItemProduct/list', params)
            .then((res) => {
                products = res.oldRepairItemProducts || []
                setProducts([...products])
                setProductTotal(res.pagination.total)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getRepairItemProductList()
    }, [productPagination, productQuery])

    // 当前页码改变或每页数量改变
    let handleProductPageChange = (currentPage, pageSize) => {
        setProductPagination({currentPage, pageSize})
    }

    // 当前页码改变或每页数量改变
    let handleOriOrderPageChange = (currentPage, pageSize) => {
        setOriOrderPagination({currentPage, pageSize})
    }
    let getRepairItemOrderList = () => {
        let params = {
            ...oriOrderQuery,
            page: oriOrderPagination.currentPage,
            limit: oriOrderPagination.pageSize,
            ownerId: common.getUser().company.id,
        }
        common.loadingStart()
        common.ajax('get', '/support/oldRepair/page', params)
            .then((res) => {
                setOriOrders(res.list || [])
                setOriOrderTotal(res.pagination.total)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getRepairItemOrderList()
    }, [oriOrderPagination, oriOrderQuery])

    return (
        <React.Fragment>
            <Tabs defaultActiveKey="1">
                <TabPane tab="维修工时" key="1">
                    <SearchArea>
                        <Form className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={6}>
                                    <FormItem label={"报修日期"}>
                                        <RangePicker
                                            value={[projectSearch.reportAtStart === "" ? null : moment(projectSearch.reportAtStart),
                                            projectSearch.reportAtEnd === "" ? null : moment(projectSearch.reportAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setProjectSearch({
                                                        ...projectSearch,
                                                        reportAtStart: "",
                                                        reportAtEnd: "",
                                                    })
                                                } else {
                                                    setProjectSearch({
                                                        ...projectSearch,
                                                        reportAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        reportAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"车牌号码"}>
                                        <Input placeholder={"请输入车牌号码"}
                                            value={projectSearch.plate}
                                            onChange={(e) => {
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    plate: e.target.value.trim()
                                                })
                                                setProductSearch({
                                                    ...productSearch,
                                                    plate: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {
                                                setProjectPagination({...projectPagination, currentPage: 1})
                                                setProjectQuery(projectSearch)
                                                setProductPagination({...productPagination, currentPage: 1})
                                                setProductQuery(productSearch)
                                            }}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={6}>
                                    <FormItem className="label-character-4" label={"维修内容"}>
                                        <Input placeholder={"请输入维修内容"}
                                            value={projectSearch.content}
                                            onChange={(e) => {
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    content: e.target.value.trim()
                                                })
                                                setProductSearch({
                                                    ...productSearch,
                                                    content: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {
                                                setProjectPagination({...projectPagination, currentPage: 1})
                                                setProjectQuery(projectSearch)
                                                setProductPagination({...productPagination, currentPage: 1})
                                                setProductQuery(productSearch)
                                            }}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={6}>
                                    <FormItem label={"主修人"}>
                                        <Input placeholder={"请输入主修人"}
                                            value={projectSearch.workerName}
                                            onChange={(e) => {
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    workerName: e.target.value.trim()
                                                })
                                                setProductSearch({
                                                    ...productSearch,
                                                    workerName: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {
                                                setProjectPagination({...projectPagination, currentPage: 1})
                                                setProjectQuery(projectSearch)
                                                setProductPagination({...productPagination, currentPage: 1})
                                                setProductQuery(productSearch)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"出厂日期"}>
                                        <RangePicker
                                            value={[projectSearch.finishAtStart === "" ? null : moment(projectSearch.finishAtStart),
                                            projectSearch.finishAtEnd === "" ? null : moment(projectSearch.finishAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setProjectSearch({
                                                        ...projectSearch,
                                                        finishAtStart: "",
                                                        finishAtEnd: "",
                                                    })
                                                } else {
                                                    setProjectSearch({
                                                        ...projectSearch,
                                                        finishAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        finishAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"维修工号"}>
                                        <Input placeholder={"请输入维修工号"}
                                            value={projectSearch.repairCode}
                                            onChange={(e) => {
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    repairCode: e.target.value.trim()
                                                })
                                                setProductSearch({
                                                    ...productSearch,
                                                    repairCode: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {
                                                setProjectPagination({...projectPagination, currentPage: 1})
                                                setProjectQuery(projectSearch)
                                                setProductPagination({...productPagination, currentPage: 1})
                                                setProductQuery(productSearch)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem className="label-character-4" label={"业务员"}>
                                        <Input placeholder={"请输入业务员"}
                                            value={projectSearch.employeeName}
                                            onChange={(e) => {
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    employeeName: e.target.value.trim()
                                                })
                                                setProductSearch({
                                                    ...productSearch,
                                                    employeeName: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {
                                                setProjectPagination({...projectPagination, currentPage: 1})
                                                setProjectQuery(projectSearch)
                                                setProductPagination({...productPagination, currentPage: 1})
                                                setProductQuery(productSearch)
                                            }}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined />} type="default" onClick={() => {
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectQuery(projectSearch)
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductQuery(productSearch)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined />} onClick={() => {
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectSearch(initialProjectSearch)
                                            setProjectQuery(initialProjectSearch)
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductSearch(initialProductSearch)
                                            setProductQuery(initialProductSearch)
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>
                    <Table
                        columns={projectColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        dataSource={projects} />

                    <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['10', '20', '40', '80']}
                                onChange={handleProjectPageChange}
                                onShowSizeChange={handleProjectPageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={projectTotal}
                                defaultCurrent={projectPagination.currentPage}
                                current={projectPagination.currentPage}
                                showSizeChanger
                                defaultPageSize={projectPagination.pageSize}
                            />}>
                    </PageBottom>
                </TabPane>

                <TabPane tab="维修材料" key="2">
                    <SearchArea>
                        <Form className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={6}>
                                    <FormItem label={"配件号码"}>
                                        <Input placeholder={"请输入配件号"}
                                            value={productSearch.number}
                                            onChange={(e) => {
                                                setProductSearch({
                                                    ...productSearch,
                                                    number: e.target.value.trim()
                                                })
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"车牌号码"}>
                                        <Input placeholder={"请输入车牌号"}
                                            value={productSearch.plate}
                                            onChange={(e) => {
                                                setProductSearch({
                                                    ...productSearch,
                                                    plate: e.target.value.trim()
                                                })
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    plate: e.target.value.trim()
                                                })
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem className="label-character-4" label={"出库日期"}>
                                        <RangePicker
                                            value={[productSearch.deliveryAtStart === "" ? null : moment(productSearch.deliveryAtStart),
                                            productSearch.deliveryAtEnd === "" ? null : moment(productSearch.deliveryAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setProductSearch({
                                                        ...productSearch,
                                                        deliveryAtStart: "",
                                                        deliveryAtEnd: "",
                                                    })
                                                } else {
                                                    setProductSearch({
                                                        ...productSearch,
                                                        deliveryAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        deliveryAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"领料人"}>
                                        <Input placeholder={"请输入领料人"}
                                            value={productSearch.collectorName}
                                            onChange={(e) => {
                                                setProductSearch({
                                                    ...productSearch,
                                                    collectorName: e.target.value.trim()
                                                })
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    collectorName: e.target.value.trim()
                                                })
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"配件名称"}>
                                        <Input placeholder={"请输入配件名称"}
                                            value={productSearch.name}
                                            onChange={(e) => {
                                                setProductSearch({
                                                    ...productSearch,
                                                    name: e.target.value.trim()
                                                })
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    name: e.target.value.trim()
                                                })
                                            }} />
                                    </FormItem>
                                </Col>

                                <Col span={6}>
                                    <FormItem label={"维修工号"}>
                                        <Input placeholder={"请输入维修工号"}
                                            value={productSearch.repairCode}
                                            onChange={(e) => {
                                                setProductSearch({
                                                    ...productSearch,
                                                    repairCode: e.target.value.trim()
                                                })
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    repairCode: e.target.value.trim()
                                                })
                                            }} />
                                    </FormItem>
                                </Col>

                                <Col span={6}>
                                    <FormItem className="label-character-4" label={"供货商"}>
                                        <Input placeholder={"请输入供货商"}
                                            value={productSearch.supplierName}
                                            onChange={(e) => {
                                                setProductSearch({
                                                    ...productSearch,
                                                    supplierName: e.target.value.trim()
                                                })
                                                setProjectSearch({
                                                    ...projectSearch,
                                                    supplierName: e.target.value.trim()
                                                })
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined />} type="default" onClick={() => {
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductQuery(productSearch)
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectQuery(projectSearch)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined />} onClick={() => {
                                            setProductPagination({...productPagination, currentPage: 1})
                                            setProductSearch(initialProductSearch)
                                            setProductQuery(initialProductSearch)
                                            setProjectPagination({...projectPagination, currentPage: 1})
                                            setProjectSearch(initialProjectSearch)
                                            setProjectQuery(initialProjectSearch)
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>
                    <Table
                        columns={productColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        dataSource={products}
                    />
                    <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['10', '20', '40', '80']}
                                onChange={handleProductPageChange}
                                onShowSizeChange={handleProductPageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={productTotal}
                                defaultCurrent={productPagination.currentPage}
                                current={productPagination.currentPage}
                                showSizeChanger
                                defaultPageSize={productPagination.pageSize}
                            />}>
                    </PageBottom>
                </TabPane>

                <TabPane tab="原始工单" key="3">
                    <SearchArea>
                        <Form className="ant-advanced-search-form">
                            <Row gutter={24}>
                                <Col span={6}>
                                    <FormItem label={"报修日期"}>
                                        <RangePicker
                                            value={[oriOrderSearch.makeAtStart === "" ? null : moment(oriOrderSearch.makeAtStart),
                                            oriOrderSearch.makeAtEnd === "" ? null : moment(oriOrderSearch.makeAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setOriOrderSearch({
                                                        ...oriOrderSearch,
                                                        makeAtStart: "",
                                                        makeAtEnd: "",
                                                    })
                                                } else {
                                                    setOriOrderSearch({
                                                        ...oriOrderSearch,
                                                        makeAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        makeAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"车牌号码"}>
                                        <Input placeholder={"请输入车牌号"}
                                            value={oriOrderSearch.plate}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    plate: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={6}>
                                    <FormItem label={"工单类型"}>
                                        <Input placeholder={"请输入工单类型"}
                                            value={oriOrderSearch.type}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    type: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"客户名称"}>
                                        <Input placeholder={"请输入客户名称"}
                                            value={oriOrderSearch.customerName}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    customerName: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>

                                <Col span={6}>
                                    <FormItem label={"结算日期"}>
                                        <RangePicker
                                            value={[oriOrderSearch.clearingAtStart === "" ? null : moment(oriOrderSearch.clearingAtStart),
                                            oriOrderSearch.clearingAtEnd === "" ? null : moment(oriOrderSearch.clearingAtEnd)]}
                                            onChange={(val) => {
                                                common.consoleLog(val)
                                                if (val === null) {
                                                    setOriOrderSearch({
                                                        ...oriOrderSearch,
                                                        clearingAtStart: "",
                                                        clearingAtEnd: "",
                                                    })
                                                } else {
                                                    setOriOrderSearch({
                                                        ...oriOrderSearch,
                                                        clearingAtStart: val[0] === null ? null : moment(val[0]).format("YYYY-MM-DD") + " 00:00:00",
                                                        clearingAtEnd: val[1] === null ? null : moment(val[1]).format("YYYY-MM-DD") + " 23:59:59",
                                                    })
                                                }
                                            }} />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"维修工号"}>
                                        <Input placeholder={"请输入维修工号"}
                                            value={oriOrderSearch.repairCode}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    repairCode: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"车架号码"}>
                                        <Input placeholder={"请输入车架号码"}
                                            value={oriOrderSearch.vin}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    vin: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"联系电话"}>
                                        <Input placeholder={"请输入联系电话"}
                                            value={oriOrderSearch.bothVin}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    bothVin: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"经营主体"}>
                                        <Input placeholder={"请输入经营主体"}
                                            value={oriOrderSearch.companyName}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    companyName: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem label={"服务顾问"}>
                                        <Input placeholder={"请输入服务顾问"}
                                            value={oriOrderSearch.employeeName}
                                            onChange={(e) => {
                                                setOriOrderSearch({
                                                    ...oriOrderSearch,
                                                    employeeName: e.target.value.trim()
                                                })
                                            }}
                                            onPressEnter={() => {setOriOrderPagination({...oriOrderPagination, currentPage: 1}); setOriOrderQuery(oriOrderSearch)}}
                                        />
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <div className={'search-btns'}>
                                        <Button icon={<SearchOutlined />} type="default" onClick={() => {
                                            setOriOrderPagination({...oriOrderPagination, currentPage: 1})
                                            setOriOrderQuery(oriOrderSearch)
                                        }}>搜索</Button>
                                        <Button icon={<ReloadOutlined />} onClick={() => {
                                            setOriOrderPagination({...oriOrderPagination, currentPage: 1})
                                            setOriOrderSearch(initialOriOrderSearch)
                                            setOriOrderQuery(initialOriOrderSearch)
                                        }}>清空</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </SearchArea>
                    <Table
                        columns={oriOrderColumns}
                        pagination={false}
                        scroll={{x: '100%'}}
                        rowKey={record => record.id}
                        dataSource={oriOrders}
                    />
                    <PageBottom className={"page-bottom-pagination"}
                        pagination={
                            <Pagination
                                pageSizeOptions={['10', '20', '40', '80']}
                                onChange={handleOriOrderPageChange}
                                onShowSizeChange={handleOriOrderPageChange}
                                showQuickJumper={true}
                                showTotal={total => `共${total}条`}
                                total={oriOrderTotal}
                                defaultCurrent={oriOrderPagination.currentPage}
                                current={oriOrderPagination.currentPage}
                                showSizeChanger
                                defaultPageSize={oriOrderPagination.pageSize}
                            />}>
                    </PageBottom>
                </TabPane>
            </Tabs>


        </React.Fragment>
    )

}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OldRepairItemList)