import React, {useEffect, useState} from 'react';
import {PlusOutlined, ReloadOutlined, SaveOutlined} from '@ant-design/icons';
import {Button, Row, Col, Select, Form, Input, Modal, Pagination, Tabs, Table} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import {connect} from "react-redux";
import common from "../../../../utils/common"
import {Term} from "../../../../components/crm/config";
import {SupportSetting} from "../../../../components/support/config";

const FormItem = Form.Item

function RepairConfig() {
    const [form] = Form.useForm()
    let [visible, setVisible] = useState(false) // 模态框是否显示
    const [tabActive, setTabActive] = useState('vehicleRescueArea')
    const [visibleObj, setVisibleObj] = useState({})

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 150,
            ellipsis: true,
        },
        {
            title: '编号',
            dataIndex: 'code',
            width: 150,
            ellipsis: true,
        },
        {
            title: '值',
            dataIndex: 'name',
            width: 200,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text, record) => (
                record.status in Term.StatusAlias ? Term.StatusAlias[record.status] : record.status
            )
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 150,
            ellipsis: true,
            valueType: 'date',
        },
        {
            title: '操作',
            align: "center",
            dataIndex: 'action',
            width: 150,
            ellipsis: true,
            render: (text, record) => (
                <div className={"action-btns"}>
                    <Button type="link" onClick={() => {
                        setVisible(true)
                        setVisibleObj(record)
                        form.setFieldsValue(record)
                    }}>编辑</Button>
                </div>
            ),
        },
    ]


    const [dataTree, setDataTree] = useState({
        vehicleRescueArea: [],
        repairVehicleFeedbackArea: [],
        vehicleClaimFollowUpType: [],
        vehicleClaimDefeatType: [],
        vehicleInspectionCategory: [],
        vehicleClaimChannel: [],
        vehicleInspectionProjectCategory: [],
        vehicleInspectionPart: [],
    })
    const onGetData = async (target) => {
        let tabs = ['vehicleRescueArea', 'repairVehicleFeedbackArea', 'vehicleClaimFollowUpType', 'vehicleClaimDefeatType', 'vehicleInspectionCategory', 'vehicleClaimChannel', 'vehicleInspectionProjectCategory', 'vehicleInspectionPart']
        if (target) {
            tabs = [target]
        }
        common.loadingStart();
        let obj = {...dataTree}
        for (let i = 0;i < tabs.length;i++) {
            await common.ajax("get", "/support/setting/getList", {
                ownerId: common.getUser().company.id,
                taxonomyList: tabs[i]
            }).then((data) => {
                obj[tabs[i]] = data || []
            }).finally(() => {
                common.loadingStop()
            })
        }

        setDataTree({...obj})
    }

    useEffect(() => {
        onGetData()
    }, [])

    return (
        <div className={"wms"}>
            <PageTop title={'快修配置'}>
                {
                    tabActive !== 'vehicleInspectionProject' && <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => {
                            setVisible(true)
                            setVisibleObj({
                                ownerId: common.getUser().company.id,
                                taxonomy: tabActive,
                                flag: SupportSetting.YES
                            })
                            form.setFieldsValue({
                                name: '',
                                status: ''
                            })
                        }}>新增</Button>
                }
            </PageTop>
            <Tabs defaultActiveKey="vehicleRescueArea" onChange={e => {
                setTabActive(e)
            }}>
                <Tabs.TabPane tab="救援-区域" key="vehicleRescueArea">
                    <Table
                        dataSource={dataTree.vehicleRescueArea}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="试车-路线" key="repairVehicleFeedbackArea">
                    <Table
                        dataSource={dataTree.repairVehicleFeedbackArea}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="跟进类型" key="vehicleClaimFollowUpType">
                    <Table
                        dataSource={dataTree.vehicleClaimFollowUpType}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="战败原因" key="vehicleClaimDefeatType">
                    <Table
                        dataSource={dataTree.vehicleClaimDefeatType}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="出险渠道" key="vehicleClaimChannel">
                    <Table
                        dataSource={dataTree.vehicleClaimChannel}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="预检类型" key="vehicleInspectionCategory">
                    <Table
                        dataSource={dataTree.vehicleInspectionCategory}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="预检单类型" key="vehicleInspectionProjectCategory">
                    <Table
                        dataSource={dataTree.vehicleInspectionProjectCategory}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="预检单部位" key="vehicleInspectionPart">
                    <Table
                        dataSource={dataTree.vehicleInspectionPart}
                        pagination={false}
                        columns={columns}
                        scroll={{x: '100%', y: '435px'}}
                        rowKey='id'
                    />
                </Tabs.TabPane>
            </Tabs>

            <Modal
                maskClosable={false}
                visible={visible}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {
                    visible && <>
                        <br />
                        <PageTop title={visibleObj.id ? '修改' : '新增'}>
                            <Button
                                icon={<SaveOutlined />}
                                type={"primary"}
                                onClick={() => form.submit()}
                            >保存</Button>
                        </PageTop>
                        <Form
                            form={form}
                            onFinish={(e) => {
                                common.loadingStart();
                                common.ajax("post", `/support/setting${visibleObj.id ? '/updateById' : ''}`, {
                                    ...visibleObj,
                                    ...e
                                }).then((data) => {
                                    common.toast("修改成功")
                                    // 获取列表
                                    onGetData(tabActive)
                                    setVisible(false)

                                }).finally(() => {
                                    common.loadingStop()
                                })
                            }}
                            name={'form-data'}
                            className={'ant-advanced-inline-form'}
                            initialValues={visibleObj}
                        >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItem
                                        rules={[
                                            {required: true, message: '请输入名称'}
                                        ]}
                                        name={'name'}
                                        label={"名称"}>
                                        <Input></Input>
                                    </FormItem>
                                </Col>
                                <Col span={12}>
                                    <FormItem
                                        rules={[{required: true, message: '请选择状态'}]}
                                        name={'status'}
                                        label={"状态"}>
                                        <Select>
                                            <Select.Option value={Term.TERM_STATUS_ENABLE}
                                                key={Term.TERM_STATUS_ENABLE}>{Term.StatusAlias[Term.TERM_STATUS_ENABLE]}</Select.Option>
                                            <Select.Option value={Term.TERM_STATUS_DISABLE}
                                                key={Term.TERM_STATUS_DISABLE}>{Term.StatusAlias[Term.TERM_STATUS_DISABLE]}</Select.Option>
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </>
                }

            </Modal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RepairConfig)