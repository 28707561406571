import React, {useEffect, useState} from 'react';
import {CloudUploadOutlined, DownloadOutlined, PlusOutlined, ReloadOutlined, SearchOutlined} from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Input, Menu,
    Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Table,
    TreeSelect
} from "antd";
import SearchArea from "../../../components/layout/SearchArea";
import PageTop from "../../../components/layout/PageTop";
import PageBottom from "../../../components/layout/PageBottom";
import FormVisible from "../../../components/wms/ProductGoodsCreateForm/companyForm";
import FormRate from "../../../components/wms/ProductGoodsCreateForm/companyRateUpdateForm";
import ExportButton from "../../export/index";
import FormPosition from "../../../components/wms/ProductGoodsCreateForm/companyPositionUpdateForm";
import {connect} from "react-redux";
import common from "../../../utils/common";
import {Link} from "react-router-dom";
import LookupBrand from "../LookupBrand";
import {Basic, Brand, Product, Term, wmsPublic, WmsReport} from "../config";
import moment from "moment";
import Search from "../../../utils/search";
import {CustomizationConstant} from '../../Customization/config';

const {RangePicker} = DatePicker;
const {Option} = Select;
const FormItem = Form.Item

function PartsArc(props) {
    // isCompany false(集团 默认) true(公司)
    let {isCompany = false} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 15,
    })

    let [list, setList] = useState([]) // 列表数据
    let [defaultCheckedBrands, setDefaultCheckedBrands] = useState([]) // 默认的品牌
    let [visible, setVisible] = useState(false)
    let [visible2, setVisible2] = useState(false)
    let [product, setProduct] = useState({})
    let [modalNumber, setModalNumber] = useState(0)
    let [brand, setBrand] = useState([]);

    // 搜索条件
    let initialSearch = {
        status: 0,
        createdAtStart: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        createdAtEnd: moment().format("YYYY-MM-DD") + " 23:59:59",
        code: "",
        name: "",
        number: "",
        isHaltProduction: "",
        termId1: "",
        termId2: "",
        productCategoryId: "",
        positionName: "",
        brandIds: isCompany ? brand.map(item => item.id) : [],
        kind: Product.KIND_GOODS,
        ownerId: isCompany ? common.getUser().company.id : ""
    }
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) //搜索输入的内容
    let [query, setQuery] = useState(Search.getParams() === null ? initialSearch : Search.getParams())   //提交ajax搜索值
    let [treeData3, setTreeData3] = useState([])
    let [treeData2, setTreeData2] = useState([])
    let [treeData1, setTreeData1] = useState([])
    const columnsCompany = [
        {
            title: '配件编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '配件名称',
            dataIndex: 'name',
            width: 250,
            ellipsis: true
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            width: 100,
            ellipsis: true
        },
        {
            title: '零售价',
            dataIndex: 'priceOne',
            width: 100,
            ellipsis: true,
            render: (text) => text === '0' ? '' : common.numberFormat(text),
            align: 'right'
        },
        {
            title: '索赔价',
            dataIndex: 'priceThree',
            width: 100,
            ellipsis: true,
            render: (text) => text === '0' ? '' : common.numberFormat(text),
            align: 'right'
        },
        {
            title: '保险价',
            dataIndex: 'priceTwo',
            width: 100,
            ellipsis: true,
            render: (text) => text === '0' ? '' : common.numberFormat(text),
            align: 'right'
        },
        {
            title: '精品价',
            dataIndex: 'priceFour',
            width: 100,
            ellipsis: true,
            render: (text) => text === '0' ? '' : common.numberFormat(text),
            align: 'right'
        },
        {
            title: '最新进价',
            dataIndex: 'lastReceiptPrice',
            width: 100,
            ellipsis: true,
            render: (text) => text === null ? '' : common.numberFormat(text),
            align: 'right'
        },
        {
            title: '当前库存',
            dataIndex: 'skuQuantity',
            width: 100,
            ellipsis: true,
            render: (text) => text === null ? '0.00' : common.numberFormat(text),
            align: 'right'
        },
        {
            title: '品牌',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '适用车型',
            dataIndex: 'applicableModels',
            width: 100,
            ellipsis: true,
        },
        {
            title: '施工否',
            dataIndex: 'work',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '保养材料',
            dataIndex: 'material',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) =>
                text in Product.StatusAlias ? Product.StatusAlias[text] : text
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true
        },
        {
            title: '集团管理分类',
            dataIndex: ['terms', Term.TAXONOMY_ONE, 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '集团统计分类',
            dataIndex: ['terms', Term.TAXONOMY_TWO, 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '配件管理分类',
            dataIndex: ['terms', Term.PRODUCT_CATEGORY, 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '质保期/天',
            dataIndex: "warranty",
            width: 150,
            ellipsis: true
        },
        {
            title: '是否停产',
            dataIndex: 'haltProduction',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '来源渠道',
            dataIndex: 'source',
            width: 100,
            ellipsis: true
        },
        {
            title: '配件管理分类',
            dataIndex: ['terms', Term.PRODUCT_CATEGORY, 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 100,
            ellipsis: true
        },
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
        {
            title: '操作',
            fixed: "right",
            width: isCompany ? 150 : 120,
            align: 'center',
            ellipsis: true,
            render: (text, record) => (
                <div className={"action-btns"}>
                    {record.tenantId !== record.ownerId ?
                        <>
                            <Dropdown
                                overlay={
                                    <Menu onClick={(val) => {
                                        if (val.key === '1') {
                                            common.confirm("确定要禁用吗?", () => {
                                                updateStatus(record.id)
                                            })
                                        } else if (val.key === '2') {
                                            common.confirm("确定要调整配件的归属吗?", () => {
                                                updateOwnerId(record)
                                            })
                                        } else if (val.key === '3') {
                                            getBrandVisible(record)
                                        }
                                    }}>
                                        {record.status !== Product.STATUS_NONE && <Menu.Item key="1">禁用</Menu.Item>}
                                        <Menu.Item key="2">调整归属</Menu.Item>
                                        <Menu.Item key="3">适用品牌</Menu.Item>
                                    </Menu>
                                }>
                                <Button style={{color: '#1890ff'}}>
                                    操作
                                </Button>
                            </Dropdown>
                            <Link to={`/wms/goods/${record.id}`}>编辑</Link>
                        </>
                        :
                        <>
                            <Button type={'link'} onClick={() => {
                                setProduct(record)
                                setModalNumber(2)
                                setVisible2(true)
                            }}>加价率</Button>
                            <Button type={'link'} onClick={() => {
                                setProduct(record)
                                setModalNumber(3)
                                setVisible2(true)
                            }}>仓位</Button>
                            <Button type={'link'} disabled={true}>编辑</Button>
                        </>
                    }
                </div>)
        }
    ]
    const columnsTenant = [
        {
            title: '配件编号',
            dataIndex: 'number',
            width: 200,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: '配件名称',
            dataIndex: 'name',
            width: 250,
            ellipsis: true
        },
        {
            title: '适用品牌',
            dataIndex: 'brandName',
            width: 150,
            ellipsis: true,
        },
        {
            title: '适用车型',
            dataIndex: 'applicableModels',
            width: 100,
            ellipsis: true,
        },
        {
            title: '施工否',
            dataIndex: 'work',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '保养材料',
            dataIndex: 'material',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '状态',
            dataIndex: 'status',
            width: 100,
            ellipsis: true,
            render: (text) =>
                text in Product.StatusAlias ? Product.StatusAlias[text] : text
        },
        {
            title: '单位',
            dataIndex: 'unit',
            width: 100,
            ellipsis: true
        },
        {
            title: '集团管理分类',
            dataIndex: ['terms', Term.TAXONOMY_ONE, 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '集团统计分类',
            dataIndex: ['terms', Term.TAXONOMY_TWO, 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '配件管理分类',
            dataIndex: ['terms', Term.PRODUCT_CATEGORY, 'name'],
            width: 150,
            ellipsis: true
        },
        {
            title: '是否停产',
            dataIndex: 'haltProduction',
            width: 100,
            ellipsis: true,
            render: (text) => text in Product.YesOrNoAlias ? Product.YesOrNoAlias[text] : text
        },
        {
            title: '来源渠道',
            dataIndex: 'source',
            width: 100,
            ellipsis: true
        },
        {
            title: '备注',
            dataIndex: 'spec',
            width: 100,
            ellipsis: true
        },
        {
            title: '档案编号',
            dataIndex: 'code',
            width: 200,
            ellipsis: true
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 150,
            ellipsis: true
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            width: 120,
            ellipsis: true,
            render: (text) => text.substring(0, 11)
        },
        {
            title: '操作',
            fixed: "right",
            width: 130,
            align: 'center',
            ellipsis: true,
            render: (text, record) => (
                <div className={"action-btns"}>
                    {record.status !== Product.STATUS_NONE &&
                        <Button type="link" onClick={() => {
                            common.confirm("确定要禁用吗?", () => {
                                updateStatus(record.id)
                            })
                        }}>禁用</Button>}
                    <Button type="link" onClick={() => getBrandVisible(record)}>适用品牌</Button>
                    <Link to={`/admin/wms/goods/${record.id}`}>编辑</Link>
                </div>)
        }
    ]

    // 更改每页显示条数
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 品牌
    let getBrand = () => {
        // if (isCompany) {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: isCompany ? common.getUser().company.id : '',
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: isCompany ? data.brands.map(item => item.id) : []
            })
            if (isCompany) {
                setQuery({
                    ...query,
                    brandIds: data.brands.map(item => item.id)
                })
            }
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
        // }
    }
    useEffect(getBrand, [])

    // 获取可见性
    let getBrandVisible = (params) => {
        common.loadingStart()
        common.ajax("get", "/wms/product/findBrandListById", {
            id: params.id,
            ownerId: isCompany ? common.getUser().company.id : ''
        }).then((data) => {
            setDefaultCheckedBrands(data)
            if (isCompany) {
                setProduct({
                    ...params,
                    brands: data
                })
                setModalNumber(1)
                setVisible2(true)
            } else {
                setProduct(params)
                setVisible(true)
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改ownerId
    let updateOwnerId = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/updateOwnerId", {
            id: params.id,
            ownerId: params.ownerId
        }).then(() => {
            common.toast("调整成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改status
    let updateStatus = (productId) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/updateStatus", {
            id: productId,
        }).then(() => {
            common.toast("调整成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改可见性
    let updateBrands = (val) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/updateVisible", {
            id: product.id,
            brands: val.map(item => item.id)
        }).then(() => {
            setVisible(false)
            common.toast("修改成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改可见性
    let updateBrandsTwo = (val) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/updateVisible", val).then(() => {
            setVisible2(false)
            common.toast("修改成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改加价率
    let updateRates = (val) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/updateGeneralById", val).then(() => {
            setVisible2(false)
            common.toast("修改成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 修改仓位
    let updatePosition = (val) => {
        common.loadingStart()
        common.ajax("post", "/wms/product/updatePositionById", val).then(() => {
            setVisible2(false)
            common.toast("修改成功")
            getData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取列表数据
    let getData = () => {
        if (isCompany && brand.length === 0) {
            setList([])
        } else {
            common.loadingStart()
            common.ajax("get", "/wms/product/list", {
                ...pagination,
                ...query,
            }).then((data) => {
                setList(data.products)
                setTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    // 通过Taxonomy获取分类信息
    let getCategory = (taxonomy) => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: taxonomy,
            status: Term.TERM_STATUS_ENABLE,
            ownerId: isCompany ? common.getUser().company.id : "",
        }).then(data => {
            if (taxonomy === Term.TAXONOMY_ONE) {
                setTreeData1(wmsPublic.getTree(data.terms, ""))
            }
            if (taxonomy === Term.TAXONOMY_TWO) {
                setTreeData2(wmsPublic.getTree(data.terms, ""))
            }
            if (taxonomy === Term.PRODUCT_CATEGORY) {
                setTreeData3(wmsPublic.getTree(data.terms, ""))
            }
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getData, [pagination, query, brand, isCompany])

    useEffect(() => {
        getCategory(Term.TAXONOMY_ONE)
    }, [])

    useEffect(() => {
        getCategory(Term.TAXONOMY_TWO)
    }, [])

    useEffect(() => {
        // if (isCompany) {
        getCategory(Term.PRODUCT_CATEGORY)
        // }
    }, [isCompany])

    return (
        <>
            <PageTop title={'配件档案管理'}>
                <Button type={"primary"}>
                    <Link to={isCompany ? "/wms/goods/create" : "/admin/wms/goods/create"}>
                        <PlusOutlined />
                        <span> 新增</span>
                    </Link>
                </Button>
                {!isCompany && common.can("admin.wms.product.part.import") &&
                    <Button>
                        <Link to={'/admin/wms/goods/import'}>
                            <CloudUploadOutlined />
                            <span> 数据导入</span>
                        </Link>
                    </Button>}
                {isCompany && common.can("wms.product.part.import") &&
                    <Button>
                        <Link to={'/wms/goods/importData'}>
                            <CloudUploadOutlined />
                            <span> 数据导入</span>
                        </Link>
                    </Button>}
                {isCompany && common.can('wms.product.part.setting') &&
                    <Button>
                        <Link to={'/wms/goods/import'}>
                            <CloudUploadOutlined />
                            <span> 数据更新</span>
                        </Link>
                    </Button>}
                <ExportButton
                    filename={'配件档案.xlsx'}
                    buttonName={isCompany ? '导出档案' : '导出集团档案'}
                    ownerId={search.ownerId}
                    params={JSON.stringify(query)}
                    type={isCompany ? WmsReport.WMS_REPORT_PRODUCT : WmsReport.WMS_REPORT_PRODUCT_TENANT}
                />
                <Button>
                    <Link to={'/wms/goods/batchUpdateOwner'}>
                        <CloudUploadOutlined />
                        <span> 批量调整归属和适用品牌</span>
                    </Link>
                </Button>
            </PageTop>
            <SearchArea>
                <Form className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        {isCompany &&
                            <Col span={6}>
                                <FormItem
                                    label={"存放仓位"}
                                >
                                    <Input value={search.positionName} onChange={(e) => {
                                        setSearch({...search, positionName: e.target.value})
                                    }} />
                                </FormItem>
                            </Col>}
                        <Col span={6}>
                            <FormItem label={"配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }} />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={isCompany ? 'label-character-5' : ''}
                                label="集团管理分类">
                                <TreeSelect
                                    value={search.termId1}
                                    onChange={(val) => {
                                        setSearch({...search, termId1: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"状态"} className={'label-character-3'}>
                                <Select value={search.status} onChange={(val) => {
                                    setSearch({...search, status: val})
                                }}>
                                    <Option
                                        value={Product.STATUS_ALL}
                                        key={Product.STATUS_ALL}>{Product.StatusAlias[Product.STATUS_ALL]}</Option>
                                    {/*单纯仅售后可用 status = Product.STATUS_AFTER_SALE*/}
                                    <Option
                                        value={100}
                                        key={100}>{Product.StatusAlias[Product.STATUS_AFTER_SALE]}</Option>
                                    <Option
                                        value={Product.STATUS_NONE}
                                        key={Product.STATUS_NONE}>{Product.StatusAlias[Product.STATUS_NONE]}</Option>
                                    <Option value={initialSearch.status} key={initialSearch.status}>全部</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"创建日期"}>
                                <RangePicker
                                    suffixIcon={null}
                                    value={[
                                        search.createdAtStart !== "" ? moment(search.createdAtStart) : null,
                                        search.createdAtEnd !== "" ? moment(search.createdAtEnd) : null
                                    ]}
                                    onChange={(date, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                        })
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"配件名称"}>
                                <Input value={search.name} onChange={(e) => {
                                    setSearch({...search, name: e.target.value})
                                }} />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className={isCompany ? 'label-character-5' : ''}
                                label="集团统计分类">
                                <TreeSelect
                                    value={search.termId2}
                                    onChange={(val) => {
                                        setSearch({...search, termId2: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData2}
                                    treeDefaultExpandAll
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"是否停产"} className={'label-character-3'}>
                                <Radio.Group value={search.isHaltProduction} onChange={(e) => {
                                    setSearch({...search, isHaltProduction: e.target.value})
                                }}>
                                    <Radio
                                        value={Basic.IS_ANSWER_YES}
                                        key={Basic.IS_ANSWER_YES}>{Basic.YesOrNoAlias[Basic.IS_ANSWER_YES]}</Radio>
                                    <Radio
                                        value={Basic.IS_ANSWER_NO}
                                        key={Basic.IS_ANSWER_NO}>{Basic.YesOrNoAlias[Basic.IS_ANSWER_NO]}</Radio>
                                    <Radio value={initialSearch.isHaltProduction}
                                        key={initialSearch.isHaltProduction}>全部</Radio>
                                </Radio.Group>
                            </FormItem>
                        </Col>
                        {
                            !isCompany && <Col span={6}>
                                <FormItem label={"适用车型"}>
                                    <Input value={search.applicableModels} onChange={(e) => {
                                        setSearch({...search, applicableModels: e.target.value})
                                    }} />
                                </FormItem>
                            </Col>
                        }
                        <Col span={isCompany ? 12 : 6}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds}
                                        onChange={(val) => {
                                            setSearch({...search, brandIds: val})
                                        }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={''}>
                                        <Option key={''} value={''}>全部</Option>
                                    </Select>
                                }
                            </Form.Item>
                        </Col>
                        {
                            (isCompany || CustomizationConstant.ACCESSORY_ORDER_TENANT_PRODUCT_ALLOW_PRODUCT_CATEGORY.includes(common.getUser().tenant.id)) && <Col span={6}>
                                <Form.Item
                                    className={isCompany ? 'label-character-5' : ''}
                                    label="配件管理分类">
                                    <TreeSelect
                                        value={search.productCategoryId}
                                        onChange={(val) => {
                                            setSearch({...search, productCategoryId: val})
                                        }}
                                        allowClear={true}
                                        style={{width: '100%'}}
                                        dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                        treeData={treeData3}
                                        treeDefaultExpandAll
                                    />
                                </Form.Item>
                            </Col>
                        }
                        <Col span={6}>
                            <FormItem label={"档案编号"} className={'label-character-3'} style={{paddingLeft: !isCompany && !CustomizationConstant.ACCESSORY_ORDER_TENANT_PRODUCT_ALLOW_PRODUCT_CATEGORY.includes(common.getUser().tenant.id) ? '20px' : '0px'}}>
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }} />
                            </FormItem>
                        </Col>
                        {
                            isCompany && <Col span={6}>
                                <FormItem label={"适用车型"}>
                                    <Input value={search.applicableModels} onChange={(e) => {
                                        setSearch({...search, applicableModels: e.target.value})
                                    }} />
                                </FormItem>
                            </Col>
                        }
                        <Col span={isCompany ? 18 : (CustomizationConstant.ACCESSORY_ORDER_TENANT_PRODUCT_ALLOW_PRODUCT_CATEGORY.includes(common.getUser().tenant.id) ? 6 : 12)}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                    Search.setParams(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    // 重置在第一页
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            <Table
                columns={isCompany ? columnsCompany : columnsTenant}
                dataSource={list}
                scroll={{x: '100%', y: '410px'}}
                pagination={false}
                rowKey="id"
            />
            <PageBottom
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        pageSizeOptions={['15', '30', '50', '100']}
                        defaultPageSize={pagination.limit}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                    />
                }>
            </PageBottom>
            <Modal
                maskClosable={false}
                visible={visible}
                title={"配件/精品 适用品牌"}
                width={800}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                <LookupBrand
                    isMultiple={true}
                    isCompany={isCompany}
                    defaultCheckedIds={defaultCheckedBrands}
                    onOk={updateBrands} />
            </Modal>
            <Modal
                maskClosable={false}
                visible={visible2}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                    <FormVisible defaultValue={product} onOk={updateBrandsTwo} />}
                {modalNumber === 3 &&
                    <FormPosition defaultValue={product} onOk={updatePosition} />}
                {modalNumber === 2 &&
                    <FormRate defaultValue={product} onOk={updateRates} />}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PartsArc);