import React, {useState} from "react"
import {Button, Form} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import PageTop from "../../../components/layout/PageTop";
import {connect} from "react-redux";
import Import from "../../../components/import";


function Vehicle() {
    return (
        <div>
            <PageTop title="维修记录导入">
                <Button type={'primary'}
                    icon={<DownloadOutlined />}
                    onClick={() => {
                        let link = document.createElement('a')
                        link.setAttribute("download", "")
                        link.href = "/excel/repairItemOrder.xlsx"
                        link.click()
                    }}>下载模板
                </Button>
            </PageTop>

            <Import ownerId={""} type={"support_old_repair"} canChangeCompany={true} />

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicle)
