import React, {useEffect, useState} from 'react'
import {
    CloseOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SearchOutlined,
    SelectOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Pagination,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import SearchArea from "../../../../components/layout/SearchArea";
import PageBottom from "../../../../components/layout/PageBottom";
import common from "../../../../utils/common";
import Search from "../../../../utils/search";
import {render} from 'less';
import BigNumber from 'bignumber.js';
import AssessmentForm from '../../../../components/support/AssessmentForm';

const FormItem = Form.Item



function AssessmentList(props) {

    let initialSearch = {
        repairId: "",
        ownerId: common.getUser().company.id,
        insuranceCompanyName: '',
        customerName: '',
        vehicleName: '',
        plate: '',
        vin: '',
        spec: '',
        repairCode: '',
    }
    let [pagination, setPagination] = useState({page: 1, limit: 15})
    let [search, setSearch] = useState(Search.getParams() === null ? initialSearch : Search.getParams()) // 搜索输入的内容
    let [total, setTotal] = useState(0)     // 总记录条数
    let [repairs, setRepairs] = useState([])    // 维修工单列表
    const [assessmentId, setAppointmentId] = useState('')


    // 页码发生变化就请求数据
    let getRepairs = () => {
        common.loadingStart()
        let params = {
            ...search,
            ...pagination,
        }
        common.ajax('get', '/support/insurance/claims/page', params).then(res => {
            setTotal(res.pagination.total)
            if (res.list.length > 0) {
                setRepairs([...res.list])
            } else {
                setRepairs([...res.list])
            }
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(() => {
        getRepairs()
    }, [pagination])


    // 当前页码改变或每页数量改变
    let handlePageChange = (page, limit) => {
        setPagination({page, limit})
    }


    // 维修工单列
    let columns = [
        {
            title: '序号',
            key: 'index',
            dataIndex: 'index',
            width: 80,
            fixed: 'left',
            render: (text, record, index) => `${record.index != '小计' ? index + 1 : record.index}`,
        },
        {
            title: '维修单号',
            width: 120,
            dataIndex: ['repairDto', 'code'],
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '车牌号',
            width: 120,
            dataIndex: ['repairDto', 'plate'],
            ellipsis: true,
            fixed: 'left',
        },
        {
            title: '车架号',
            width: 120,
            dataIndex: ['repairDto', 'vin'],
            ellipsis: true,
        },
        {
            title: '品牌',
            width: 120,
            dataIndex: ['repairDto', 'brandName'],
            ellipsis: true,
        },
        {
            title: '车系',
            width: 120,
            dataIndex: ['repairDto', 'seriesName'],
            ellipsis: true,
        },
        {
            title: '车型',
            width: 120,
            dataIndex: ['repairDto', 'productName'],
            ellipsis: true,
        },
        {
            title: '服务顾问',
            width: 120,
            dataIndex: ['repairDto', 'employeeName'],
            ellipsis: true,
        },
        // {
        //     title: '理赔照片',
        //     width: 120,
        //     dataIndex: 'imageUrlList',
        //     ellipsis: true,
        //     render: (text, record) => {
        //         return text.length
        //     }
        // },
        // {
        //     title: '理赔文件',
        //     width: 120,
        //     dataIndex: 'pdfUrlList',
        //     ellipsis: true,
        //     render: (text, record) => {
        //         return text.length
        //     }
        // },
        {
            title: '定损工时',
            width: 120,
            dataIndex: 'projectCost',
            ellipsis: true,
        },
        {
            title: '定损材料',
            width: 120,
            dataIndex: 'materialCost',
            ellipsis: true,
        },
        {
            title: '其他费用',
            width: 120,
            dataIndex: 'otherCost',
            ellipsis: true,
        },
        {
            title: '定损金额',
            width: 120,
            dataIndex: 'vin',
            ellipsis: true,
            render: (text, record) => {
                return new BigNumber(0).plus(record.projectCost).plus(record.materialCost).plus(record.otherCost).toFormat(2)
            }
        },
        {
            title: '维修净产值',
            width: 120,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '差额',
            width: 120,
            dataIndex: '',
            ellipsis: true,
        },
        {
            title: '保险公司1',
            width: 120,
            dataIndex: 'insuranceCompanyName',
            ellipsis: true,
        },
        {
            title: '定损员',
            width: 120,
            dataIndex: 'insuranceCompanyId',
            ellipsis: true,
        },
        // {
        //     title: '保险公司2',
        //     width: 120,
        //     dataIndex: 'vin',
        //     ellipsis: true,
        // },
        // {
        //     title: '定损员',
        //     width: 120,
        //     dataIndex: 'vin',
        //     ellipsis: true,
        // },
        {
            title: '定损备注',
            width: 120,
            dataIndex: 'spec',
            ellipsis: true,
        },
        {
            title: '操作',
            width: 100,
            align: 'center',
            ellipsis: true,
            fixed: 'right',
            render: (text, record) => {
                return (
                    <div>
                        <Space>
                            <a onClick={() => {
                                setAppointmentId(record.id)
                            }}>编辑</a>
                            <a onClick={() => {
                                common.confirm("确认要删除吗?", () => {
                                    common.loadingStart()
                                    common.ajax("post", `/support/insurance/claims/delete`, {
                                        ownerId: common.getUser().company.id,
                                        id: record.id
                                    })
                                        .then(res => {
                                            common.toast("删除成功")
                                            setPagination({...pagination, page: 1})
                                        })
                                        .finally(common.loadingStop)
                                })
                            }}>删除</a>
                        </Space>
                    </div>
                )
            }
        },
    ]


    return (
        <React.Fragment>
            <PageTop title={'定损查询'}>

            </PageTop>

            <SearchArea>
                <Form className="ant-advanced-search-form">
                    <Row gutter={24}>
                        <Col span={6}>
                            <FormItem label={"维修单号"}>
                                <Input placeholder={"请输入维修单号"} value={search.repairCode} onChange={(e) => {
                                    setSearch({...search, repairCode: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        Search.setParams(search)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"保险公司"}>
                                <Input placeholder={"请输入保险公司"} value={search.insuranceCompanyName} onChange={(e) => {
                                    setSearch({...search, insuranceCompanyName: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        Search.setParams(search)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"客户名称"}>
                                <Input placeholder={"请输入客户名称"} value={search.customerName} onChange={(e) => {
                                    setSearch({...search, customerName: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        Search.setParams(search)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"定损备注"}>
                                <Input placeholder={"请输入定损备注"} value={search.spec} onChange={(e) => {
                                    setSearch({...search, spec: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        Search.setParams(search)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车牌号码"}>
                                <Input placeholder={"请输入车牌号码"} value={search.plate} onChange={(e) => {
                                    setSearch({...search, plate: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        Search.setParams(search)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={6}>
                            <FormItem label={"车架号码"}>
                                <Input placeholder={"请输入车架号码"} value={search.vin} onChange={(e) => {
                                    setSearch({...search, vin: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setPagination({...pagination, page: 1})
                                        Search.setParams(search)
                                    }}
                                />
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} type="default" onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    Search.setParams(search)
                                }}>搜索
                                </Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setSearch({...initialSearch})
                                    Search.clearParams()
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                columns={columns}
                pagination={false}
                scroll={{x: '100%'}}
                rowKey={record => record.id}
                dataSource={repairs}
            />

            <PageBottom
                // children={<div>
                //     <span>台数：-</span>
                //     <Divider type="vertical" />
                //     <span>客户数：-</span>
                // </div>}
                className={"page-bottom-pagination"}
                pagination={
                    <Pagination
                        pageSizeOptions={['15', '30', '50', '100']}
                        onChange={handlePageChange}
                        onShowSizeChange={handlePageChange}
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        defaultCurrent={pagination.page}
                        current={pagination.page}
                        showSizeChanger
                        defaultPageSize={pagination.limit}
                    />
                }
            />

            <Modal
                visible={assessmentId !== ''}
                destroyOnClose={true}
                maskClosable={false}
                width={1000}
                footer={null}
                onCancel={() => {
                    setAppointmentId('')
                }}>
                <br />
                <AssessmentForm id={assessmentId} onOk={(e) => {
                    setAppointmentId('')
                    if (e) {
                        setPagination({...pagination, page: 1})
                    }
                }} />
            </Modal>

        </React.Fragment>
    )
}


export default AssessmentList
