import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom"
import {Button, Form, Row, Col, DatePicker, Input, Select, Table, Pagination, Modal} from 'antd'
import {PlusOutlined, SearchOutlined, ReloadOutlined, DownloadOutlined, SelectOutlined} from '@ant-design/icons'
import PageTop from '../../../../components/layout/PageTop'
import SearchArea from '../../../../components/layout/SearchArea'
import PageBottom from '../../../../components/layout/PageBottom'
import common from "../../../../utils/common"
import moment from 'moment'
import SearchList from './search'
import FakeRepairEdit from './edit'
import PrepayForm from '../../../../components/support/PrepayForm'
import BigNumber from 'bignumber.js'

function FakeRepairList(props) {
    const [form] = Form.useForm()
    const [formDialog] = Form.useForm()
    const initParams = {
        name: "",
        plate: "",
        vin: "",
        checkStatus: "",
        settlementAtStart: "",
        settlementAtEnd: "",
        makeAtStart: "",
        makeAtEnd: "",
    }
    const [searchParams, setSearchParams] = useState(initParams)
    const [dialogTodo, setDialogTodo] = useState('')
    const [dialogData, setDialogData] = useState({})
    const [dataList, setDataList] = useState([])
    const [total, setTotal] = useState(0)
    const [pagination, setPagination] = useState({page: 1, limit: 15, })

    const onSearch = (vals) => {
        // 处理undefined参数
        Object.keys(vals).map(key => {
            if (vals[key] === undefined) {
                vals[key] = ''
            }
        })
        // 合并默认参数和表单参数
        vals = {...searchParams, ...vals}
        setSearchParams(vals)
        setPagination({
            ...pagination,
            page: 1
        })
    }
    const onGetData = async () => {
        const res = await common.ajax('get', '/support/print/page', {
            businessId: props && props.repairId || '',
            spec: '',
            ...pagination,
            ownerId: common.getUser().company.id,
        }).finally(common.loadingStop)
        if (res && res.list) {
            setDataList(res.list)
        }
        setTotal(res.pagination.total)
    }
    useEffect(() => {
        onGetData()
    }, [pagination])
    return (
        <>
            <PageTop title="估价单查询" >
                {
                    props.repairId && <Button icon={<PlusOutlined />} type="primary" onClick={() => {
                        setDialogTodo('add' + props.repairId)
                    }}>新增估价单</Button>
                }
            </PageTop>
            <SearchArea>
                <Form form={form} onFinish={onSearch} initialValues={searchParams} className="ant-advanced-search-form" >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="报修时间" name="makeAtRange">
                                <DatePicker.RangePicker
                                    value={[searchParams.makeAtStart ? moment(searchParams.makeAtStart) : null, searchParams.makeAtEnd ? moment(searchParams.makeAtEnd) : null]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车牌号" name="plate">
                                <Input autoComplete='off' type="text" placeholder={'输入车牌或自编号'}
                                    onPressEnter={() => {
                                        form.submit()
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户名称" name="name">
                                <Input autoComplete='off' type="text" placeholder={'输入客户名称'}
                                    onPressEnter={() => {
                                        form.submit()
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="核对状态" name="checkStatus">
                                <Select >
                                    <Select.Option value={''} key={0}>全部</Select.Option>
                                    <Select.Option value={'yes'} key={1}>已对账</Select.Option>
                                    <Select.Option value={'no'} key={2}>进行中</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="结算日期" name="settlementAtRange">
                                <DatePicker.RangePicker
                                    value={[searchParams.settlementAtStart ? moment(searchParams.settlementAtStart) : null, searchParams.settlementAtEnd ? moment(searchParams.settlementAtEnd) : null]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="车架号" name="vin">
                                <Input autoComplete='off' type="text" placeholder={'输入车架号'}
                                    onPressEnter={() => {
                                        form.submit()
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="维修单号" name="code">
                                <Input autoComplete='off' type="text" placeholder={'输入关联单号/维修单号'}
                                    onPressEnter={() => {
                                        form.submit()
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {form.submit()}}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setSearchParams(initParams)
                                    form.resetFields()
                                    setTimeout(() => {
                                        form.resetFields()
                                        setSearchParams(initParams)
                                        form.submit()
                                    }, 30)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>
            {/* 主表 */}
            <Table
                rowKey={record => record.id}
                columns={[
                    {
                        title: '序号',
                        dataIndex: 'key',
                        key: 'key',
                        width: 80,
                        ellipsis: true,
                        fixed: "left",
                        render: (text, record, index) => index + 1
                    },
                    {
                        title: '关联单号',
                        dataIndex: ['data', 'code'],
                        key: 'code',
                        ellipsis: true,
                        width: 120,
                        fixed: "left",
                        render: (text, record, index) => {
                            return <a onClick={() => {
                                setDialogTodo('look' + record.businessId)
                            }}>{text}</a>
                        }
                    },
                    {
                        title: '车牌号',
                        dataIndex: ['data', 'plate'],
                        key: 'plate',
                        ellipsis: true,
                        width: 120,
                        fixed: "left",
                    },
                    {
                        title: '报修日期',
                        dataIndex: ['data', 'makeAt'],
                        key: 'makeAt',
                        ellipsis: true,
                        width: 160,
                    },
                    {
                        title: '结算日期',
                        dataIndex: ['data', 'settmentAt'],
                        key: 'settmentAt',
                        ellipsis: true,
                        width: 160,
                    },
                    {
                        title: '维修单号',
                        dataIndex: ['data', 'code'],
                        key: 'code',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '单据类型',
                        dataIndex: ['data', 'fakeType'],
                        key: 'code',
                        ellipsis: true,
                        width: 120,
                        render: (text) => {
                            return text ? `估价单${text.substr(-1)}` : '估价单1'
                        }
                    },
                    {
                        title: '车架号',
                        dataIndex: ['data', 'vin'],
                        key: 'vin',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '车主姓名',
                        dataIndex: ['data', 'vehicleName'],
                        key: 'vehicleName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '车主电话',
                        dataIndex: ['data', 'vehiclePhone'],
                        key: 'vehiclePhone',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '送修人',
                        dataIndex: ['data', 'name'],
                        key: 'name',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '品牌',
                        dataIndex: ['data', 'brandName'],
                        key: 'brandName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '车系',
                        dataIndex: ['data', 'seriesName'],
                        key: 'seriesName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '车型',
                        dataIndex: ['data', 'productName'],
                        key: 'productName',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '上次里程数',
                        dataIndex: ['data', 'lastMileage'],
                        key: 'lastMileage',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '本次里程数',
                        dataIndex: ['data', 'mileage'],
                        key: 'mileage',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '工单类型',
                        dataIndex: ['data', 'repairCategoryName'],
                        key: 'repairCategoryName',
                        ellipsis: true,
                        width: 120,
                    },
                    // {
                    //     title: '维修项目',
                    //     dataIndex: ['data', 'repairItemNameList'],
                    //     key: 'repairItemNameList',
                    //     ellipsis: true,
                    //     width: 120,
                    // },
                    // {
                    //     title: '自动摘要',
                    //     dataIndex: ['data', 'description'],
                    //     key: 'description',
                    //     ellipsis: true,
                    //     width: 120,
                    // },
                    {
                        title: '工时费',
                        dataIndex: ['data', 'projectTotal'],
                        key: 'projectTotal',
                        ellipsis: true,
                        width: 120,
                        align: 'right'
                    },
                    {
                        title: '材料费',
                        dataIndex: ['data', 'productReceiptTotal'],
                        key: 'productReceiptTotal',
                        ellipsis: true,
                        width: 120,
                        align: 'right'
                    },
                    {
                        title: '总费用',
                        dataIndex: ['data', 'total'],
                        key: 'total',
                        ellipsis: true,
                        width: 120,
                        align: 'right'
                    },
                    {
                        title: '已优惠',
                        dataIndex: ['data', 'discountTotal'],
                        key: 'discountTotal',
                        ellipsis: true,
                        width: 120,
                        align: 'right'
                    },
                    {
                        title: '客户应付',
                        dataIndex: ['data', 'total'],
                        key: 'customerTotal',
                        ellipsis: true,
                        width: 120,
                        align: 'right',
                        render: (text, record) => {
                            return (
                                new BigNumber(record.data.total).minus(record.data.discountTotal).toFixed(2)
                            )
                        }
                    },
                    {
                        title: '自编号',
                        dataIndex: ['data', 'selfCode'],
                        key: 'selfCode',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '单据备注',
                        dataIndex: ['data', 'spec'],
                        key: 'spec',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '核对状态',
                        dataIndex: ['data', 'checkStatus'],
                        key: 'checkStatus',
                        ellipsis: true,
                        width: 120,
                        render: (text) => {
                            return text == '1' ? '已核对' : '未核对'
                        }
                    },
                    {
                        title: '核对日期',
                        dataIndex: ['data', 'checkAt'],
                        key: 'checkAt',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '核对人',
                        dataIndex: ['data', 'checkBy'],
                        key: 'checkBy',
                        ellipsis: true,
                        width: 120,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        align: 'center',
                        fixed: 'right',
                        ellipsis: true,
                        width: 100,
                        render: (text, record) => {
                            return (
                                <div className={"action-btns"}>
                                    <a onClick={() => {
                                        setDialogTodo('edit')
                                        setDialogData(record)
                                        // formDialog.setFieldsValue(record)
                                        // setDialogTodo('edit')
                                    }}>编辑</a>
                                </div>
                            )
                        }
                    }
                ]}
                dataSource={dataList}
                scroll={{x: '100%'}}
                pagination={false}
            />

            {/* 分页 */}
            <PageBottom pagination={
                <Pagination
                    showQuickJumper={true}
                    showTotal={total => `共${total}条`}
                    total={total}
                    current={pagination.page}
                    showSizeChanger
                    pageSizeOptions={['15', '30', '50', '100']}
                    defaultPageSize={pagination.limit}
                    onChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                    onShowSizeChange={(page, limit) => {
                        setPagination({page, limit})
                    }}
                />
            }></PageBottom>
            {/* 弹框 */}
            <Modal
                maskClosable={false}
                visible={dialogTodo != ''}
                width={1200}
                footer={null}
                onCancel={() => {
                    setDialogTodo('')
                    setDialogData({})
                }}
                zIndex={1000}>
                {
                    dialogTodo.indexOf('add') != -1 && <FakeRepairEdit todo="edit" repairId={dialogTodo.split('add')[1]} onOk={() => {
                        // setDialogTodo('')
                        // setDialogData({})
                        setPagination({
                            ...pagination,
                            page: 1
                        })
                    }} onBack={() => {
                        setDialogTodo('')
                        setDialogData({})
                    }} />
                }
                {
                    dialogTodo.indexOf('edit') != -1 && <FakeRepairEdit todo="edit" repairData={dialogData} onOk={() => {
                        // setDialogTodo('')
                        // setDialogData({})
                        setPagination({
                            ...pagination,
                            page: 1
                        })
                    }} onBack={() => {
                        setDialogTodo('')
                        setDialogData({})
                    }} />
                }
                {
                    dialogTodo.indexOf('look') != -1 && <PrepayForm repairId={dialogTodo.split('look')[1]} scene={"look"} onBack={() => {
                        setDialogTodo('')
                        setDialogData({})
                    }} />
                }
            </Modal>
        </>
    )
}

export default FakeRepairList