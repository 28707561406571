import React, {useEffect, useState} from 'react';
import {
    CloseOutlined,
    QuestionCircleOutlined,
    ReloadOutlined,
    SaveOutlined,
    SearchOutlined,
    SelectOutlined,
    DownloadOutlined, MinusCircleOutlined, CloudUploadOutlined, CheckSquareOutlined
} from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Table,
    Select,
    Row,
    Form,
    Modal,
    Tooltip,
    Tabs, TreeSelect, InputNumber, Pagination, Checkbox
} from "antd";
import SearchArea from "../../../../../components/layout/SearchArea";
import {connect} from "react-redux";
import common from "../../../../../utils/common";
import {
    Basic,
    Brand,
    Product,
    Stock,
    StockItem,
    Term,
    Warehouse,
    wmsPublic
} from "../../../../../components/wms/config";
import LookupProduct from "../../../../../components/wms/LookupProductGoods";
import ProductGoodsReceiptAndDelivery from "../../../../../components/wms/ProductGoodsReceiptAndDelivery";
import BigNumber from "bignumber.js";
import {Link} from "react-router-dom";
import PageBottom from "../../../../../components/layout/PageBottom";
import ExportButton from "../../../../../components/export";

function Index(props) {
    // action part(配件)  boutique(精品)
    let {action = Product.KIND_PART} = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 列表数据
    let [tabNumber, setTabNumber] = useState("")
    let [tableNumber, setTableNumber] = useState(1)
    let [list, setList] = useState([])
    let [product, setProduct] = useState({});// 配件/精品
    let [visible2, setVisible2] = useState(false)
    let [modalTitle, setModalTitle] = useState("")
    let [modalNumber, setModalNumber] = useState(0)
    let [brand, setBrand] = useState([]);// 品牌
    let [treeData1, setTreeData1] = useState([])
    let [terms, setTerms] = useState([])
    let [warehouse, setWarehouse] = useState([]) // 仓库
    let [subtotal, setSubtotal] = useState({}) // 左下角小计

    // 搜索条件
    let initialSearch = {
        number: "",
        sign: "",// 是否标记
        barCode: "",
        productId: "",
        positionName: "",
        quantity: -1,
        code: '',
        termIdsOne: [],
        statuses: [Stock.STATUS_ING, Stock.STATUS_AUDIT],
        kind: action,
        ownerId: common.getUser().company.id,
        brandIds: brand.map(item => item.id),
        canPagination: Basic.IS_ANSWER_YES,// 需要分页
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [number, setNumber] = useState('')

    const batchSetToZero = () => {
        update({
            ownerId: common.getUser().company.id,
            kind: action,
            id: list.map(item => item.id).join(','),
            actureNum: 0,
            sign: StockItem.SIGN_YES,
        });
    };

    const columns = [
        {
            title: '仓库名称',
            width: 150,
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '结存日期',
            width: 120,
            dataIndex: 'createdAt',
            ellipsis: true,
            render: (text) => text.substring(0, 10)
        },
        {
            title: '结存单号',
            width: 150,
            dataIndex: ['stockDto', 'code'],
            ellipsis: true
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: 'number',
            width: 150,
            ellipsis: true,
            render: (text) => {
                return <a
                    onClick={() => {
                        setNumber(text)
                        setModalTitle(action === Product.KIND_PART ? "配件入出库明细账" : "精品入出库明细账")
                        setModalNumber(3)
                        setVisible2(true)
                    }}
                    style={{whiteSpace: 'pre-wrap'}}>{text}</a>
            }
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 200,
            dataIndex: 'productName',
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined/></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '条码编号',
            dataIndex: 'barCode',
            width: 150,
            ellipsis: true
        },
        {
            title: '仓位',
            dataIndex: 'positionName',
            width: 100,
            ellipsis: true
        },
        {
            title: '状态',
            dataIndex: ['stockDto', 'status'],
            width: 120,
            ellipsis: true,
            align: 'center',
            render: (text) => text in Stock.StatusAlias ? Stock.StatusAlias[text] : text
        },
        {
            title: '单位',
            align: 'center',
            width: 100,
            dataIndex: 'unit',
            ellipsis: true
        },
        {
            title: '库存去税金额',
            width: 150,
            dataIndex: 'financeTotal',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '账面库存数量',
            width: 100,
            align: 'right',
            dataIndex: 'financeNum',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '实盘数',
            width: 150,
            dataIndex: 'actureNum',
            ellipsis: true,
            render: (text, record) => (
                <InputNumber
                    disabled={record.stockDto.status !== Stock.STATUS_ING}
                    value={text}
                    style={{width: "100%"}}
                    min={0}
                    precision={2}
                    onChange={(val) => {
                        if (val === '') {
                            return false
                        }
                        if (parseFloat(val) === parseFloat(text)) {
                            return false
                        }
                        update({
                            ownerId: common.getUser().company.id,
                            kind: action,
                            id: record.id,
                            actureNum: common.numberFormat(val),
                            spec: record.spec,
                            sign: StockItem.SIGN_YES,
                        })
                    }}
                    onBlur={(e) => {
                        if (e.target.value === '') {
                            return false
                        }
                        if (parseFloat(e.target.value) === parseFloat(text)) {
                            return false
                        }
                        update({
                            ownerId: common.getUser().company.id,
                            kind: action,
                            id: record.id,
                            actureNum: common.numberFormat(e.target.value),
                            spec: record.spec,
                            sign: StockItem.SIGN_YES,
                        })
                    }}
                />
            )
        },
        {
            title: '',
            width: 110,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                if (index !== 0) {
                    return ""
                }
                return (
                    <Button
                        disabled={record.stockDto.status !== Stock.STATUS_ING}
                        icon={<CheckSquareOutlined/>}
                        onClick={() => {
                            common.confirm('点击确定后将会把所有的实盘数改为0！你录入的实盘数将会被0覆盖，请谨慎操作！', () => {
                                batchSetToZero()
                            })
                        }}>批量置为0
                    </Button>
                )
            }
        },
        {
            title: '盘点标记',
            width: 100,
            // align: 'right',
            dataIndex: 'sign',
            ellipsis: true,
            render: (text, record, index) => {
                return <Checkbox
                    checked={text === StockItem.SIGN_YES}
                    onChange={(val) => {
                        let sign = text
                        if (text === StockItem.SIGN_YES) {
                            sign = StockItem.SIGN_NO
                        }
                        if (text === StockItem.SIGN_NO) {
                            sign = StockItem.SIGN_YES
                        }
                        updateSign({
                            id: record.id,
                            sign: sign
                        })
                    }}>{StockItem.SignAlias[text]}</Checkbox>
            }
        },
        {
            title: '盈亏数',
            width: 100,
            align: 'right',
            dataIndex: 'num',
            ellipsis: true,
            render: (text) => text === null ? '0.00' : common.numberFormat(text)
        },
        {
            title: '盘盈含税',
            width: 100,
            dataIndex: 'moreIncludeTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '盘盈去税',
            width: 100,
            dataIndex: 'moreNotTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '盘亏含税',
            width: 100,
            dataIndex: 'lessIncludeTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '盘亏去税',
            width: 100,
            dataIndex: 'lessNotTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '管理分类',
            width: 150,
            dataIndex: 'categoryName',
            align: 'center',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            ellipsis: true,
            dataIndex: 'spec',
            render: (text, record) => (
                <Input
                    disabled={record.stockDto.status !== Stock.STATUS_ING}
                    defaultValue={text}
                    onBlur={(e) => {
                        if (e.target.value === text) {
                            return false
                        }

                        update({
                            ownerId: common.getUser().company.id,
                            kind: action,
                            id: record.id,
                            actureNum: record.actureNum === '' ? 0 : record.actureNum,
                            spec: e.target.value || ""
                        })
                    }}
                />
            )
        }
    ]

    // 品牌
    let getBrand = () => {
        common.loadingStart()
        common.ajax("get", "/wms/brand/list", {
            companyId: common.getUser().company.id,
            status: Brand.STATUS_ENABLE,
        }).then((data) => {
            setSearch({
                ...search,
                brandIds: data.brands.map(item => item.id)
            })
            setBrand(data.brands)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getBrand, [])

    // 获取列表数据
    let getData = () => {
        common.loadingStart();
        new Promise(resolve => {
            common.ajax('get', '/wms/warehouse/list', {
                status: Warehouse.STATUS_ENABLE,//仓库状态为启用
                ownerId: common.getUser().company.id,
                type: Warehouse.TYPE_GOODS,
                isAll: Basic.IS_ANSWER_YES,
            })
                .then(data => {
                    resolve(data.warehouses)
                }).catch(common.loadingStop)
        }).then(warehouseList => {
            if (warehouseList.length > 0) {
                setTabNumber(warehouseList[0].id)
                getListData()
                setWarehouse(warehouseList)
            }
        }).finally(common.loadingStop)
    }

    // 初始化页面
    useEffect(getData, [])

    let getListData = () => {
        if (tabNumber !== '') {
            common.loadingStart()
            common.ajax("get", "/wms/stock/list", {
                ...pagination,
                ...query,
                brandIds: query.brandIds.length === 0 ? brand.map(item => item.id) : query.brandIds,
                termIdsOne: wmsPublic.getTermData(query.termIdsOne, terms),
                warehouseId: tabNumber
            }).then((data) => {
                setList(data.list)
                setSubtotal(data.subtotal === null ? {} : data.subtotal)
                setTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    useEffect(getListData, [tabNumber, query, pagination, brand])

    // 修改 盘点数 备注
    let update = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/stock/update", params).then(() => {
            getListData()
        }).finally(() => {
            common.loadingStop()
        })
    }
    // 修改 盘点标记
    let updateSign = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/stock/updateSign", params).then(() => {
            getListData()
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 审核/作废
    let updateStatus = (params) => {
        common.loadingStart()
        common.ajax("post", "/wms/stock/updateStatus", params).then(() => {
            getListData()
            common.toast("操作成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 通过Taxonomy获取分类信息
    let getCategory = () => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: Term.TAXONOMY_ONE,
            status: Term.TERM_STATUS_ENABLE
        }).then(data => {
            setTerms(data.terms)
            setTreeData1(wmsPublic.getTree(data.terms, ""))
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 根据配件档案仓位 更改仓位
    let updatePosition = () => {
        common.loadingStart()
        common.ajax('post', '/wms/stock/updatePositionName', {
            ownerId: list[0].ownerId,
            id: list[0].stockId,
        }).then(() => {
            getListData()
            common.toast("操作成功")
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(getCategory, [])

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input
                                    value={product.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        product.name === undefined ? <span/> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSearch({...search, productId: ''})
                                                    setProduct({})
                                                }}/>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(1)
                                                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                                setVisible2(true)
                                            }}/>
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(1)
                                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="条码编号">
                                <Input value={search.barCode} onChange={(e) => {
                                    setSearch({...search, barCode: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="管理分类">
                                <TreeSelect
                                    value={search.termIdsOne}
                                    onChange={(val) => {
                                        setSearch({...search, termIdsOne: val})
                                    }}
                                    allowClear={true}
                                    style={{width: '100%'}}
                                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                                    treeData={treeData1}
                                    treeDefaultExpandAll
                                    treeCheckable={true}
                                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="存放仓位">
                                <Input value={search.positionName} onChange={(e) => {
                                    setSearch({...search, positionName: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="盘点状态">
                                <Select
                                    mode={"multiple"}
                                    allowClear
                                    value={search.statuses}
                                    onChange={(val) => {
                                        setSearch({
                                            ...search,
                                            statuses: val.length === 0 ? [Stock.STATUS_ING, Stock.STATUS_INVALID, Stock.STATUS_AUDIT] : val
                                        })
                                    }}>
                                    <Select.Option key={Stock.STATUS_ING}
                                                   value={Stock.STATUS_ING}>{Stock.StatusAlias[Stock.STATUS_ING]}</Select.Option>
                                    <Select.Option key={Stock.STATUS_AUDIT}
                                                   value={Stock.STATUS_AUDIT}>{Stock.StatusAlias[Stock.STATUS_AUDIT]}</Select.Option>
                                    <Select.Option key={Stock.STATUS_INVALID}
                                                   value={Stock.STATUS_INVALID}>{Stock.StatusAlias[Stock.STATUS_INVALID]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="经营品牌">
                                {brand.length > 0 &&
                                    <Select
                                        mode={"multiple"}
                                        allowClear
                                        value={search.brandIds}
                                        onChange={(val) => {
                                            setSearch({
                                                ...search,
                                                brandIds: val.length === 0 ? brand.map(item => item.id) : val
                                            })
                                        }}>
                                        {
                                            brand.map((item) => {
                                                return (
                                                    <Select.Option key={item.id}
                                                                   value={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }
                                    </Select>}
                                {brand.length === 0 &&
                                    <Select value={''}>
                                        <Select.Option key={''} value={''}>全部</Select.Option>
                                    </Select>}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="结存单号">
                                <Input value={search.code} onChange={(e) => {
                                    setSearch({...search, code: e.target.value})
                                }}/>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="显示对象">
                                <Select
                                    value={search.quantity}
                                    onChange={(val) => {
                                        setSearch({...search, quantity: val})
                                    }}>
                                    <Select.Option key={3} value={3}>盘盈</Select.Option>
                                    <Select.Option key={4} value={4}>盘亏</Select.Option>
                                    <Select.Option key={2} value={2}>盈亏差异</Select.Option>
                                    <Select.Option key={1} value={1}>未点实物</Select.Option>
                                    <Select.Option key={-1} value={-1}>全部记录</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="盘点标记">
                                <Select
                                    allowClear
                                    value={search.sign}
                                    onChange={(val) => {
                                        setSearch({...search, sign: val})
                                    }}>
                                    <Select.Option key={StockItem.SIGN_YES}
                                                   value={StockItem.SIGN_YES}>{StockItem.SignAlias[StockItem.SIGN_YES]}</Select.Option>
                                    <Select.Option key={StockItem.SIGN_NO}
                                                   value={StockItem.SIGN_NO}>{StockItem.SignAlias[StockItem.SIGN_NO]}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined/>} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    setProduct({})
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            {warehouse.length > 0 &&
                <Tabs
                    tabBarExtraContent={
                        <>
                            <Button
                                onClick={() => {
                                    updatePosition()
                                }}
                                disabled={list.length === 0}
                                style={{marginRight: '10px'}}>
                                获取仓位信息
                            </Button>
                            <Button
                                type="primary"
                                disabled={list.length === 0 || list[0].stockDto.status !== Stock.STATUS_ING}
                                style={{marginRight: '10px'}}
                                icon={<SaveOutlined/>}
                                onClick={() => {
                                    let arr = []
                                    list.forEach((item) => {
                                        if (item.stockDto.status === Stock.STATUS_ING) {
                                            arr.push(item)
                                        }
                                    })

                                    if (arr.length === 0) {
                                        common.toast("该盘点单不允许提交审核")
                                        return false
                                    }
                                    // 判断数量
                                    let number = 0;
                                    let number2 = 0;
                                    arr.forEach((item) => {
                                        if (item.actureNum === '') {
                                            number2++
                                        }
                                        if (parseFloat(item.actureNum) < 0) {
                                            number++
                                        }
                                    })
                                    if (number2 !== 0) {
                                        common.toast("实盘数不允许为空")
                                        return false
                                    }
                                    if (number !== 0) {
                                        common.toast("实盘数不合法")
                                        return false
                                    }

                                    common.confirm("确认要提交审核吗?", () => {

                                        updateStatus({
                                            ownerId: common.getUser().company.id,
                                            id: arr[0].stockId,
                                            status: Stock.STATUS_AUDIT
                                        })
                                    })
                                }}>提交审核</Button>
                            <Button
                                disabled={list.length === 0}
                                style={{marginRight: '10px'}}>
                                <Link
                                    to={action === Product.KIND_PART ? '/wms/part/stock/import' : '/wms/article/stock/import'}>
                                    <CloudUploadOutlined/>
                                    <span> 导入实盘数</span>
                                </Link>
                            </Button>
                            <Button
                                disabled={list.length === 0 || list[0].stockDto.status !== Stock.STATUS_ING}
                                style={{marginRight: '10px'}}
                                icon={<MinusCircleOutlined/>}
                                type={"danger"}
                                onClick={() => {
                                    let arr = []
                                    list.forEach((item) => {
                                        if (item.stockDto.status === Stock.STATUS_ING) {
                                            arr.push(item)
                                        }
                                    })

                                    if (arr.length === 0) {
                                        common.toast("该结存单不允许作废")
                                        return false
                                    }

                                    common.confirm("作废结存单,您所录入的实盘数将不能恢复,您确认要作废吗?", () => {
                                        updateStatus({
                                            ownerId: common.getUser().company.id,
                                            id: arr[0].stockId,
                                            status: Stock.STATUS_INVALID
                                        })
                                    })
                                }}>作废结存单</Button>
                            <ExportButton
                                filename={action === Product.KIND_PART ? '配件结存表.xlsx' : '精品结存表.xlsx'}
                                buttonName={'导出结存表'}
                                ownerId={search.ownerId}
                                params={JSON.stringify({
                                    ...query,
                                    termIdsOne: wmsPublic.getTermData(query.termIdsOne, terms),
                                    warehouseId: tabNumber,
                                })}
                                type={'wms_part_stock'}
                            />
                        </>
                    }
                    activeKey={tabNumber}
                    onChange={(key) => {
                        setPagination({...pagination, page: 1})
                        setTableNumber(tableNumber + 1)
                        setTabNumber(key)
                    }}>
                    {warehouse.map((warehouseItem) => {
                        return (
                            <Tabs.TabPane tab={warehouseItem.name} key={warehouseItem.id}>
                                {tableNumber && tabNumber === warehouseItem.id &&
                                    <Table
                                        pagination={false}
                                        rowKey='id'
                                        columns={columns}
                                        dataSource={list}
                                        scroll={{x: '100%', y: '350px'}}
                                    />}
                                {tabNumber === warehouseItem.id &&
                                    <PageBottom
                                        children={
                                            Object.keys(subtotal).length > 0 &&
                                            <>
                                                <Row gutter={24}>
                                                    <Col span={4}>
                                                        账面库存:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.financeNum)}
                                                    </Col>
                                                    <Col span={4}>
                                                        库存含税金额:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.financeMoney)}
                                                    </Col>
                                                    <Col span={4}>
                                                        库存去税金额:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.financeTotal)}
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={4}>
                                                        实盘数:<Tooltip title={'包含未修改过"实盘数"的记录'}>
                                                        <QuestionCircleOutlined/></Tooltip>
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.actureNum)}
                                                    </Col>
                                                    <Col span={4}>
                                                        盘点含税金额:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(new BigNumber(subtotal.moreIncludeTax).plus(subtotal.financeMoney).plus(subtotal.lessIncludeTax).toString())}
                                                    </Col>
                                                    <Col span={4}>
                                                        盘点去税金额:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(new BigNumber(subtotal.moreNotTax).plus(subtotal.financeTotal).plus(subtotal.lessNotTax).toString())}
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={4}>
                                                        盘盈数量:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.moreNum)}
                                                    </Col>
                                                    <Col span={4}>
                                                        盘盈含税:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.moreIncludeTax)}
                                                    </Col>
                                                    <Col span={4}>
                                                        盘盈去税:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.moreNotTax)}
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={4}>
                                                        盘亏数量
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.lessNum)}
                                                    </Col>
                                                    <Col span={4}>
                                                        盘亏含税:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.lessIncludeTax)}
                                                    </Col>
                                                    <Col span={4}>
                                                        盘亏去税:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.lessNotTax)}
                                                    </Col>
                                                </Row>
                                                <Row gutter={24}>
                                                    <Col span={4}>
                                                        盈亏数:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(subtotal.num)}
                                                    </Col>
                                                    <Col span={4}>
                                                        盈亏含税:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(new BigNumber(subtotal.moreIncludeTax).plus(subtotal.lessIncludeTax).toString())}
                                                    </Col>
                                                    <Col span={4}>
                                                        盈亏去税:
                                                    </Col>
                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                        {common.numberFormat(new BigNumber(subtotal.moreNotTax).plus(subtotal.lessNotTax).toString())}
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        pagination={
                                            <Pagination
                                                showQuickJumper={true}
                                                showTotal={total => `共${total}条`}
                                                total={total}
                                                current={pagination.page}
                                                pageSizeOptions={['10', '15', '50', '100']}
                                                defaultPageSize={pagination.limit}
                                                showSizeChanger
                                                onShowSizeChange={onChange}
                                                onChange={onChange}/>
                                        }/>}
                            </Tabs.TabPane>
                        )
                    })}
                </Tabs>}

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {modalNumber === 1 &&
                    <LookupProduct
                        isHaltProduction={'all'}
                        action={'all'}
                        defaultValue={action}
                        isMultiple={false}
                        onOk={(val) => {
                            setProduct(val)
                            setSearch({...search, productId: val.id})
                            setVisible2(false)
                        }}/>}
                {modalNumber === 3 &&
                    <ProductGoodsReceiptAndDelivery action={action} number={number}/>}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Index)