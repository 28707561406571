import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, Row, Select, AutoComplete, Modal} from "antd";
import PageTop from "../../../components/layout/PageTop";
import {CloseOutlined, SaveOutlined, SelectOutlined} from "@ant-design/icons";
import common from '../../../utils/common'
import LookupCompany from "../../passport/LookupCompany";
import {Project} from "../config";
import LookupPartner from "../../passport/LookupPartner";
import BigNumber from 'bignumber.js';

const FormItem = Form.Item
const {Option} = Select
const STATUS_ENABLE = 1  // 启用
const STATUS_DISABLE = 2 // 禁用

// 使用场景
const SCENE_NEW = Symbol()
const SCENE_EDIT = Symbol()
const initialCompany = {
    ids: [],
    names: "",
}

let initialProject = {
    id: '',
    name: '',
    section: '',
    number: '',
    category: '',
    remark: '',
    status: STATUS_ENABLE,
    price: '',
    companyNames: "",
    fiduciaryCompanyId: "",
    companyIds: [],
    workHoursQuota: '1.00',
    comprehensiveCoefficient: '1.00'
}

function ProjectForm(props) {

    // isTenant 是否是集团端  true：是   false：否
    // id 工时id
    // onOk 按保存之后返回给父组件的值
    let {isTenant, id, onOk, type = Project.TYPE_PROJECT} = props
    let [categoryOptions, setCategoryOptions] = useState([])
    let [company, setCompany] = useState(initialCompany)//适用公司
    let [fiduciaryCompany, setFiduciaryCompany] = useState({})
    let [sections, setSections] = useState([]) // 工段
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)// 选择公司弹框
    let [modalNum, setModalNum] = useState(0)// 选择公司弹框
    const [form] = Form.useForm()
    const [re, setRe] = useState('')

    // 返回当前的场景，是新增还是编辑
    let getScene = () => {
        if (id) {
            return SCENE_EDIT
        }
        return SCENE_NEW
    }

    //获取工段
    let getSections = () => {
        common.loadingStart()
        common.ajax('get', '/passport/term/findByTaxonomy', {taxonomy: "section"})
            .then((data) => {
                data = data.filter(item => item.status !== 2)
                setSections(data)
            }).finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        getSections()
    }, [])

    //获取项目类别
    let getCategories = () => {
        common.loadingStart()
        common.ajax('get', '/support/project/findCategoriesByOwnerId', {ownerId: isTenant ? "" : common.getUser().company.id})
            .then(res => {
                categoryOptions = []
                res.forEach(item => {
                    let option = {value: item}
                    categoryOptions.push(option)
                })
                setCategoryOptions(categoryOptions)
            }).finally(common.loadingStop)
    }
    useEffect(() => {
        getCategories()
    }, [])

    // 提交表单信息
    let onFinish = (values) => {
        if (id) {
            update(values)
        } else {
            create(values)
        }
    }

    // 新增
    let create = (values) => {
        common.loadingStart()
        let params = {
            ...values,
            ownerId: isTenant ? "" : common.getUser().company.id,
            type: type,
            fiduciaryCompanyId: ""
        }
        // 受托公司
        if (type === Project.TYPE_OUTSOURCE) {
            if (Object.keys(fiduciaryCompany).length > 0) {
                params.fiduciaryCompanyId = fiduciaryCompany.id
            }
        }
        common.ajax("post", "/support/project/create", params)
            .then((res) => {
                common.toast("新增成功")
                form.setFieldsValue({...initialProject})
                setCompany(initialCompany)
                getCategories()
                onOk(true)
            })
            .finally(common.loadingStop)
    }

    // 更新
    let update = (values) => {
        common.loadingStart()
        let params = {
            ...values,
            type: type,
            fiduciaryCompanyId: ""
        }
        // 受托公司
        if (type === Project.TYPE_OUTSOURCE) {
            if (Object.keys(fiduciaryCompany).length > 0) {
                params.fiduciaryCompanyId = fiduciaryCompany.id
            }
        }
        common.ajax("post", "/support/project/updateById", params)
            .then((res) => {
                common.toast("更新成功")
                onOk(true)
                setCompany(initialCompany)
                getCategories()
            })
            .finally(common.loadingStop)
    }

    // 根据id获取工时项目
    useEffect(() => {
        if (id) {
            common.loadingStart()
            common.ajax('get', '/support/project/findById?id=' + id).then(res => {
                form.setFieldsValue({...res})
                setCompany({ids: res.companyIds, names: res.companyNames})
                // 受托方
                setFiduciaryCompany({id: res.fiduciaryCompanyId, name: res.fiduciaryCompanyName})
            }).finally(common.loadingStop)
        } else {
            form.setFieldsValue({...initialProject})
            setCompany(initialCompany)
        }
    }, [id])

    return (
        <React.Fragment>
            <PageTop title={getScene() === SCENE_NEW ? "新增项目" : "编辑项目"}
                children={<Button type="primary" icon={<SaveOutlined />}
                    onClick={() => {
                        form.submit()
                    }}>保存</Button>} />
            <Form className={'ant-advanced-inline-form'} form={form} onFinish={onFinish}>
                <FormItem hidden name='status' label="状态">
                    <Input hidden />
                </FormItem>
                <FormItem hidden name='id' label="项目id">
                    <Input hidden />
                </FormItem>
                <FormItem hidden name='code' label="编号">
                    <Input hidden />
                </FormItem>
                <FormItem hidden name='companyIds' label="公司ids">
                    <Input hidden />
                </FormItem>
                <Row gutter={24}>
                    <Col span={12}>
                        <FormItem name='name' label="项目名称" className="label-character-4"
                            rules={[
                                {required: true, message: "项目名称不能为空"}
                            ]}>
                            <Input />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='number' label="项目代码" className="label-character-4">
                            <Input />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem name='price' label="工时单价"
                            className="label-character-4"
                            rules={[
                                {required: true, message: "单价不能为空"}
                            ]}>
                            <InputNumber min={0} precision={2} onChange={e => {setRe(e)}} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='section' label="维修工段"
                            className="label-character-4"
                            rules={[
                                {required: true, message: "工段不能为空"},
                            ]}>
                            <Select>
                                <Option value="">请选择</Option>
                                {
                                    sections.map(section => (
                                        <Option value={section.id} key={section.id}>{section.name}</Option>
                                    ))
                                }
                            </Select>
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem
                            name='workHoursQuota'
                            label="工时定额"
                            className="label-character-4">
                            <InputNumber min={0} precision={2} onChange={e => {setRe(e)}} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name='dispatchTime'
                            label="派工工时"
                            className="label-character-4"
                            rules={[
                                {required: true, message: "派工工时不能为空"}
                            ]}>
                            <InputNumber min={0} precision={2} />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem
                            name='comprehensiveCoefficient'
                            label="综合系数"
                            className="label-character-4">
                            <InputNumber min={0} precision={2} onChange={e => {setRe(e)}} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name='category'
                            label="项目类别" className="label-character-4">
                            <AutoComplete
                                options={categoryOptions}
                                placeholder="请输入项目类别"
                                filterOption={(inputValue, option) =>
                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                }
                            />
                        </FormItem>
                    </Col>

                    <Col span={12}>
                        <FormItem
                            label="项目金额"
                            className="label-character-4">
                            <InputNumber disabled={true} min={0} precision={2} value={
                                new BigNumber(form.getFieldValue('price') || "0.00")
                                    .multipliedBy(new BigNumber(form.getFieldValue('workHoursQuota') || "1.00"))
                                    .multipliedBy(new BigNumber(form.getFieldValue('comprehensiveCoefficient') || "1.00"))
                                    .toFixed(2)} />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem name='status' label="状态" className="label-character-4">
                            <Select>
                                <Option value={STATUS_ENABLE} key={STATUS_ENABLE}>启用</Option>
                                <Option value={STATUS_DISABLE} key={STATUS_DISABLE}>禁用</Option>
                            </Select>
                        </FormItem>
                    </Col>

                    {type === Project.TYPE_OUTSOURCE &&
                        <Col span={12}>
                            <FormItem label={"受托方"} className="label-character-4">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    type="text"
                                    placeholder={'选择受托方'}
                                    value={fiduciaryCompany.name}
                                    suffix={
                                        fiduciaryCompany.name !== undefined ? <CloseOutlined
                                            onClick={() => {
                                                setFiduciaryCompany({})
                                            }} /> : <span />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNum(2)
                                                setLookupCompanyVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalNum(2)
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col>}
                    {/*如果是集团端则显示 适用公司*/}
                    {
                        isTenant ? <Col span={24}>
                            <FormItem name='companyNames' label="适用公司" className="label-character-4">
                                <Input
                                    readOnly
                                    autoComplete="off"
                                    placeholder={'请选择公司名称'}
                                    suffix={
                                        company.names !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    form.setFieldsValue({
                                                        companyNames: "",
                                                        companyIds: [],
                                                    })
                                                    setCompany({
                                                        ids: [],
                                                        names: "",
                                                    })
                                                }} /> : <span />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupCompanyVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setLookupCompanyVisible(true)
                                    }}
                                />
                            </FormItem>
                        </Col> : null
                    }
                    <Col span={24}>
                        <FormItem name='remark' label="项目备注" className="label-character-4">
                            <Input.TextArea />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div>
                <Modal
                    maskClosable={false}
                    title={modalNum === 1 ? "选择公司" : "选择受托方"}
                    visible={lookupCompanyVisible}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={() => {
                        setLookupCompanyVisible(false)
                    }}>
                    {modalNum === 1 &&
                        <LookupCompany type={'group'} isMultiple={true} onOk={(value) => {
                            setLookupCompanyVisible(false)
                            form.setFieldsValue({
                                companyIds: value.map((item) => item.id),
                                companyNames: value.map((item) => item.name).join(", ")
                            })
                            setCompany({
                                ids: value.map((item) => item.id),
                                names: value.map((item) => item.name).join(", ")
                            })
                        }}
                        />}
                    {modalNum === 2 &&
                        <LookupPartner
                            dataSource={isTenant ? 1 : 3}
                            isMultiple={false}
                            onOk={(value) => {
                                setLookupCompanyVisible(false)
                                setFiduciaryCompany(value)
                            }}
                        />}
                </Modal>
            </div>
        </React.Fragment>
    )
}

export default ProjectForm