import React, {useEffect, useState} from 'react';
import {CloseOutlined, QuestionCircleOutlined, ReloadOutlined, SearchOutlined, SelectOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Pagination, Row, Select, Table, Tooltip} from "antd";
import SearchArea from "../../layout/SearchArea";
import PageBottom from "../../layout/PageBottom";
import {connect} from "react-redux";
import common from "../../../utils/common";
import BigNumber from "bignumber.js";
import {Basic, Product, ProductCategoryRate, ProductGeneralRate, Receipt, Warehouse} from "../config";
import LookupProduct from "../LookupProductGoods";
import LookupAll from "../../passport/LookupAll";

const {RangePicker} = DatePicker
const {Option} = Select

function ReceiptIndex(props) {
    // action part(配件)  boutique(精品)
    // chargeType        (字符串)      收费区分      说明:(选填)   1(默认 客户自费 零售)/2(保险维修)/3(厂家索赔)
    // orderType         (字符串)      出库类型      说明:(选填)   1(默认 销售出库)/2(售后维修)/3(集团内移出)/4(库存调整)/5(出库退货)
    // defaultValue      (数组)        默认选择的行   说明:(选填)
    // productNumber     (字符串)      搜索的商品编号  说明:(选填)
    let {
        action = Product.KIND_PART,
        onOk,
        chargeType,
        orderType,
        defaultValue = [],
        productNumber = "",
        isShowPriceCost = true
    } = props

    // 列表分页数据
    let [total, setTotal] = useState(0)
    let [pagination, setPagination] = useState({
        page: 1,
        limit: 10,
    })
    // 列表数据
    let [list, setList] = useState([])
    // let [product, setProduct] = useState({});// 配件/精品
    let [supplier, setSupplier] = useState({});// 供货商
    let [actionNumber, setActionNumber] = useState(0);
    let [visible2, setVisible2] = useState(false)
    let [modalTitle, setModalTitle] = useState('')
    let [modalNumber, setModalNumber] = useState(0)
    let [warehouse, setWarehouse] = useState([]) // 仓库

    // 搜索条件
    let initialSearch = {
        receiptCode: "",
        productName: "",
        type: "",
        number: "",
        warehouseId: "",
        // productId: "",
        supplierName: "",
        deliveryNumber: "",
        date: [],
    }
    let [search, setSearch] = useState(initialSearch); //搜索输入的内容
    let [query, setQuery] = useState(initialSearch); //提交ajax搜索值
    let [rows, setRows] = useState([]);// 打印的数据

    let rowSelection = {
        selectedRowKeys: rows.map(item => item.id),
        preserveSelectedRowKeys: true,
        onSelect: (record, selected) => {
            if (selected) {
                setRows([
                    ...rows,
                    record
                ])
            } else {
                setRows(rows.filter(item => item.id !== record.id))
            }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            if (selected) {
                setRows([
                    ...rows,
                    ...changeRows
                ])
            }
            if (!selected) {
                let arr = []
                rows.forEach((item1) => {
                    let number = 0;
                    changeRows.forEach((item2) => {
                        if (item1.id === item2.id) {
                            number++;
                        }
                    })
                    if (number === 0) {
                        arr.push(item1)
                    }
                })

                setRows(arr)
            }
        }
    }

    const columns = [
        {
            title: action === Product.KIND_ARTICLE ? '精品编号' : '配件编号',
            dataIndex: ['productDto', 'number'],
            width: 150,
            ellipsis: true,
            render: (text) => <span style={{whiteSpace: 'pre-wrap'}}>{text}</span>
        },
        {
            title: action === Product.KIND_ARTICLE ? '精品名称' : '配件名称',
            width: 250,
            dataIndex: ['productDto', 'name'],
            ellipsis: true,
            render: (text, record) => {
                if (record.positionName === '') {
                    return text
                }

                return (
                    <div>
                        {text}
                        <Tooltip title={record.positionName}> <QuestionCircleOutlined /></Tooltip>
                    </div>
                )
            }
        },
        {
            title: '入库时间',
            width: 120,
            dataIndex: 'receiptDate',
            ellipsis: true,
            render: (text) => text === undefined ? '' : text.substring(0, 10)
        },
        {
            title: '库别名称',
            width: 150,
            align: 'center',
            dataIndex: 'warehouseName',
            ellipsis: true
        },
        {
            title: '数量',
            width: 100,
            align: 'right',
            dataIndex: 'salableQuantity',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: '单位',
            align: 'center',
            width: 100,
            dataIndex: ['productDto', 'unit'],
            ellipsis: true
        },
        {
            title: '含税进价',
            width: isShowPriceCost ? 100 : 0,
            dataIndex: 'priceCost',
            align: 'right',
            ellipsis: true,
            render: (text) => common.numberFormat(text)
        },
        {
            title: <>计划售价<Tooltip title={'加价后的价格'}> <QuestionCircleOutlined /></Tooltip></>,
            width: 100,
            dataIndex: 'price',
            align: 'right',
            ellipsis: true,
            render: (text) => <span style={{color: 'blue'}}>{common.numberFormat(text)}</span>
        },
        {
            title: '供货商',
            width: 250,
            align: 'center',
            dataIndex: 'supplierName',
            ellipsis: true
        },
        {
            title: '税率',
            width: 100,
            dataIndex: 'tax',
            align: 'center',
            ellipsis: true,
            render: (text) => common.numberCut(new BigNumber(text).multipliedBy(100).toString(), 2) + "%"
        },
        {
            title: '去税金额',
            width: 150,
            dataIndex: 'priceGo',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '税金',
            width: 150,
            dataIndex: 'priceTax',
            ellipsis: true,
            align: 'right',
            render: (text) => common.numberFormat(text)
        },
        {
            title: '送货单号',
            width: 200,
            align: 'center',
            dataIndex: ['receiptDto', 'deliveryNumber'],
            ellipsis: true
        },
        {
            title: '入库单号',
            dataIndex: ['receiptDto', 'code'],
            width: 200,
            ellipsis: true
        },
        {
            title: '入库类型',
            width: 100,
            dataIndex: ['receiptDto', 'type'],
            ellipsis: true,
            render: (text) => text in Receipt.ReceiptTypeAlias ? Receipt.ReceiptTypeAlias[text] : text
        },
        {
            title: '状态',
            width: 100,
            dataIndex: 'status',
            ellipsis: true,
            render: (text) => (
                text in Receipt.ReceiptItemGoodsStatusAlias ? Receipt.ReceiptItemGoodsStatusAlias[text] : text
            )
        },
        {
            title: '采购人',
            width: 150,
            dataIndex: 'employeeName',
            ellipsis: true
        },
        {
            title: '备注',
            width: 200,
            dataIndex: 'spec',
            ellipsis: true
        }
    ]

    useEffect(() => {
        if (productNumber !== "") {
            setSearch({...search, number: productNumber})
        }
    }, [productNumber])

    // ajax 获取列表数据
    let getData = () => {
        let params = {
            kind: action === Product.KIND_ARTICLE ? Product.KIND_ARTICLE : Product.KIND_PART,
            chargeType: ProductGeneralRate.CHARGE_TYPE_SALE,
            orderType: ProductCategoryRate.ORDER_TYPE_SALE,
            ownerId: common.getUser().company.id,
            types: [Receipt.RECEIPT_TYPE_BUY, Receipt.RECEIPT_TYPE_TRANSFER, Receipt.RECEIPT_TYPE_ALLOT, Receipt.RECEIPT_TYPE_EMPTY, Receipt.RECEIPT_TYPE_STOCK, Receipt.RECEIPT_TYPE_ADJUST_SKU],
            isHaveDeliveryPrice: Basic.IS_ANSWER_YES,
            isHaveGoodsInStock: Basic.IS_ANSWER_YES,
            brandId: common.getUser().brand.id
        }
        if (chargeType === ProductGeneralRate.CHARGE_TYPE_REPAIR || chargeType === ProductGeneralRate.CHARGE_TYPE_CLAIM || chargeType === ProductGeneralRate.CHARGE_TYPE_BOUTIQUE) {
            params.chargeType = chargeType
        }
        if (orderType === ProductCategoryRate.ORDER_TYPE_ALLOT || orderType === ProductCategoryRate.ORDER_TYPE_RECEIVE || orderType === ProductCategoryRate.ORDER_TYPE_REPAIR || orderType === ProductCategoryRate.ORDER_TYPE_SALE_WMS) {
            params.orderType = orderType
        }

        if (productNumber !== undefined && actionNumber === 0) {
            query.number = productNumber
        }
        common.loadingStart()
        common.ajax("get", "/wms/receiptItem/listForDelivery", {
            ...params,
            ...pagination,
            ...query,
        }).then((data) => {
            if (data.receiptItems.length > 0) {
                data.receiptItems.forEach((item) => {
                    item.priceGo = common.numberCut(new BigNumber(item.price).dividedBy(new BigNumber(item.tax).plus(1)).toString(), 2)
                    item.priceTax = new BigNumber(item.price).minus(item.priceGo).toNumber()
                })
            }

            setList(data.receiptItems)
            setTotal(data.pagination.total)
        }).finally(() => {
            common.loadingStop()
        })
    }

    useEffect(() => {
        setRows(defaultValue)
    }, [])

    let onChange = (page, limit) => {
        setPagination({page, limit})
    }

    // 初始化页面
    useEffect(getData, [pagination, query, productNumber])

    // 获取仓库
    let getWarehouse = () => {
        common.loadingStart();
        common.ajax("get", "/wms/warehouse/list", {
            status: Warehouse.STATUS_ENABLE,
            isAll: Basic.IS_ANSWER_YES,
            ownerId: common.getUser().company.id,
            type: Warehouse.TYPE_GOODS,
        }).then((data) => {
            setWarehouse(data.warehouses)
        }).finally(() => {
            common.loadingStop()
        })
    }
    useEffect(getWarehouse, [])

    return (
        <>
            <SearchArea>
                <Form name={"validate_other"} className={"ant-advanced-search-form"}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                className={'label-character-3'}
                                label={action === Product.KIND_ARTICLE ? "精品编号" : "配件编号"}>
                                <Input value={search.number} onChange={(e) => {
                                    setSearch({...search, number: e.target.value.trim()})
                                }}
                                    onPressEnter={() => {
                                        setActionNumber(1)
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {/*<Col span={8}>*/}
                        {/*    <Form.Item*/}
                        {/*        label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>*/}
                        {/*        <Input*/}
                        {/*            value={product.name}*/}
                        {/*            readOnly={true}*/}
                        {/*            autoComplete={"off"}*/}
                        {/*            suffix={*/}
                        {/*                product.name === undefined ? <span/> :*/}
                        {/*                    <CloseOutlined*/}
                        {/*                        onClick={() => {*/}
                        {/*                            setSearch({...search, productId: ''})*/}
                        {/*                            setProduct({})*/}
                        {/*                        }}/>*/}
                        {/*            }*/}
                        {/*            addonAfter={*/}
                        {/*                <SelectOutlined*/}
                        {/*                    onClick={() => {*/}
                        {/*                        setModalNumber(1)*/}
                        {/*                        setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品")*/}
                        {/*                        setVisible2(true)*/}
                        {/*                    }}/>*/}
                        {/*            }*/}
                        {/*            onKeyPress={() => {*/}
                        {/*                setModalNumber(1)*/}
                        {/*                setModalTitle(action === Product.KIND_PART ? "选择配件" : "选择精品");*/}
                        {/*                setVisible2(true)*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    </Form.Item>*/}
                        {/*</Col>*/}
                        <Col span={8}>
                            <Form.Item
                                label={action === Product.KIND_ARTICLE ? "精品名称" : "配件名称"}>
                                <Input value={search.productName} onChange={(e) => {
                                    setSearch({...search, productName: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setActionNumber(1)
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="入库单号">
                                <Input value={search.receiptCode} onChange={(e) => {
                                    setSearch({...search, receiptCode: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setActionNumber(1)
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                className={'label-character-3'}
                                label="入库类型">
                                <Select value={search.type} onChange={(val) => {
                                    setSearch({...search, type: val})
                                }}>
                                    <Option value={Receipt.RECEIPT_TYPE_BUY}
                                        key={Receipt.RECEIPT_TYPE_BUY}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_BUY]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ALLOT}
                                        key={Receipt.RECEIPT_TYPE_ALLOT}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ALLOT]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_TRANSFER}
                                        key={Receipt.RECEIPT_TYPE_TRANSFER}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_TRANSFER]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_STOCK}
                                        key={Receipt.RECEIPT_TYPE_STOCK}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_STOCK]}</Option>
                                    <Option value={Receipt.RECEIPT_TYPE_ADJUST_SKU}
                                        key={Receipt.RECEIPT_TYPE_ADJUST_SKU}>{Receipt.ReceiptTypeAlias[Receipt.RECEIPT_TYPE_ADJUST_SKU]}</Option>
                                    <Option value={initialSearch.type}
                                        key={initialSearch.type}>全部</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="入库时间">
                                <RangePicker
                                    suffixIcon={null}
                                    value={search.date}
                                    onChange={(timeDate, dateArray) => {
                                        setSearch({
                                            ...search,
                                            createdAtStart: dateArray[0] === "" ? '' : dateArray[0] + " 00:00:00",
                                            createdAtEnd: dateArray[1] === "" ? '' : dateArray[1] + " 23:59:59",
                                            date: timeDate
                                        })
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="库别名称">
                                <Select value={search.warehouseId} onChange={(val) => {
                                    setSearch({...search, warehouseId: val})
                                }}>
                                    {
                                        warehouse.map((item) => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                className={'label-character-3'}
                                label="供货商">
                                <Input
                                    value={supplier.name}
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        supplier.id === undefined ? <span /> :
                                            <CloseOutlined
                                                onClick={() => {
                                                    setSupplier({})
                                                    setSearch({...search, supplierId: ''})
                                                }} />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalTitle("选择供货商")
                                                setModalNumber(2)
                                                setVisible2(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalTitle("选择供货商")
                                        setModalNumber(2)
                                        setVisible2(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="送货单号">
                                <Input value={search.deliveryNumber} onChange={(e) => {
                                    setSearch({...search, deliveryNumber: e.target.value})
                                }}
                                    onPressEnter={() => {
                                        setActionNumber(1)
                                        setPagination({...pagination, page: 1})
                                        setQuery(search)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <div className={'search-btns'}>
                                <Button icon={<SearchOutlined />} onClick={() => {
                                    setActionNumber(1)
                                    setPagination({...pagination, page: 1})
                                    setQuery(search)
                                }}>搜索</Button>
                                <Button icon={<ReloadOutlined />} onClick={() => {
                                    setPagination({...pagination, page: 1})
                                    setQuery(initialSearch)
                                    setSearch(initialSearch)
                                    // setProduct({})
                                    setSupplier({})
                                    setActionNumber(1)
                                }}>清空</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </SearchArea>

            <Table
                pagination={false}
                rowKey="id"
                columns={columns}
                dataSource={list}
                rowSelection={rowSelection}
                onRow={record => {
                    return {
                        onClick: () => {
                            rowSelection.onSelect(record, rows.filter(item => item.id === record.id).length === 0)
                        }, // 点击行
                    };
                }}
                scroll={{x: '100%'}} />
            <PageBottom
                pagination={
                    <Pagination
                        showQuickJumper={true}
                        showTotal={total => `共${total}条`}
                        total={total}
                        current={pagination.page}
                        showSizeChanger
                        onShowSizeChange={onChange}
                        onChange={onChange}
                        pageSizeOptions={['10']}
                        defaultPageSize={pagination.limit} />
                }>
            </PageBottom>
            <div className={"modal-footer-btn"}>
                <Button type={"primary"} onClick={() => {
                    if (rows.length === 0) {
                        common.toast(action === Product.KIND_ARTICLE ? "请选择精品" : "请选择配件")
                        return false
                    }
                    onOk(rows)
                }}>确定</Button>
            </div>

            <Modal
                maskClosable={false}
                visible={visible2}
                title={modalTitle}
                width={1000}
                destroyOnClose={true}
                onCancel={() => {
                    setVisible2(false)
                }}
                footer={null}
            >
                {/*{modalNumber === 1 &&*/}
                {/*<LookupProduct*/}
                {/*    defaultValue={action}*/}
                {/*    isMultiple={false}*/}
                {/*    onOk={(val) => {*/}
                {/*        setProduct(val)*/}
                {/*        setSearch({...search, productId: val.id})*/}
                {/*        setVisible2(false)*/}
                {/*    }}/>}*/}
                {modalNumber === 2 &&
                    <LookupAll
                        isCompany={true}
                        isPartner={true}
                        isMultiple={false}
                        onOk={(val) => {
                            setSupplier(val)
                            setSearch({...search, supplierId: val.id})
                            setVisible2(false)
                        }}
                    />}
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeUser: (user) => {
            dispatch({type: 'CHANGE_USER', user: user})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptIndex)