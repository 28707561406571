import React, {useEffect, useState} from 'react';
import {CloseOutlined, RetweetOutlined, RollbackOutlined, SaveOutlined, SelectOutlined, } from '@ant-design/icons';
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select} from 'antd';
import PageTop from "../../../../components/layout/PageTop"
import common from "../../../../utils/common"
import LookupProduct from "../../../../components/wms/LookupProduct"
import LookupAddress from "../../../../components/passport/LookupAddress"
import CustomersCreate from "./customersCreate";
import Search from "../../../../utils/search";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import moment from "moment/moment";

const {Option} = Select

const SEX_UNKNOWN = 0
const SEX_MEN = 1
const SEX_WOMEN = 2

const STATUS_SUCCESS = 1
// const STATUS_FAIL = 2
const STATUS_CONDUCT = 3

function MaintainTask(props) {
    const {history, match} = props
    let [state, setState] = useState({
        sex: 1,
        name: "",
        arrivalTime: "",// 到店日期
        wechat: "",
        createdAt: "",
        creatorId: "",// 创建人
        phone: props.match !== undefined ? props.match.params.mobile : "",
        phone2: "",
        qq: "",
        email: "",
        remark: "",
        work: "",
        idCard: "",
        idcard: "",
        address: "",
        saleId: common.getUser().id || "",
        provinceId: "",
        cityId: "",
        districtId: "",
        sourceId: "",
        channelId: "",
        info: "wechat",
        productId: "",
        productName: "",
        status: null,
        leadId: "",
        ownerId: "",
        saleName: common.getUser().nickname || "",
        companyType: "",
        customerTypeId: "",
        customerSourceFlag: "sale",
        method: "",
        levelId: '',
        phoneCity: '',
        color: ''
    })
    let [source, setSource] = useState([])
    let [channel, setChannel] = useState([])
    let [lookupEmployeeVisible, setLookupEmployeeVisible] = useState(false)
    let [lookupProductVisible, setLookupProductVisible] = useState(false)
    let [lookupAddressVisible, setLookupAddressVisible] = useState(false)
    let [address, setAddress] = useState("")
    let [validate, setValidate] = useState({
        validateStatus: "",
        errorMsg: null
    })
    let [companyTypes, setCompanyTypes] = useState([])
    let [lookupCustomersVisible, setLookupCustomersVisible] = useState(false)
    let [methods, setMethods] = useState([])
    let [level, setLevel] = useState([])
    let [id2day, setId2Day] = useState({})
    let [isIdCard, setIsIdCard] = useState(false)
    let [colors, setColors] = useState([])
    let [vehicle, setVehicle] = useState({
        brandName: '',
        seriesName: ''
    })

    useEffect(() => {
        let params = {
            taxonomy: "isRequireIdcard",
            ownerId: ''
        }
        common.loadingStart()
        common.ajax('get', '/crm/setting', params).then(data => {
            if (data != null && data.flag == '1') {
                setIsIdCard(true)
            }
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        if (match.params.id) {
            common.loadingStart()
            common.ajax("get", "/crm/task/findById", {id: match.params.id}, {useToken: true}).then(data => {
                data.customerTypeId = data.companyType
                setVehicle({...data})
                common.ajax("get", "/passport/region/findByIds", {ids: [data.provinceId.toString(), data.cityId.toString(), data.districtId.toString()]}).then(regionRes => {

                    let str = ""

                    regionRes.forEach((v, i) => {
                        str += v.name
                    })

                    setAddress(str)

                    setState(state => {
                        return {
                            ...state,
                            createdAt: data.createdAt,
                            creatorId: data.creatorId,
                            arrivalTime: moment(data.arrivalTime, "YYYY-MM-DD HH:mm"),
                            name: data.name,
                            sex: data.sex,
                            phone: data.phone,
                            phone2: data.phone2,
                            wechat: data.wechat,
                            qq: data.qq,
                            email: data.email,
                            remark: data.remark,
                            work: data.work,
                            idCard: data.idCard,
                            idcard: data.idCard,
                            address: data.address,
                            saleId: data.saleId,
                            provinceId: data.provinceId.toString(),
                            cityId: data.cityId.toString(),
                            districtId: data.districtId.toString(),
                            sourceId: data.sourceId === null ? "" : data.sourceId,
                            channelId: data.channelId === null ? "" : data.channelId,
                            productId: data.productId,
                            productName: data.productName,
                            status: data.status,
                            leadId: data.leadId,
                            ownerId: data.ownerId,
                            saleName: data.saleName,
                            companyType: data.companyType,
                            customerTypeId: data.customerTypeId,
                            method: data.method,
                            color: data.color
                        }
                    })
                }).finally(common.loadingStop)
            }).finally(common.loadingStop)
        } else {
            setState({
                ...state,
                createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
                arrivalTime: moment(new Date().toLocaleDateString(), "YYYY-MM-DD HH:mm"),
                creatorId: common.getUser().nickname
            })
        }
    }, [match.params.id])

    useEffect(() => {
        common.loadingStart()
        common.ajax('get', '/wms/term/list', {
            taxonomy: "colorOutside",
            ownerId: common.getUser().company.id,
            status: 1,
            limit: 1000
        }).then(data => {
            setColors(data.terms)
        }).finally(common.loadingStop)
    }, [])

    useEffect(() => {
        common.loadingStart()

        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "channel"}).then(data2 => {
            data2 = data2.filter(item => item.status != 2)
            let obj = []

            data2.forEach((v, i) => {
                obj.push(
                    <Option value={v.id} key={v.id}>{v.name}</Option>
                )
            })

            setChannel(obj)

            common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "source"}).then(data3 => {
                data3 = data3.filter(item => item.status != 2)
                let obj2 = []

                data3.forEach((v, i) => {
                    obj2.push(
                        <Option value={v.id} key={v.id}>{v.name}</Option>
                    )
                })

                setSource(obj2)

                common.ajax("get", "/passport/term/findByTaxonomy", {taxonomy: "customerType"}).then(data4 => {
                    data4 = data4.filter(item => item.status != 2)
                    let obj = {}
                    data4.map(item => obj = {...obj, [item.id]: item})

                    common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "method"}).then(methodRes => {
                        methodRes = methodRes.filter(item => item.status != 2)
                        setMethods(methodRes)
                        common.ajax("get", "/crm/term/findByTaxonomy", {taxonomy: "level"}).then(levelRes => {
                            levelRes = levelRes.filter(item => item.status != 2)
                            levelRes.sort(function(a, b) {
                                return a.sort - b.sort
                            })
                            common.ajax("get", "/crm/level/list", {ownerId: common.getUser().company.id}).then(levelDeRes => {
                                let id2day = {}
                                levelDeRes.map(item => {
                                    id2day[item.termId] = item.val
                                })
                                setId2Day(id2day)
                            }).finally(common.loadingStop)
                            setLevel(levelRes)
                        }).finally(common.loadingStop)
                    }).finally(common.loadingStop)

                    setCompanyTypes(data4)
                }).finally(() => {
                    common.loadingStop()
                })
            }).finally(() => {
                common.loadingStop()
            })
        }).finally(() => {
            common.loadingStop()
        })
    }, [])

    function toSubmit() {

        let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
        let isPhone = /^1\d{10}$/

        if (state.phone !== "" && !isPhone.test(state.phone) && !isMap.test(state.phone)) {
            common.alert("常用电话有误")
            return
        }

        if (state.phone2 !== "" && !isPhone.test(state.phone) && !isMap.test(state.phone)) {
            common.alert("备用电话有误")
            return
        }

        if (state.idCard !== "") {
            let req2 = /(^\d{15}$)|(^\d{17}([0-9]|X)$)/

            if (!req2.test(state.idCard)) {
                common.alert("身份证号有误")
                return
            }
        }

        if (state.email !== "") {
            let emailTest = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/

            if (!emailTest.test(state.email)) {
                common.alert("邮箱格式有误")
                return
            }
        }

        if (!state.name) {
            common.alert("姓名必填")
            return
        }

        if (state.name != '' && !isNaN(state.name)) {
            common.alert("姓名格式有误")
            return;
        }

        if (!state.phone) {
            common.alert("请输入常用电话")
            return;
        }

        if (isIdCard && !state.idCard) {
            common.alert("请输入身份证号")
            return;
        }

        if (!state.productName) {
            common.alert("意向车型不能为空")
            return
        }

        let params = {
            id: match.params.id,
            arrivalTime: moment(state.arrivalTime).format('YYYY-MM-DD HH:mm'),
            ownerId: common.getUser().company.id,
            sourceId: state.sourceId,
            channelId: state.channelId,
            name: state.name,
            sex: state.sex,
            phone: state.phone,
            phone2: state.phone2,
            wechat: state.wechat,
            qq: state.qq,
            email: state.email,
            idCard: state.idCard,
            work: state.work,
            provinceId: state.provinceId,
            cityId: state.cityId,
            districtId: state.districtId,
            address: state.address,
            productId: state.productId,
            status: state.status,
            remark: state.remark,
            companyType: state.companyType,
            method: state.method,
            color: state.color
        }

        // 新增和修改的时候 如果可以修改 就提示要选择
        if (common.can("crm.task.update.saleId")) {
            if (state.saleId === undefined || state.saleId === null || state.saleId === "") {
                common.alert("请选择销售顾问")
                return
            }
            params.saleId = state.saleId
        }

        if (match.params.id) {
            common.ajax("post", "/crm/task/update", params, {useToken: true}).then(data => {
                common.toast("维护成功")
            }).finally(() => {
                common.loadingStop()
            })
        } else {
            if (!state.levelId) {
                common.loadingStop()
                common.alert("请选择客户级别")
                return;
            }

            if (!state.provinceId || !state.cityId || !state.districtId || state.districtId === '0') {
                common.loadingStop()
                common.alert('请选择省市区')
                return
            }

            params.comparison = ''
            params.levelId = state.levelId
            common.ajax("post", "/crm/task/create", params).then(data => {
                common.toast("创建成功")
                history.push('/crm/task')
            }).finally(() => {
                common.loadingStop()
            })
        }
    }

    function onPhoneChange(value) {
        let isPhone = /^1\d{10}$/

        if (!isPhone.test(value)) {
            setValidate({
                validateStatus: "error",
                errorMsg: "格式有误"
            })
            setState({...state, phone: value})
        } else {
            setValidate({
                validateStatus: "success",
                errorMsg: null
            })
            setState({...state, phone: value})
        }
    }

    const onGetPhoneCity = (e) => {
        if (e.target.value) {
            let phone = e.target.value
            if (/^1\d{10}$/.test(phone)) {
                common.ajax("get", "/passport/location/findByMobile", {mobile: phone}).then(data => {
                    if (data && data.city) {
                        setState({
                            ...state,
                            phoneCity: data.city
                        })
                    }
                }).finally(() => {
                    // common.loadingStop()
                })
            }
        }
    }

    return (
        <div className="maintain">
            <div className="myCreateForm">

                <PageTop title={match.params.id ? "潜客维护" : "创建潜客"}>
                    {
                        match.params.id ?
                            <Button icon={<SaveOutlined />} disabled={state.status === STATUS_CONDUCT ? false : true}
                                type="primary" onClick={() => {
                                    toSubmit()
                                }}>保存</Button>
                            :
                            <Button icon={<SaveOutlined />} type="primary" onClick={() => {
                                toSubmit()
                            }}>保存</Button>
                    }
                    {
                        state.status === STATUS_CONDUCT && match.params.id
                            ? <Button icon={<RetweetOutlined />}
                                onClick={() => {
                                    let isMap = /^(0[0-9]{2,3}-)([2-9][0-9]{6,7})+(-[0-9]{1,4})?$/
                                    let isPhone = /^1\d{10}$/

                                    if (state.phone !== "" && !isPhone.test(state.phone) && !isMap.test(state.phone)) {
                                        common.alert("常用电话有误")
                                        return
                                    }

                                    if (state.phone2 !== "" && !isPhone.test(state.phone) && !isMap.test(state.phone)) {
                                        common.alert("备用电话有误")
                                        return
                                    }

                                    if (!state.idCard) {
                                        common.alert("请先填写身份证号并保存")
                                        return
                                    }

                                    if (!state.channelId) {
                                        common.toast('客户渠道必填')
                                        return
                                    }
                                    setLookupCustomersVisible(true)
                                }}>潜客转订单</Button>
                            : null
                    }
                    <Button icon={<RollbackOutlined />} onClick={() => {
                        history.push('/crm/task')
                        // Search.back()
                    }}>返回</Button>
                </PageTop>

                <Form className={'ant-advanced-inline-form'}>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="客户姓名" required={true} className='label-character-4'>
                                <Input value={state.name}
                                    autoComplete="off"
                                    onChange={e => {
                                        setState({...state, name: e.target.value})
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="信息来源" className='label-character-4'>
                                <Select value={state.sourceId}
                                    onChange={value => {
                                        state = {...state, sourceId: value}
                                        setState(state)
                                    }}>
                                    {source}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="微信号" className='label-character-4'>
                                <Input value={state.wechat}
                                    autoComplete="off"
                                    onChange={v => setState({...state, wechat: v.target.value})} />
                            </Form.Item>
                        </Col>
                        {common.can('crm.task.update.saleId') ?
                            <Col span={6}>
                                <Form.Item label="销售顾问" required={true} className='label-character-4'>
                                    <Input
                                        type="text"
                                        placeholder={'选择销售顾问'}
                                        suffix={
                                            state.saleName !== "" ?
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setState({...state, saleName: "", saleId: ""})
                                                    }} /> : <span />
                                        }
                                        addonAfter={
                                            <SelectOutlined
                                                onClick={() => {
                                                    setLookupEmployeeVisible(true)
                                                }} />
                                        }
                                        onKeyPress={() => {
                                            setLookupEmployeeVisible(true)
                                        }}
                                        value={
                                            state.saleName
                                        }
                                    />
                                </Form.Item>
                            </Col> :
                            <Col span={6}>
                                <Form.Item label="销售顾问" className='label-character-4'>
                                    <Input autoComplete="off" value={state.saleName} disabled />
                                </Form.Item>
                            </Col>}
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="　　性别" className='label-character-4'>
                                <Select value={state.sex} onChange={e => setState({...state, sex: e})}>
                                    <Option value={0}>不详</Option>
                                    <Option value={1}>男</Option>
                                    <Option value={2}>女</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="客户渠道" className='label-character-4' required={true} >
                                <Select value={state.channelId} onChange={v => {
                                    state = {...state, channelId: v}
                                    setState(state)
                                }}>
                                    {channel}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="腾讯QQ" className='label-character-4'>
                                <Input value={state.qq}
                                    autoComplete="off"
                                    onChange={v => {
                                        setState({...state, qq: v.target.value})
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="到店日期" className='label-character-4'>
                                <DatePicker
                                    disabled={match.params.id}
                                    showTime
                                    value={state.arrivalTime}
                                    onChange={(val) => {
                                        setState({...state, arrivalTime: val})
                                    }}
                                    format={'YYYY-MM-DD HH:mm'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="常用电话" required={true} help={validate.errorMsg}
                                validateStatus={validate.validateStatus}
                                className='label-character-4'>
                                <Input value={state.phone}
                                    autoComplete="off"
                                    onChange={e => {
                                        onPhoneChange(e.target.value)
                                    }} onBlur={onGetPhoneCity} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="号码属地" className='label-character-4'>
                                <Input value={state.phoneCity} readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="邮箱号" className='label-character-4'>
                                <Input value={state.email}
                                    autoComplete="off"
                                    onChange={v => {
                                        setState({...state, email: v.target.value})
                                    }} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="工作单位" className='label-character-4'>
                                <Input value={state.work} autoComplete="off"
                                    onChange={v => {
                                        setState({...state, work: v.target.value})
                                    }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="意向车型"
                                required={true}
                                className='label-character-4'>
                                <Input autoComplete="off"
                                    type="text"
                                    value={state.productName}
                                    placeholder={'选择车辆'}
                                    suffix={
                                        state.productName !== "" && state.productName !== null ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setState({
                                                        ...state,
                                                        productName: "",
                                                        productId: ""
                                                    })
                                                    setVehicle({brandName: '', seriesName: ''})
                                                }} /> : <span />
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupProductVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setLookupProductVisible(true)
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="身份证号" required={isIdCard} className='label-character-4'>
                                <Input value={state.idCard} autoComplete="off"
                                    onChange={v => setState({
                                        ...state,
                                        idCard: v.target.value,
                                        idcard: v.target.value
                                    })} />
                            </Form.Item>
                        </Col>
                        {
                            !match.params.id &&
                            <Col span={6}>
                                <Form.Item label="客户级别" required={true} className='label-character-4'>
                                    <Select value={state.levelId} onChange={v => {
                                        setState({...state, levelId: v})
                                    }}>
                                        {
                                            level.map(item => {
                                                return <Option value={item.id} key={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="所属品牌" className='label-character-4'>
                                <Input autoComplete="off" disabled value={vehicle.brandName} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="所属车系" className='label-character-4'>
                                <Input autoComplete="off" disabled value={vehicle.seriesName} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="主要联系方式">
                                <Select value={state.method} onChange={v => {
                                    setState({...state, method: v})
                                }}>
                                    {
                                        methods.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="下次跟进" className='label-character-4'>
                                <Input autoComplete="off" readOnly value={moment().add(id2day[state.levelId] || 0, 'day').format('YYYY-MM-DD')} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="所属区域" required={!match.params.id ? true : false}
                                className='label-character-4'>
                                <Input autoComplete="off"
                                    type="text"
                                    placeholder={'选择住址'}

                                    suffix={
                                        address !== "" ?
                                            <CloseOutlined
                                                onClick={() => {
                                                    setAddress("")
                                                    setState({
                                                        ...state,
                                                        provinceId: "",
                                                        cityId: "",
                                                        districtId: ""
                                                    })
                                                }} /> : <span></span>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setLookupAddressVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setLookupAddressVisible(true)
                                    }}
                                    value={
                                        address
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="详细地址" className='label-character-4'>
                                <Input autoComplete="off" value={state.address} onChange={v => setState({
                                    ...state,
                                    address: v.target.value
                                })} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"外观颜色"} className='label-character-4'>
                                <Select value={state.color} onChange={e => {
                                    setState({
                                        ...state,
                                        color: e
                                    })
                                }}>
                                    {
                                        colors.map(item => {
                                            return <Select.Option value={item.name}
                                                key={item.id}>{item.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={"潜客类型"} className='label-character-4'>
                                <Select value={state.companyType}
                                    onChange={e => {
                                        setState({...state, companyType: e, customerTypeId: e})
                                    }}>
                                    {
                                        companyTypes.map(item => {
                                            return <Option value={item.id} key={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="登记时间" className='label-character-4'>
                                <Input autoComplete="off" value={state.createdAt} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="登记人" className='label-character-4'>
                                <Input autoComplete="off" value={state.creatorId} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="单据备注" className='label-character-4'>
                                {/* <Input.TextArea autoSize={{minRows: 1}} value={state.remark} onChange={e => {
                                    setState({...state, remark: e.target.value})
                                }} /> */}
                                <Input value={state.remark} onChange={e => {
                                    setState({...state, remark: e.target.value})
                                }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <div>
                    <Modal title="选车型"
                        maskClosable={false}
                        destroyOnClose={true}
                        visible={lookupProductVisible}
                        width={1000}
                        footer={null}
                        onCancel={() => {
                            setLookupProductVisible(false)
                        }}>

                        <LookupProduct onOk={(val) => {
                            setLookupProductVisible(false)
                            setState({...state, productId: val.id, productName: val.name})
                            setVehicle({...val})
                        }} />

                    </Modal>
                </div>
            </div>
            <div>
                <Modal title="选择员工"
                    maskClosable={false}
                    visible={lookupEmployeeVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupEmployeeVisible(false)
                    }}>

                    <LookupEmployee
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setLookupEmployeeVisible(false)
                            setState({...state, saleId: value.id, saleName: value.name})
                        }}
                        isMultiple={false}  //如果想单选，传false
                    />
                </Modal>
            </div>
            <div>
                <Modal title="选择省市区"
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={lookupAddressVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupAddressVisible(false)
                    }}>

                    <LookupAddress onOk={(val) => {
                        setLookupAddressVisible(false)

                        setAddress(val.provinceName + val.cityName + val.districtName)
                        setState({
                            ...state,
                            provinceId: val.provinceId,
                            cityId: val.cityId,
                            districtId: val.districtId
                        })
                        // common.consoleLog(val.provinceId, val.cityId, val.districtId)

                        // setAddress(val.provinceName + val.cityName + val.districtName)
                    }} />

                </Modal>
            </div>
            <div>
                <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    visible={lookupCustomersVisible}
                    width={1000}
                    footer={null}
                    onCancel={() => {
                        setLookupCustomersVisible(false)
                    }}>
                    <CustomersCreate state={state} address={address} taskId={match.params.id}
                        history={props.history} />
                </Modal>
            </div>
        </div>
    );
}

export default MaintainTask
