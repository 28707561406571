import React, {useEffect, useState} from "react"
import {Button, Col, Radio, Divider, Form, Input, Row, Pagination, Empty} from "antd"

import common from "../../../../utils/common"
import PageTop from "../../../../components/layout/PageTop";
import {SaveOutlined} from "@ant-design/icons";
import moment from "moment";
import {Ticket, Coupon} from "../../../../components/coupon/config";

function UseStatus(props) {
    let {defaultValue, onOk, member} = props
    const [useStatusList, setUseStatusList] = useState([])
    const [useStatusMax, setUseStatusMax] = useState('0.00')
    const [useVehicleList, setUseVehicleList] = useState([])
    const [useStatusParams, setUseStatusParams] = useState({
        page: 1,
        limit: 15,
        memberId: member.memberId,
        statuses: '1',
        vehicleId: ''
    })
    const [useStatusTotal, setUseStatusTotal] = useState(0)
    const getUseStatus = () => {
        if (useStatusParams.memberId) {
            let _params = {
                ownerId: common.getUser().company.id,
                statuses: '1,2,3,6',
                code: '',
                vin: '',
                plate: '',
                isSend: -1,
                phone: '',
                type: -1,
                show: 1,
                category1: '',
                createdAtStart: '',
                createdAtEnd: '',
                date2: '',
                customerName: '',
            }
            common.loadingStart();
            common.ajax("get", "/coupon/ticket/list", {
                ..._params,
                ...useStatusParams,
                memberId: member.memberId,
            }).then(data => {
                if (data.tickets) {
                    setUseStatusList(data.tickets)
                }
                if (data.subtotal) {
                    setUseStatusMax(data.subtotal.reduceMax)
                } else {
                    setUseStatusMax('0.00')
                }
                setUseStatusTotal(data.pagination.total)
            }).finally(() => {
                common.loadingStop()
            })

            common.ajax("get", "/support/vehicle/list", {
                ownerId: common.getUser().company.id,
                customerId: useStatusParams.memberId
            }).then(data => {
                if (data && data.vehicles) {
                    setUseVehicleList(data.vehicles)
                }
            }).finally(() => {
                common.loadingStop()
            })

        }
    }

    useEffect(() => {
        getUseStatus()
    }, [member.memberId, useStatusParams])

    return (
        <>
            <Row style={{marginBottom: '15px'}}>
                <Col span={24}><h4>{member.memberName}({member.memberMobile})<span
                    style={{marginLeft: '15px'}}>礼券总额：￥{useStatusMax}</span></h4></Col>
                <Col span={24}>
                    <div style={{marginBottom: '5px'}}>
                        车辆信息：
                        <Radio.Group style={{marginRight: '5px'}} options={[{
                            label: '全部',
                            value: ''
                        }, ...useVehicleList.map(item => item = {label: item.plate, value: item.id})]}
                            onChange={(e) => {
                                setUseStatusParams({
                                    ...useStatusParams,
                                    vehicleId: e.target.value,
                                    page: 1
                                })
                            }} value={useStatusParams.vehicleId} optionType="button" buttonStyle="solid" />
                        卡券状态：
                        <Radio.Group options={[
                            {label: '未使用', value: '1'},
                            {label: '已锁定', value: '2'},
                            {label: '已使用', value: '3'},
                            {label: '已核销', value: '6'},
                            {label: '全部', value: '1,2,3,6'},
                        ]} onChange={(e) => {
                            setUseStatusParams({
                                ...useStatusParams,
                                statuses: e.target.value,
                                page: 1
                            })
                        }} value={useStatusParams.statuses} optionType="button" buttonStyle="solid" />
                    </div>
                </Col>
            </Row>


            <Row style={{marginBottom: '15px'}}>
                {
                    useStatusList && useStatusList.map((item, index) => {
                        return (
                            <Col key={index} span={8}>
                                <div style={{
                                    border: '1px solid #f1f1f1',
                                    padding: '4px',
                                    margin: '2px',
                                    borderRadius: '4px'
                                }}>
                                    <div style={{display: 'flex'}}>
                                        <strong>{item.planName}</strong>
                                    </div>
                                    <div style={{display: 'flex', color: '#444'}}>
                                        礼券编号：{item.code}
                                        <div style={{width: 0, flex: 1}}></div>
                                        {Ticket.StatusAlias ? Ticket.StatusAlias[item.status] : '-'}
                                    </div>
                                    {/* <div style={{display: 'flex', color: '#444'}}>客户名称：{item.customerName}</div> */}
                                    {/* <div style={{display: 'flex', color: '#444'}}>客户电话：{item.customerPhone}</div> */}
                                    <div style={{display: 'flex', color: '#444'}}>
                                        车牌号：{item.plate}
                                        <div style={{width: 0, flex: 1}}></div>
                                        {item.couponDto && Coupon.Category1s && Coupon.Category1s[item.couponDto.category1]}
                                    </div>
                                    <div style={{display: 'flex', color: '#444'}}>车架号：{item.vin}
                                        <div style={{width: 0, flex: 1}}></div>
                                        ￥{item.couponDto.reduceMax}
                                    </div>
                                    <div style={{color: '#444'}}>生效时间：{item.beginDate}</div>
                                    <div style={{color: '#444'}}>到期时间：{item.endDate}</div>
                                    <div style={{color: '#444'}}>创建时间：{item.createdAt}</div>
                                </div>
                            </Col>
                        )
                    })
                }
                {
                    useStatusList.length == 0 && <Col span={24}>
                        <div style={{border: '1px solid #f1f1f1', padding: '4px', margin: '2px', borderRadius: '4px'}}>
                            <Empty description='暂无卡券' /></div>
                    </Col>
                }
            </Row>

            <Pagination
                showQuickJumper={true}
                showTotal={useStatusTotal => `共${useStatusTotal}条`}
                total={useStatusTotal}
                current={useStatusParams.page}
                showSizeChanger
                pageSizeOptions={['15', '30', '50', '100']}
                defaultPageSize={useStatusParams.limit}
                onChange={(page, limit) => {
                    setUseStatusParams({
                        ...useStatusParams,
                        page,
                        limit
                    })
                }}
                onShowSizeChange={(page, limit) => {
                    setUseStatusParams({
                        ...useStatusParams,
                        page,
                        limit
                    })
                }}
            />
        </>

    )
}

export default UseStatus