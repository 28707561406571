import React, {useEffect, useState} from 'react';
import {CloseOutlined, SaveOutlined, SelectOutlined, SearchOutlined} from '@ant-design/icons';
import {Modal, Tree, Table, Form} from 'antd';
import {Layout, Input, Button} from 'antd';
import PageTop from "../../../../components/layout/PageTop";
import MySider from "./component/sider";
import LookupCompany from "../../../../components/passport/LookupCompany";
import common from "../../../../utils/common";
import "./style.less"
import {Payable} from "../../../../components/finance/config";
import {PassportSetting, UserData} from "../../../../components/passport/config";

const {Sider} = Layout;
const FormItem = Form.Item;

function User(props) {

    let {type} = props.match.path === '/admin/passport/authority/user' ? 'user' : props.match.path === '/admin/passport/authority/department' ? 'department' : 'warehouse'

    //选择公司弹框
    let [companyIdNames, setCompanyIdNames] = useState("")
    let [companyId, setCompanyId] = useState("")
    let [lookupCompanyVisible, setLookupCompanyVisible] = useState(false)

    //公司树状层级数据
    let [employees, setEmployees] = useState([])                 //员工数据
    let [departmentList, setDepartmentList] = useState([])       //部门数据
    let [companyLevelList, setCompanyLevelList] = useState([])   //公司层级数据

    let [operationList, setOperationList] = useState([])         //操作公司
    let [roles, setRoles] = useState([])                         //角色数据

    let [checkRoles, setCheckRoles] = useState([])              //选择的角色数据
    let [checkEmployeeId, setCheckEmployeeId] = useState("")    //选择的员工ID
    let [checkCompanyId, setCheckCompanyId] = useState("")      //选择的公司ID

    let [search, setSearch] = useState('')              //提交ajax搜索值

    const formItemLayout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    let getType = () => {
        if (props.match.path === '/admin/passport/authority/user') {
            return 'user'
        } else if (props.match.path === '/admin/passport/authority/department') {
            return 'department'
        } else if (props.match.path === '/admin/passport/authority/warehouse') {
            return 'warehouse'
        } else if (props.match.path === '/admin/passport/authority/financeBusinessType') {
            return 'financeBusinessType'
        } else if (props.match.path === '/admin/passport/authority/normal') {
            return 'normal'
        } else if (props.match.path === '/admin/passport/authority/coupon') {
            return 'coupon'
        }
    }

    let getTitle = () => {
        if (props.match.path === '/admin/passport/authority/user') {
            return '用户权限设置'
        } else if (props.match.path === '/admin/passport/authority/department') {
            return '部门权限设置'
        } else if (props.match.path === '/admin/passport/authority/warehouse') {
            return '仓库权限设置'
        } else if (props.match.path === '/admin/passport/authority/financeBusinessType') {
            return '付款申请-业务类型权限设置'
        } else if (props.match.path === '/admin/passport/authority/normal') {
            return '普通权限设置'
        } else if (props.match.path === '/admin/passport/authority/coupon') {
            return '礼券权限设置'
        }
        return ''
    }

    let getEmployeeList = () => {
        let params = {
            page: 1,
            limit: 1000,
            mobile: '',
            name: '',
            status: 1,
        }

        if (search.length > 0) {
            let reg = /^1\d{10}$/;
            if (reg.test(search)) {
                params.mobile = search
            } else {
                params.name = search
            }
        }
        common.loadingStart()
        common.ajax("get", "/passport/employee/list", params).then((res) => {
            setEmployees(res.employees)
            getCompanyLevelList(departmentList, res.employees, companyId)

        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取部门
    let getDepartments = (val) => {
        setLookupCompanyVisible(false)
        setCompanyIdNames(val.nameShort)
        setCompanyId(val.id)

        common.loadingStart()
        common.ajax("get", "/passport/company/findDepartments", {'id': val.id}).then((res) => {
            setDepartmentList(res)

            getCompanyLevelList(res, employees, val.id)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取层级部门、员工数据
    let getCompanyLevelList = (paramDepartmentList, paramEmployees, companyId) => {
        let departmentList = paramDepartmentList
        let employees = paramEmployees

        if (departmentList.length < 1) {
            setCompanyLevelList([])
            return
        }

        let employeeList = []
        //员工是否存在这个公司
        for (let i = 0; i < employees.length; i++) {
            let item = employees[i]
            if (item['companyIds'] !== null && item['companyIds'].indexOf(companyId) >= 0) {
                employeeList.push(item)
                continue
            }

            if (item['companyId'] === companyId) {
                employeeList.push(item)
            }
        }

        let companyLevelList = []
        //员工是否存在部门之中，存在则将员工放入部门中
        for (let i = 0; i < departmentList.length; i++) {
            let val = departmentList[i]
            let item = {'departmentId': val['id'], 'name': val['name'], 'employees': []}
            for (let j = 0; j < employeeList.length; j++) {
                if (val['id'] === employeeList[j]['departmentId']) {
                    item['employees'].push({
                        'id': employeeList[j]['id'],
                        'name': employeeList[j]['positionName'] ? employeeList[j]['name'] + ` (${employeeList[j]['positionName']})` : employeeList[j]['name']
                    })
                }
            }

            companyLevelList.push(item)
        }

        //员工是否存在部门之中，不存在则以"公司"为部门名，将员工放入该部门中
        let emptyDepartment = {'departmentId': "", 'name': "公司", 'employees': []}
        for (let e = 0; e < employeeList.length; e++) {
            let item = employeeList[e]
            let employeeStatus = false
            for (let d = 0; d < departmentList.length; d++) {
                let item1 = departmentList[d]
                if (item1['id'] === item['departmentId']) {
                    employeeStatus = true
                }
            }

            if (!employeeStatus) {
                emptyDepartment['employees'].push({
                    'id': item['id'],
                    'name': item['positionName'] ? item['name'] + ` (${item['positionName']})` : item['name']
                })
            }
        }

        companyLevelList.push(emptyDepartment)
        companyLevelList.forEach(item => {
            item.title = item.name
            item.key = item.departmentId
            item.employees.forEach(val => {
                val.title = val.name
                val.key = val.id
            })
            item.children = item.employees
        })
        setCompanyLevelList(companyLevelList)
    }

    //选择操作范围
    let onCompanySelect = (selectVal) => {
        if (selectVal[0] === undefined) {
            setCheckCompanyId('')
            setCheckRoles([])
            return
        }

        let reg = /^title/
        if (reg.test(selectVal[0])) {
            return;
        }

        setCheckCompanyId(selectVal[0])

        if (getType() == "user") {
            //根据公司和员工获取角色
            common.loadingStart()
            common.ajax("get", "/passport/employee/findRoles", {
                'companyId': selectVal[0],
                'id': checkEmployeeId,
            }).then((res) => {
                setCheckRoles(res)
            }).finally(() => {
                common.loadingStop()
            })
        } else if (getType() == 'department') {
            getDepartment(selectVal[0])
            common.loadingStart()
            common.ajax("get", "/passport/data/findDepartmentIdsByUserId", {
                companyId: selectVal[0],
                employeeId: checkEmployeeId
            }).then((res) => {
                setCheckRoles(res)
            }).finally(() => {
                common.loadingStop()
            })
        } else if (getType() == 'coupon') {
            getDepartment(selectVal[0])
            common.loadingStart()
            common.ajax("get", "/passport/data/findList", {
                companyId: selectVal[0],
                employeeId: checkEmployeeId,
                type: UserData.TYPE_COUPON
            }).then((res) => {
                setCheckRoles(res)
            }).finally(() => {
                common.loadingStop()
            })
        } else if (getType() == 'warehouse') {
            getWarehouse(selectVal[0])
            common.loadingStart()
            common.ajax('get', '/passport/data/findWarehouseIdsByUserId', {
                companyId: selectVal[0],
                employeeId: checkEmployeeId
            }).then(res => {
                setCheckRoles(res)
            }).finally(common.loadingStop)
        } else if (getType() === 'financeBusinessType') {
            getFinanceBusinessTypeList()
            common.loadingStart()
            common.ajax('get', '/passport/data/findFinanceBusinessTypeListByUserId', {
                companyId: selectVal[0],
                employeeId: checkEmployeeId
            }).then(res => {
                setCheckRoles(res)
            }).finally(common.loadingStop)
        } else if (getType() === 'normal') {
            getNormalList()
            common.loadingStart()
            common.ajax('get', '/passport/data/findNormalListByUserId', {
                companyId: selectVal[0],
                employeeId: checkEmployeeId
            }).then(res => {
                setCheckRoles(res)
            }).finally(common.loadingStop)
        }
    }

    //选择员工
    let onEmployeeSelect = (selectVal) => {
        if (selectVal[0] === undefined) {
            setCheckEmployeeId('')
            return
        }

        let id = selectVal[0]
        let employee = {}
        for (let e = 0; e < employees.length; e++) {
            if (id === employees[e]['id']) {
                employee = employees[e]
            }
        }

        let bool = Object.keys(employee).length > 0
        if (!bool) {
            return;
        }

        setCheckEmployeeId(id)

        //操作范围（公司数据）
        common.loadingStart()
        common.ajax("get", "/passport/employee/findById", {
            'id': id,
        }).then((res) => {

            let companyId = res.companyId !== undefined ? res.companyId : ''     //公司ID  员工归属   必填
            let companyIds = res.companyIds !== undefined ? res.companyIds : []  //跨经营主体

            companyIds = [companyId].concat(companyIds);
            getCompanys(companyIds)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取所有角色
    let getRoles = () => {
        common.loadingStart()
        common.ajax("get", "/passport/role/all", {}).then((res) => {
            res.forEach(val => {
                val.title = val.name
                val.key = val.id
            })
            let Data = [{title: "角色名称", key: "title1", children: res}]

            setRoles(Data)
        }).finally(() => {
            common.loadingStop()
        })
    }

    //获取所有部门
    let getDepartment = (id) => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findDepartments", {id: id}).then(res => {
            res.forEach(val => {
                val.title = val.name
                val.key = val.id
            })
            let Data = [{title: "部门名称", key: "title1", children: res}]

            setRoles(Data)
        }).finally(common.loadingStop)
    }

    //获取所有仓库
    let getWarehouse = (id) => {
        common.loadingStart()
        common.ajax('get', '/wms/warehouse/findByCompanyId', {companyId: id}).then(res => {
            res.forEach(item => {
                item.title = item.name
                item.key = item.id
            })
            let Data = [{title: "仓库名称", key: "title1", children: res}]
            setRoles(Data)
        }).finally(common.loadingStop)
    }

    // 获取所有业务类型
    let getFinanceBusinessTypeList = () => {
        let arr = []
        for (let key in Payable.businessTypeAlias) {
            arr.push({
                'title': Payable.businessTypeAlias[key],
                'key': key
            })
        }
        setRoles([{title: "业务类型", key: "title1", children: arr}])
    }

    // 获取普通权限
    let getNormalList = () => {
        let arr = [
            {
                'title': "修改工单车牌号",
                'key': "updateSupportPlate"
            },
            {
                'title': "订单汇总是否显示[销售金额]",
                'key': "appAccessorySummaryTotal"
            },
            {
                'title': "订单汇总是否显示[4s店销售提成]",
                'key': "appAccessorySummary4s"
            },
            {
                'title': "订单汇总是否显示[店端销售提成]",
                'key': "appAccessorySummaryCommission"
            },
            {
                'title': "绩效管理是否可以设置[日激励]",
                'key': "appAccessoryPerformanceIncentive"
            },
            {
                'title': "绩效管理是否可以设置[4s店销售提成]",
                'key': "appAccessoryPerformance4s"
            },
            {
                'title': "绩效管理是否可以设置[店端销售提成]",
                'key': "appAccessoryPerformanceRoyalty"
            },
            {
                'title': "订正加装订单报表数据",
                'key': "updateAccessoryReport"
            },
            {
                'title': "默认厂家采购入库供货商的设置权限",
                'key': PassportSetting.TAXONOMY_PARTNER_SUPPLIER_ALLOW_UPDATE
            },
            {
                'title': "应收款单据-退款按钮",
                'key': PassportSetting.TAXONOMY_FINANCE_RECEIPT_RECEIVABLE_REFUND
            },
        ]
        setRoles([{title: "普通权限", key: "title1", children: arr}])
    }

    //获取员工的所属公司和跨经营主体的数据
    let getCompanys = (ids) => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findByIds", {
            "ids": ids
        }).then((res) => {
            let operationList = []
            let company = {'title': '所属公司', 'children': []}
            let companys = {'title': '经营主体', 'children': []}
            for (var i = 0; i < res.length; i++) {
                if (i === 0) {
                    company['children'].push({
                        'id': res[i]['id'],
                        'name': res[i]['name'],
                    })
                } else {
                    companys['children'].push({
                        'id': res[i]['id'],
                        'name': res[i]['name'],
                    })
                }
            }

            if (res.length > 0) {
                operationList.push(company)
            }

            if (res.length > 1) {
                operationList.push(companys)
            }
            operationList.forEach((item, index) => {
                item.key = "title" + index
                item.children.forEach(val => {
                    val.title = val.name
                    val.key = val.id
                })
            })
            setOperationList(operationList)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 选择角色
    let onRoleCheck = (checkedKeys) => {
        if (checkEmployeeId.length < 1) {
            common.alert('请先选择员工')
            return
        }

        if (checkCompanyId.length < 1) {
            common.alert('请先选择操作范围')
            return
        }

        let checkArr = []
        for (let i = 0; i < checkedKeys.length; i++) {
            let item = checkedKeys[i]
            if (item !== 'all' && item !== 'title1') {
                checkArr.push(item)
            }
        }
        setCheckRoles(checkArr)
    }

    let taskList = [
        {
            id: '1',
            spec: 'John Brown',
        },
        {
            id: '2',
            spec: 'Brown John',
        },
    ]

    const columns = [
        {
            title: '权限名称',
            dataIndex: 'spec',
            key: 'spec',
            width: 100
        },
        {
            title: '模块名称',
            dataIndex: 'id',
            key: 'id',
            width: 100
        },
    ];

    //员工搜索
    let submitSearch = () => {
        initData()
        getEmployeeList()
    }

    let save = () => {
        common.loadingStart()
        if (getType() === "user") {
            common.ajax("post", "/passport/employee/assignRoles", {
                id: checkEmployeeId,
                companyId: checkCompanyId,
                roleIds: checkRoles
            }).then((res) => {
                common.toast('保存成功')
            }).finally(() => {
                common.loadingStop()
            })
        } else if (getType() === 'department') {
            common.ajax("post", "/passport/data/assignDepartments", {
                employeeId: checkEmployeeId,
                companyId: checkCompanyId,
                departmentIds: checkRoles,
                ownerId: ""
            }).then((res) => {
                common.toast('保存成功')
            }).finally(() => {
                common.loadingStop()
            })
        } else if (getType() === 'coupon') {
            common.ajax("post", "/passport/data/assign", {
                employeeId: checkEmployeeId,
                companyId: checkCompanyId,
                departmentIds: checkRoles,
                ownerId: "",
                type: UserData.TYPE_COUPON
            }).then((res) => {
                common.toast('保存成功')
            }).finally(() => {
                common.loadingStop()
            })
        } else if (getType() === 'warehouse') {
            common.ajax('post', '/passport/data/assignWarehouses', {
                companyId: checkCompanyId,
                employeeId: checkEmployeeId,
                ownerId: '',
                warehouseIds: checkRoles
            }).then(res => {
                common.toast('保存成功')
            }).finally(common.loadingStop)
        } else if (getType() === 'financeBusinessType') {
            common.ajax('post', '/passport/data/assignFinanceBusinessTypeList', {
                companyId: checkCompanyId,
                employeeId: checkEmployeeId,
                ownerId: '',
                businessTypeList: checkRoles
            }).then(res => {
                common.toast('保存成功')
            }).finally(common.loadingStop)
        } else if (getType() === 'normal') {
            common.ajax('post', '/passport/data/assignNormal', {
                companyId: checkCompanyId,
                employeeId: checkEmployeeId,
                ownerId: '',
                normalTypeList: checkRoles
            }).then(res => {
                common.toast('保存成功')
            }).finally(common.loadingStop)
        }
    }

    //初始化数据
    let initData = () => {
        setCompanyLevelList([])   //公司层级数据
        setOperationList([])      //操作公司数据
        setCheckRoles([])         //选择角色数据
        setCheckEmployeeId('')    //选择员工数据
        setCheckCompanyId('')     //选择公司数据
    }

    useEffect(getEmployeeList, [getType()])

    useEffect(() => {
        if (getType() === "user") {
            getRoles()
        } else if (getType() === 'department') {
            let Data = [{title: "部门名称", key: "title1", children: []}]
            setRoles(Data)
        } else if (getType() === 'warehouse') {
            let Data = [{title: "仓库名称", key: "title1", children: []}]
            setRoles(Data)
        } else if (getType() === 'financeBusinessType') {
            let Data = [{title: "业务类型", key: "title1", children: []}]
            setRoles(Data)
        } else if (getType() === 'normal') {
            let Data = [{title: "普通权限", key: "title1", children: []}]
            setRoles(Data)
        }
    }, [getType()])

    return (
        <div>
            <PageTop title={getTitle()}>
                <Button type="primary" icon={<SaveOutlined/>} onClick={save}>保存</Button>
            </PageTop>
            <Layout>
                <div>
                    <Modal title="选公司"
                           visible={lookupCompanyVisible}
                           width={1000}
                           footer={null}
                           onCancel={() => {
                               setLookupCompanyVisible(false)
                           }}>

                        <LookupCompany isMultiple={false}
                                       defaultCheckedIds={[companyId]}
                                       onOk={(val) => {
                                           getDepartments(val)
                                       }}/>
                    </Modal>
                </div>

                <Sider theme={"light"} style={{paddingRight: "10px"}}>
                    <Form {...formItemLayout}>
                        <FormItem label={"公司名称"}>
                            <Input
                                value={companyIdNames}
                                placeholder="选择公司"
                                suffix={
                                    companyIdNames !== "" ?
                                        <CloseOutlined
                                            onClick={() => {
                                                setCompanyIdNames("")
                                                setCompanyId('')

                                                setDepartmentList([])     //部门数据
                                                initData()
                                            }}/> : null
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setLookupCompanyVisible(true)
                                        }}/>
                                }
                                onKeyPress={() => {
                                    setLookupCompanyVisible(true)
                                }}
                            />
                        </FormItem>
                        <FormItem label={"员工搜索"}>
                            <Input placeholder={"员工姓名或手机"} addonAfter={
                                <SearchOutlined
                                    onClick={() => {
                                        submitSearch()
                                        // setLookupCompanyVisible(true)
                                    }}/>
                            } onChange={(e) => {
                                setSearch(e.target.value)
                            }}/>
                        </FormItem>
                    </Form>
                    <MySider companyLevelList={companyLevelList} checkEmployeeId={checkEmployeeId}
                             onSelect={onEmployeeSelect}/>
                </Sider>
                <div className={"right-content-div"}>
                    <div className={"left-div"}>
                        <div className={"title-div"}>操作范围</div>
                        <div className={"content-div"}>
                            <Tree
                                showLine={true}
                                autoExpandParent={true}
                                defaultExpandAll={true}
                                onSelect={onCompanySelect}
                                selectedKeys={[checkCompanyId]}
                                className="hide-file-icon"
                                treeData={operationList}
                            >
                            </Tree>
                        </div>
                    </div>
                    <div className={"middle-div"}>
                        <div className={"title-div"}>勾选权限</div>

                        <div className={"content-div"}>
                            {/*<Descriptions layout="vertical" bordered>*/}
                            {/*    <Descriptions.Item label="角色权限">*/}
                            {/*        /!*<MySider/>*!/*/}
                            {/*        <Tree*/}
                            {/*            checkable*/}
                            {/*            autoExpandParent={true}*/}
                            {/*            defaultExpandAll={true}*/}
                            {/*            onCheck={onRoleCheck}*/}
                            {/*            checkedKeys={checkRoles}*/}
                            {/*        >*/}
                            {/*            <TreeNode title="角色名称" key={"all"}>*/}
                            {/*                {*/}
                            {/*                    roles.map((item, index) => (*/}
                            {/*                        <TreeNode title={item.name} key={item.id}></TreeNode>*/}
                            {/*                    ))*/}
                            {/*                }*/}
                            {/*            </TreeNode>*/}
                            {/*        </Tree> </Descriptions.Item>*/}
                            {/*    <Descriptions.Item label="用户权限检查[已有权限]">*/}
                            {/*        /!*<MySider/>*!/*/}
                            {/*    </Descriptions.Item>*/}
                            {/*    <Descriptions.Item label="用户权限检查[已有权限]">*/}
                            {/*        /!*<MySider/>*!/*/}
                            {/*    </Descriptions.Item>*/}
                            {/*</Descriptions>*/}

                            <div className={"item-div"}>
                                {/*<div className={"title-div"}>角色权限</div>*/}
                                {/*<div className={"content-div"}>*/}
                                <Tree
                                    checkable
                                    autoExpandParent={true}
                                    defaultExpandAll={true}
                                    onCheck={onRoleCheck}
                                    checkedKeys={checkRoles}
                                    treeData={roles}
                                >
                                </Tree>
                                {/*</div>*/}
                            </div>
                            {/*<div className={"item-div"}>*/}
                            {/*    <div className={"title-div"}>仓库权限</div>*/}
                            {/*    <div className={"content-div"}>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className={"item-div"}>*/}
                            {/*    <div className={"title-div"}>单据审核范围</div>*/}
                            {/*    <div className={"content-div"}>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {/*<div className={"right-div"}>*/}
                    {/*    <div className={"title-div"}>用户权限检查[已有权限]</div>*/}
                    {/*    <div className={"content-div"}>*/}
                    {/*        <Table bordered={false} rowKey={record => record.id} scroll={{x: true}} columns={columns}*/}
                    {/*               dataSource={taskList}*/}
                    {/*               pagination={false}/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>

            </Layout>
        </div>
    );
}

export default User;
