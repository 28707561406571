import React, {useEffect, useRef, useState} from "react"
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Tooltip
} from "antd"
import common from "../../../../utils/common"
import {
    CloseOutlined, EditOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
    SaveOutlined,
    SelectOutlined,
    StopOutlined
} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";
import {Coupon, Order, Plan, TaxonomyEnum} from "../../../../components/coupon/config";
import LookupAll from "../../../../components/passport/LookupAll";
import LookupPlan from "../../../../components/coupon/LookupPlan";
import LookupVehicle from "../../../../components/support/LookupVehicle";
import BigNumber from "bignumber.js";
import moment from "moment/moment";
import CustomerForm from "../../../../components/passport/CustomerForm";
import VehiclePremiumForm from "../../../../components/support/VehiclePremiumForm";
import LookupEmployee from "../../../../components/passport/LookupEmployee";
import {SupportImages} from "../../../../components/support/config";
import './style.css'

function CouponSaleForm(props) {
    // action create=新增 update=编辑 look=查看
    let {onOk, defaultValue = {}, action = "create"} = props

    const [form] = Form.useForm()
    const {Item: FormItem} = Form

    let [list, setList] = useState([]) // 表格数据
    let [couponList, setCouponList] = useState([]) // 表格数据
    let [couponTableNumber, setCouponTableNumber] = useState(1)
    const emptyInput = useRef()
    let [newValue, setNewValue] = useState('')
    let [customer, setCustomer] = useState({});//客户
    let [creator, setCreator] = useState({});// 创建人
    let [imageUrl, setImageUrl] = useState(null);
    let [couponSaleCreateUpdateCreator, setCouponSaleCreateUpdateCreator] = useState(true)
    let [popupPrintWindow, setPopupPrintWindow] = useState(true)
    let [vehicle, setVehicle] = useState({});//车辆
    let [visible, setVisible] = useState(false) // 模态框可见性
    let [modalTitle, setModalTitle] = useState('');//模态框标题
    let [modalNumber, setModalNumber] = useState(0);
    let [company, setCompany] = useState() // 公司信息
    let [printData, setPrintData] = useState([]) // 销售订单详情
    let [customerInfo, setCustomerInfo] = useState({}) // 客户详情

    useEffect(() => {
        if (Object.keys(defaultValue).length > 0) {
            form.setFieldsValue(defaultValue)

            // 车牌号/车辆ID
            setVehicle({id: defaultValue.vehicleId, plate: defaultValue.plate, vin: defaultValue.vin, name: defaultValue.vehicleName, phone: defaultValue.vehiclePhone})
            setCustomer({id: defaultValue.memberId, name: defaultValue.memberName})
        } else {
            form.setFieldsValue({businessType: Order.BUSINESS_SALE_TYPE_SUPPORT})
        }
    }, [defaultValue])

    // 表单提交
    let onFinish = (values) => {
        if (Object.keys(defaultValue).length > 0) {
            onOk({
                vehicleId: vehicle.id === undefined ? '' : vehicle.id,
                businessType: values.businessType,
                spec: values.spec,
                businessCode: defaultValue.businessCode,
                ownerId: common.getUser().company.id,
                action: 'update'
            })
        } else {
            onOk({
                vehicleId: vehicle.id === undefined ? '' : vehicle.id,
                memberId: customer.id,
                businessType: values.businessType,
                ownerId: common.getUser().company.id,
                spec: values.spec,
                businessCode: 'any',
                popupPrintWindow: popupPrintWindow,
                plans: list,
                action: 'create',
                creatorId: creator.id
            })
        }
    }

    // 获取列表数据
    let getData = (params) => {
        let obj = {
            ownerId: common.getUser().company.id,
            status: Plan.STATUS_VALID,
            name: params,
            page: 1,
            limit: 2
        }

        if (form.getFieldValue("businessType") === Order.BUSINESS_SALE_TYPE_SALE) {
            obj.departmentIds = [Plan.DEPARTMENT_SALE, Plan.DEPARTMENT_SALE_AND_SUPPORT]
        }

        if (form.getFieldValue("businessType") === Order.BUSINESS_SALE_TYPE_SUPPORT) {
            obj.departmentIds = [Plan.DEPARTMENT_SUPPORT, Plan.DEPARTMENT_SALE_AND_SUPPORT]
        }

        common.loadingStart()
        common.ajax("get", "/coupon/plan/list", obj).then((data) => {
            if (data.plans.length > 1 || data.plans.length === 0) {
                setModalNumber(1)
                setModalTitle("方案选择")
                setVisible(true)
            } else {
                // 添加一行
                planOnOk(data.plans)
                setTimeout(() => {
                    emptyInput.current.focus()
                }, 100)
            }
            setNewValue('')
        }).finally(() => {
            common.loadingStop()
        })
    }

    const columns = [
        {
            title: '方案名称',
            dataIndex: 'name',
            width: 180,
            ellipsis: true,
            render: (text, record) => {
                if (!record.isEmptyRecord) {
                    return (
                        <Tooltip
                            placement={'topLeft'}
                            title={<StopOutlined />}
                        ><Input value={text} readOnly />
                        </Tooltip>
                    )
                }

                return (
                    <Input
                        ref={emptyInput}
                        value={newValue}
                        autoComplete="off"
                        placeholder={"输入方案名称 回车"}
                        addonAfter={
                            <SelectOutlined
                                onClick={() => {
                                    setModalNumber(1)
                                    setModalTitle('方案选择')
                                    setVisible(true)
                                }} />
                        }
                        onChange={(e) => {
                            setNewValue(e.target.value)
                        }}

                        onKeyDown={(e) => {
                            if (newValue.length > 0 && e.keyCode === 13) { // Enter
                                // ajax查询数据
                                getData(newValue)
                            }
                        }}
                    />
                )
            }
        },
        {
            title: '编号',
            width: 100,
            dataIndex: 'code',
            ellipsis: true
        },
        {
            title: '售价',
            width: 100,
            dataIndex: 'price',
            align: 'right',
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 110,
            ellipsis: true,
            render: (text, record, index) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <InputNumber
                        precision={0}
                        min={1}
                        value={text}
                        onChange={(val) => {
                            setList(list.map(item => {
                                if (item.id === record.id) {
                                    return {
                                        ...item,
                                        quantity: val,
                                        saleQuantity: (val === '' || val === null) ? item.quantity : item.quantity * val
                                    }
                                }

                                return item
                            }))

                            setCouponList(couponList.map(item => {
                                if (item.planName === record.name) {
                                    return {
                                        ...item,
                                        saleQuantity: (val === '' || val === null) ? item.quantity : item.quantity * val
                                    }
                                }
                                return item
                            }))
                        }}
                    />)
            }
        },
        {
            title: '操作',
            align: "center",
            width: 100,
            fixed: "right",
            ellipsis: true,
            render: (text, record) => {
                if (record.isEmptyRecord) {
                    return ''
                }
                return (
                    <div className={"action-btns"}>
                        {record.name !== "" &&
                            <Popconfirm
                                title="确定要删除吗?"
                                onConfirm={() => {
                                    let arr = []

                                    list.forEach((item) => {
                                        if (item.id !== record.id) {
                                            arr.push(item)
                                        }
                                    })
                                    setList(arr)
                                    setCouponList(couponList.filter(item => item.planId !== record.id))
                                    setCouponTableNumber(couponTableNumber + 1)
                                }}
                                okText="确定"
                                cancelText="取消">
                                <Button type={'link'}>删除</Button>
                            </Popconfirm>}
                    </div>)
            }
        }
    ]

    const columnsUpdate = [
        {
            title: '方案名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '礼券名称',
            dataIndex: 'couponName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '售价',
            width: 100,
            dataIndex: 'price',
            align: 'right',
            ellipsis: true
        },
        {
            title: '数量',
            dataIndex: 'quantity',
            width: 100,
            ellipsis: true,
            align: 'right',
        },
    ]

    const columns2 = [
        {
            title: '方案名称',
            dataIndex: 'planName',
            width: 100,
            ellipsis: true,
        },
        {
            title: '礼券名称',
            dataIndex: 'name',
            width: 100,
            ellipsis: true,
        },
        {
            title: '数量',
            width: 100,
            dataIndex: 'saleQuantity',
            ellipsis: true
        },
    ]

    // 方案选择
    let planOnOk = (val) => {
        let list = []
        val.forEach((item) => {
            item.items.forEach((item2) => {
                if (item.quantity != null && item.quantity !== 0) {
                    item2.saleQuantity = new BigNumber(item2.quantity)
                        .multipliedBy(item.quantity)
                        .toNumber()
                } else {
                    item2.saleQuantity = item2.quantity
                }
                item2.planName = item.name
                item2.name = item2.couponDto.name
                list.push(item2)
            })
        })
        setCouponList(list)

        let arr = []
        val.forEach((item1) => {
            let number = 0
            list.forEach((item2) => {
                if (item2.id === item1.id) {
                    number++;
                    arr.push(item2)
                }
            })
            if (number === 0) {
                arr.push({
                    ...item1,
                    quantity: null,
                })
            }
        })
        setList(arr)
        setVisible(false)
    }

    // 获取公司信息
    let getCompany = () => {
        common.loadingStart()
        common.ajax("get", "/passport/company/findById?id=" + common.getUser().company.id)
            .then((data) => {
                setCompany(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    // 获取订单详情
    let getPrintData = () => {
        common.loadingStart()
        common.ajax("get", "/coupon/order/detail", {
            businessCode: defaultValue.businessCode,
            ownerId: common.getUser().company.id,
        })
            .then((data) => {
                setPrintData(data)
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    // 获取客户信息
    let getCustomerInfo = () => {
        common.loadingStart()
        common.ajax("get", "/passport/customer/findById?id=" + defaultValue.memberId)
            .then((data) => {
                if (data != null) {
                    form.setFieldsValue({phone: data.mobile})
                    setCustomerInfo(data)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }
    useEffect(() => {
        if (action !== 'create') {
            getCompany()
            getPrintData()
            getCustomerInfo()
        }

        // 获取当前登录用户 为创建人
        if (action === 'create') {
            getEmployeeId()
        }
    }, [])

    // 获取当前登录者的id
    let getEmployeeId = () => {
        common.loadingStart()
        common.ajax('get', '/passport/user/findByAuthUser')
            .then(res => {
                setCreator({id: res.id, name: res.name})
                form.setFieldsValue({creatorName: res.name})
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    // 获取打印logo
    let getPrintLogo = () => {
        common.loadingStart()
        common.ajax('get', '/support/images/list', {
            ownerId: common.getUser().company.id,
            type: SupportImages.TYPE_PRINT_LOGO
        })
            .then(res => {
                if (res.length > 0 && res[0].url !== "") {
                    setImageUrl(res[0].url)
                }
            })
            .finally(() => {
                common.loadingStop()
            })
    }

    useEffect(() => {
        getPrintLogo()
    }, [])

    // 打印
    let print = () => {
        let advanceQuantity = 0// 预收券数量
        let advanceTotal = 0// 预收券金额
        let clearTotal = 0// 优惠券金额
        let total = 0;// 订单总金额
        let orderData = {}
        let productList = []// 预估材料
        let projectList = []// 工时项目
        for (let printDatum of printData) {
            // 预收券
            if (printDatum.couponDto.category1 === Coupon.CATEGORY1_ADVANCE) {
                // 预收券数量
                advanceQuantity += printDatum.quantity
                // 预收券金额
                advanceTotal = new BigNumber(advanceTotal).plus(
                    new BigNumber(printDatum.couponDto.price).multipliedBy(printDatum.quantity)
                ).toFixed(2)
            }

            // 优惠券
            if (printDatum.couponDto.category1 === Coupon.CATEGORY1_CLEAR) {
                // 优惠券金额
                clearTotal = new BigNumber(clearTotal).plus(
                    new BigNumber(printDatum.couponDto.reduceMax).multipliedBy(printDatum.quantity)
                ).toFixed(2)
            }

            // 订单总金额
            total = common.numberHalfUp(new BigNumber(total).plus(
                new BigNumber(printDatum.price).multipliedBy(printDatum.quantity)
            ).toString(), 2)

            if (printDatum.vin !== null && printDatum.vin !== "") {
                // 车架号
                orderData.vin = printDatum.vin
                // 车牌号
                orderData.plate = printDatum.plate
                // 车型
                orderData.productName = printDatum.productName
            }
            if (printDatum.couponProductList !== null) {
                for (let item of printDatum.couponProductList) {
                    // 是否是东风日产 用于打印打钩
                    item.isDongfeng = false

                    // 是否是东风日产 如果是则在那一栏打钩
                    if (item.source !== undefined && item.source !== null && item.source === "√") {
                        item.isDongfeng = true
                    }
                    productList.push({
                        ...item,
                        useQuantity: printDatum.quantity
                    })
                }
            }
            if (printDatum.couponProjectList !== null) {
                for (let item of printDatum.couponProjectList) {
                    projectList.push({
                        ...item,
                        useQuantity: printDatum.quantity
                    })
                }
            }
        }

        let data = {
            "code": "coupon_order",
            "owner_id": common.getUser().company.id,
            "data": {
                "order": {
                    ...orderData,
                    // 客户名称
                    memberName: printData[0].memberName,
                    // 客户手机号
                    memberMobile: customerInfo.mobile,
                    // 订单号
                    businessCode: printData[0].businessCode,
                    // 打印时间
                    "printTime": moment().format("YYYY-MM-DD HH:mm:ss"),
                    // 方案名称
                    "planName": defaultValue.list.map(item => item.name).join(","),
                    // 总金额
                    "total": total,
                    // 预收券总数量
                    advanceQuantity: advanceQuantity,
                    // 预收券总金额
                    advanceTotal: advanceTotal,
                    // 优惠券总金额
                    clearTotal: clearTotal,
                    // 客户地址
                    address: (customerInfo.provinceName || "") + (customerInfo.cityName || "") + (customerInfo.districtName || "") + (customerInfo.address || ""),
                },
                // 预估材料
                couponProductList: productList,
                // 工时项目
                couponProjectList: projectList,
                "companyInfo": {
                    ...company,
                    "address": company.provinceName + company.cityName + company.areaName + company.address,
                    // 打印logo
                    'logo': imageUrl || "",
                }
            }
        }
        common.loadingStart()
        common.ajax("post", "/ext/print/template/parse", data).then((res) => {
            common.print(res)
        }).finally(() => {
            common.loadingStop()
        })
    }

    // 获取参数
    let getSetting = (params) => {
        common.loadingStart()
        common.ajax('get', '/coupon/setting', {
            taxonomy: params,
            ownerId: common.getUser().company.id
        })
            .then(res => {
                if (res) {
                    // 是否允许修改创建人
                    if (params === TaxonomyEnum.COUPON_SALE_CREATE_UPDATE_CREATOR && res.flag === TaxonomyEnum.SETTING_NO) {
                        setCouponSaleCreateUpdateCreator(false)
                    } else if (params === TaxonomyEnum.COUPON_SALE_POPUP_PRINT_WINDOW && res.flag === TaxonomyEnum.SETTING_NO) {
                        setPopupPrintWindow(false)
                    }
                }
            })
            .finally(common.loadingStop)
    }
    useEffect(() => {
        // 是否允许修改创建人
        if (action === 'create') {
            getSetting(TaxonomyEnum.COUPON_SALE_CREATE_UPDATE_CREATOR)
            getSetting(TaxonomyEnum.COUPON_SALE_POPUP_PRINT_WINDOW)
        }
    }, [])

    return (
        <>
            <br />
            <PageTop title={Object.keys(defaultValue).length === 0 ? '礼券销售' : '订单编辑'}>
                {action !== "create" &&
                    <Button icon={<PrinterOutlined />}
                        onClick={() => {
                            print()
                        }}>打印</Button>
                }
                {action !== "look" &&
                    <Button
                        icon={<SaveOutlined />}
                        type={"primary"}
                        onClick={() => {
                            if (Object.keys(defaultValue).length === 0) {
                                if (customer.id === undefined) {
                                    common.toast("请选择客户")
                                    return false
                                }

                                if (list.length === 0) {
                                    common.toast("请选择方案")
                                    return false
                                }

                                let number = 0
                                list.forEach((item) => {
                                    if (item.quantity === null || item.quantity === '') {
                                        number++
                                    }
                                })
                                if (number !== 0) {
                                    common.toast("请填写数量")
                                    return false
                                }
                                if (creator === null || creator.id === undefined || creator.id === "") {
                                    common.toast("创建人不允许为空")
                                    return
                                }
                            } else {
                                let businessType = form.getFieldValue('businessType')
                                if (businessType === null || businessType === '') {
                                    common.toast("业务大类不允许为空")
                                    return false
                                }
                            }
                            common.confirm(<>
                                <p>客户：<strong>{form.getFieldValue('customerName')}</strong></p>
                                <p>客户手机：<strong>{form.getFieldValue('phone')}</strong></p>
                                <p>车牌号：<strong>{form.getFieldValue('plate')}</strong></p>
                                <p>车架号：<strong>{form.getFieldValue('vin')}</strong></p>
                            </>, () => {
                                form.submit()
                            }, () => {}, "请核对客户信息")
                        }}>保存</Button>}
            </PageTop>
            <Form
                form={form}
                className={'ant-advanced-inline-form'}
                onFinish={onFinish}
            >
                <Row gutter={24}>
                    {/* <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'plate'}
                            label="车牌">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col> */}
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'plate'}
                            label="车牌">
                            <Input
                                className="redInput"
                                readOnly={true}
                                autoComplete={"off"}
                                suffix={
                                    (vehicle.id === undefined || vehicle.id === "") ? <span /> :
                                        <Space>
                                            {
                                                action !== 'look' && common.can("important.vehicle.edit") ?
                                                    <EditOutlined style={{color: "#1890ff"}} onClick={() => {
                                                        //  存在车辆档案id和客户id才可以打开弹框
                                                        setModalTitle("")
                                                        setModalNumber(5)
                                                        setVisible(true)
                                                    }} /> : null
                                            }
                                            <CloseOutlined onClick={() => {
                                                form.setFieldsValue({vin: "", plate: ''})
                                                setVehicle({})
                                            }} />
                                        </Space>
                                }
                                addonAfter={
                                    <SelectOutlined
                                        onClick={() => {
                                            setModalNumber(2)
                                            setModalTitle('选择车辆')
                                            setVisible(true)
                                        }} />
                                }
                                onKeyPress={() => {
                                    if (customer.id === undefined) {
                                        common.toast("请先选择客户")
                                        return false
                                    }
                                    setModalNumber(2)
                                    setModalTitle('选择车辆')
                                    setVisible(true)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'vin'}
                            label="车架号">
                            <Input disabled={true}
                                style={{color: 'red'}} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            className={'label-character-4'}
                            name={'businessType'}
                            rules={[{required: true, message: '业务大类不允许为空'}]}
                            label={'业务大类'}
                        >
                            <Select onChange={() => {
                                setList([])
                                setCouponList([])
                            }}>
                                <Select.Option value={Order.BUSINESS_SALE_TYPE_SALE}
                                    key={Order.BUSINESS_SALE_TYPE_SALE}>{Order.BusinessTypes[Order.BUSINESS_SALE_TYPE_SALE]}</Select.Option>
                                <Select.Option value={Order.BUSINESS_SALE_TYPE_SUPPORT}
                                    key={Order.BUSINESS_SALE_TYPE_SUPPORT}>{Order.BusinessTypes[Order.BUSINESS_SALE_TYPE_SUPPORT]}</Select.Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            label="创建人"
                            name="creatorName"
                        >
                            {(action === 'create' && couponSaleCreateUpdateCreator) ?
                                <Input
                                    readOnly
                                    suffix={creator.id !== "" ?
                                        <CloseOutlined onClick={() => {
                                            setCreator({id: "", name: ""})
                                            form.setFieldsValue({
                                                creatorName: "",
                                            })
                                        }} /> :
                                        <span />}
                                    addonAfter={<SelectOutlined onClick={() => {
                                        setModalTitle("选择创建人")
                                        setModalNumber(6)
                                        setVisible(true)
                                    }} />}
                                    onPressEnter={() => {
                                        setModalTitle("选择创建人")
                                        setModalNumber(6)
                                        setVisible(true)
                                    }}
                                /> :
                                <Input disabled={true} />
                            }
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            rules={[{required: true, message: '请选择客户'}]}
                            name={'customerName'}
                            label="客户">
                            {Object.keys(defaultValue).length > 0 ?
                                <Input disabled={true} style={{color: 'red'}} /> :
                                <Input
                                    className="redInput"
                                    readOnly={true}
                                    autoComplete={"off"}
                                    suffix={
                                        customer.id === undefined ? <span /> :
                                            <Space>
                                                {
                                                    common.can("important.customer.edit") ?
                                                        <EditOutlined style={{color: "#1890ff"}} onClick={() => {
                                                            //  存在客户id才可以打开弹框
                                                            setModalTitle("")
                                                            setModalNumber(3)
                                                            setVisible(true)
                                                        }} /> : null
                                                }
                                                <CloseOutlined onClick={() => {
                                                    form.setFieldsValue({
                                                        customerName: "",
                                                        vin: '',
                                                        plate: '',
                                                        phone: ''
                                                    })
                                                    setCustomer({})
                                                    setVehicle({})
                                                }} />
                                            </Space>
                                    }
                                    addonAfter={
                                        <SelectOutlined
                                            onClick={() => {
                                                setModalNumber(4)
                                                setModalTitle('选择客户')
                                                setVisible(true)
                                            }} />
                                    }
                                    onKeyPress={() => {
                                        setModalNumber(4)
                                        setModalTitle('选择客户')
                                        setVisible(true)
                                    }}
                                />}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'phone'}
                            label="客户手机">
                            <Input disabled={true}
                                style={{color: 'red'}} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            label="车主姓名">
                            <Input disabled={true} value={vehicle.name} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className={'label-character-4'}
                            label="车主电话">
                            <Input disabled={true} value={vehicle.phone} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            className={'label-character-4'}
                            name={'spec'}
                            label="备注">
                            <Input.TextArea
                                autoSize={{
                                    minRows: 1,
                                    maxRows: 10,
                                }} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <p style={{color: 'red', marginLeft: '20px', fontSize: '16px', fontWeight: 800}}>重要提示：请务必核对"客户、客户手机号、车牌、车架号"4项内容是否正确！</p>
            <Table
                pagination={false}
                rowKey='id'
                columns={Object.keys(defaultValue).length === 0 ? columns : columnsUpdate}
                dataSource={
                    Object.keys(defaultValue).length === 0 ?
                        [...list, {
                            isEmptyRecord: true,
                            id: -1
                        }] :
                        defaultValue.list
                }
                scroll={{x: '100%'}} />

            {couponList.length > 0 &&
                <Divider orientation={'left'}>礼券信息</Divider>}
            {couponList.length > 0 && couponTableNumber &&
                <Table
                    pagination={false}
                    rowKey='id'
                    columns={columns2}
                    dataSource={couponList}
                    scroll={{x: '100%', y: '300px'}} />}

            <Modal
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                width={1000}
                onCancel={() => {
                    setVisible(false)
                }}
                destroyOnClose={true}
                footer={null}
            >
                {modalNumber === 6 &&
                    <LookupEmployee
                        canChangeCompany={true}
                        companyId={common.getUser().company.id}
                        onOk={(value) => {
                            setCreator({name: value.name, id: value.id})
                            form.setFieldsValue({creatorName: value.name})
                            setVisible(false)
                        }} isMultiple={false} />
                }
                {modalNumber === 5 && <br />}
                {modalNumber === 5 &&
                    <VehiclePremiumForm
                        id={vehicle.id}
                        customerId={customer.id}
                        onSave={(value) => {
                            form.setFieldsValue({
                                vin: value.vin,// 车架号
                                plate: value.plate,// 车牌号码
                            })
                            setVehicle({
                                id: value.id,
                                vin: value.vin,// 车架号
                                plate: value.plate,// 车牌号码
                                name: value.name,// 车主姓名
                                phone: value.phone,// 车主电话
                            })
                            setVisible(false)
                        }} />
                }
                {modalNumber === 3 &&
                    <CustomerForm
                        id={customer.id}
                        onSave={(value) => {
                            setCustomer({id: value.id, name: value.name})
                            form.setFieldsValue({
                                customerName: value.name || "",// 客户名称
                                phone: value.phone || "",// 客户电话
                            })
                            setVisible(false)
                        }} />
                }
                {modalNumber === 2 &&
                    <LookupVehicle
                        // 车辆状态为启用
                        status={1}
                        customerId={customer.id}
                        onOk={(res) => {
                            setVehicle(res)
                            if (Object.keys(customer).length === 0) {
                                form.setFieldsValue({
                                    vin: res.vin,
                                    plate: res.plate,
                                    customerName: res.customer.name,
                                    phone: res.customer.mobile
                                })
                                setCustomer({id: res.customerId, name: res.customer.name})
                            } else {
                                form.setFieldsValue({vin: res.vin, plate: res.plate, phone: res.customer.mobile})
                            }
                            setVisible(false)
                        }}
                        isMultiple={false}
                    />}
                {modalNumber === 1 &&
                    <LookupPlan
                        defaultValue={list}
                        isMultiple={true}
                        isTenant={false}
                        department={form.getFieldValue("businessType") === Order.BUSINESS_SALE_TYPE_SALE ? Plan.DEPARTMENT_SALE : Plan.DEPARTMENT_SUPPORT}
                        onOk={planOnOk}
                    />
                }
                {modalNumber === 4 &&
                    <LookupAll
                        isPartner={true}
                        isCompany={true}
                        isCustomer={true}
                        isMultiple={false}
                        onOk={(value) => {
                            if (value.id === common.getUser().company.id) {
                                common.alert("客户不能是本公司 请重新选择")
                                return false
                            }
                            if (Object.keys(vehicle).length > 0) {
                                setVehicle({})
                            }

                            setCustomer(value)
                            setVehicle({})
                            form.setFieldsValue({customerName: value.name, vin: '', plate: '', phone: value.mobile})
                            setVisible(false)
                        }} />}
            </Modal>
        </>

    )
}

export default CouponSaleForm