import React, {useEffect, useState} from "react";
import {Button, Divider, Table, Tabs} from "antd";
import common from "../../../utils/common";
import moment from "moment";
import AdvanceList from "./component/advanceList";
import ReceivableList from "./component/receivableList";
import TicketList from "./component/ticketList";
import VehicleList from "./component/vehicleList";
import TagList from "./component/tagList";
import {RepairReminders} from "../../support/config";

const SEX_UNKNOWN = 0;
const SEX_MEN = 1;
const SEX_WOMEN = 2;

let sexAlias = {
    [SEX_UNKNOWN]: '未知',
    [SEX_MEN]: '男',
    [SEX_WOMEN]: '女',
}

const {TabPane} = Tabs

function LookupCustomerRecord(props) {

    // showRepairReminders 是否展示维修提醒 false:不展示 true:展示
    let {customerId, isTag, source, business, showRepairReminders = false} = props

    let initTag = {
        ownerId: common.getUser().company.id,
        source: source || 0,
        business: business || 0,
        customerId: customerId,
        spec: ''
    }

    let [dataSource, setDataSource] = useState([])
    let [tag, setTag] = useState(initTag)
    let [remindersList, setRemindersList] = useState([])// 维修工单 维修提醒数据

    const columns = [
        {
            title: '编号',
            width: 110,
            dataIndex: 'code',
            ellipsis: true,
        },
        {
            title: '客户名称',
            dataIndex: 'name',
            width: 120,
            ellipsis: true,
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            width: 160,
            ellipsis: true,
            render: (text, record) => {
                return text ? text.substr(0, 3) + '****' + text.substr(7) : ''
            }
        },
        {
            title: '性别',
            dataIndex: 'sex',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => sexAlias.hasOwnProperty(record.sex) ? sexAlias[record.sex] : ''
        },
        {
            title: '身份证',
            dataIndex: 'idcard',
            width: 160,
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'creatorName',
            width: 160,
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            width: 160,
            ellipsis: true,
            render: (text, record, index) => {
                return text ? moment(text, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD') : '';
            },
        },
    ];

    // 维修提醒信息 分页列表
    let getRemindersList = (vin) => {
        common.loadingStart();
        common.ajax('get', '/support/repair/reminders/list', {
            ownerId: common.getUser().company.id,
            vin: vin,
            type: RepairReminders.TYPE_ADD,
            page: 1,
            limit: 10
        })
            .then(res => {
                setRemindersList(res.list)
            })
            .finally(common.loadingStop)
    }

    useEffect(() => {
        if (showRepairReminders) {
            getRemindersList("")
        }
    }, [showRepairReminders])

    useEffect(() => {
        if (customerId) {
            common.loadingStart()
            common.ajax('get', '/passport/customer/findByIds', {ids: [customerId]}).then(data => {
                if (data.length > 0) {
                    setDataSource(data)
                } else {
                    common.ajax('get', '/passport/user/findById', {id: customerId}).then(res => {
                        common.consoleLog(res)
                        setDataSource([res])
                    }).finally(common.loadingStop)
                    // common.consoleLog(data, 'aa')
                }
            }).finally(common.loadingStop)
        }
    }, [customerId])

    let handleOk = () => {
        common.loadingStart()
        common.ajax('post', '/crm/tag/create', tag).then(res => {
            common.toast('保存成功')
        }).finally(common.loadingStop)
    }

    return (
        <>
            <Table
                columns={columns}
                dataSource={dataSource}
                scroll={{x: true}}
                rowKey={record => record.id}
                pagination={false}/>

            <Tabs defaultActiveKey={isTag ? '5' : '2'}>
                <TabPane tab={'所有车辆'} key={'1'}>
                    <VehicleList customerId={customerId}/>
                </TabPane>
                <TabPane tab={'预收信息'} key={'2'}>
                    <AdvanceList customerId={customerId}/>
                </TabPane>
                <TabPane tab={'礼券信息'} key={'3'}>
                    <TicketList customerId={customerId}/>
                </TabPane>
                <TabPane tab={'应收信息'} key={'4'}>
                    <ReceivableList customerId={customerId}/>
                </TabPane>
                <TabPane tab={'标记信息'} key={'5'}>
                    <TagList
                        isTag={isTag} customerId={customerId}
                        source={source} business={business} tag={tag}
                        setTag={setTag}/>
                </TabPane>
                {/* todo 维修提醒 */}
                {/*{showRepairReminders &&*/}
                {/*    <TabPane tab={'维修提醒'} key={'6'}>*/}
                {/*        <TagList*/}
                {/*            isTag={isTag} customerId={customerId}*/}
                {/*            source={source} business={business} tag={tag}*/}
                {/*            setTag={setTag}/>*/}
                {/*    </TabPane>}*/}
            </Tabs>

            {
                isTag &&
                <div className={"modal-footer-btn"}>
                    <Button
                        type={"primary"}
                        onClick={handleOk}>保存
                    </Button>
                </div>
            }
        </>
    )
}

export default LookupCustomerRecord