import React from "react"
import Import from "../../../../components/import";
import common from "../../../../utils/common";
import {Button} from "antd";
import {DownloadOutlined, RollbackOutlined} from "@ant-design/icons";
import PageTop from "../../../../components/layout/PageTop";

function ImportVehicle(props) {
    return (
        <div>
            <PageTop title={'导入【车辆信息】'}>
                <Button icon={<RollbackOutlined />} onClick={() => {
                    props.history.goBack();
                }}>返回</Button>
                <Button
                    type={'primary'}
                    icon={<DownloadOutlined />}
                    onClick={() => {
                        let link = document.createElement('a')
                        link.setAttribute("download", "")
                        link.href = "/excel/vehicleCreate.xlsx"
                        link.click()
                    }}>下载模板</Button>
            </PageTop>
            <Import type={'support_vehicle_company'} ownerId={common.getUser().company.id} />
        </div>
    )
}

export default ImportVehicle